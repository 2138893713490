interface IProps {
  className?: string;
}

export const Twitter = ({ className }: IProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M26.3164 0.299805H31.2844L20.4304 14.0257L33.2 32.6998H23.2019L15.3719 21.3726L6.41065 32.6998H1.43995L13.0499 18.018L0.800049 0.301298H11.0519L18.13 10.6547L26.3164 0.299805ZM24.5735 29.411H27.3262L9.55615 3.41688H6.60235L24.5735 29.411Z"
        fill="#333333"
      />
    </svg>
  );
};

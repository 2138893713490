import { animated, useSpring } from "@react-spring/web";
import { ArrowUp } from "img";
import { useEffect, useState } from "react";
import styles from "./ScrollToTopButton.module.scss";

export const ScrollToTopButton = () => {
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);

  const fadeIn = useSpring({
    to: {
      opacity: showScrollToTop ? 1 : 0,
    },
    from: {
      opacity: 0,
    },
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!showScrollToTop) {
    return null;
  }
  return (
    <animated.div
      className={styles.Button}
      onClick={clickHandler}
      style={fadeIn}>
      <ArrowUp />
    </animated.div>
  );
};

import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("legalPage.privacy")}</h1>
      <br />
      <br />
      <section className="textDefault">
        <h1>INTRODUCTION</h1>
        <p>
          Dear User,
          <br />
          <br />
          We invite you to find below the Policy regarding the processing of
          personal data while using the Savelit Mobile Application.
          <br />
          <br />
          The data Controller of your personal data is Berrystack Services
          S.R.L., headquartered in Iuliu Maniu Boulevard 15H, Bl. 2, Sc. 2, Et.
          11, Ap. 188, Bucharest, Romania registered at the Trade Register
          Office attached to the Bucharest Tribunal under no. J40/9964/2021,
          having a unique registration code 44403102, hereinafter “Berrystack”,
          “Savelit” or “we”. This Privacy Policy outlines how Berrystack
          processes personal data, the confidentiality measures in place, and
          how we use the personal data you provide when using the Mobile
          Application, Savelit. Please note that this Privacy Policy may be
          updated periodically, with the latest version available in the App.
          We'll notify you of any changes.
          <br />
          <br />
          Please read the following provisions carefully so that you become
          aware of our Privacy Policy.
        </p>
      </section>

      <section className="textDefault">
        <h1>1. DEFINITIONS</h1>
        <p>
          “Personal data” means any information about an identified or
          identifiable natural person ("Data Subject") that can be identified,
          directly or indirectly, in particular by reference to an identifier,
          such as a name, identification number, data location, an online
          identifier, or to one or more elements specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity
          of the natural person, according to art. 4 point 1 of Reg. EU
          679/2016.
          <br />
          <br />
          “Processing of personal data” means any operation or set of operations
          performed on personal data or on personal data sets, with or without
          the use of automated means, such as collecting, recording, organizing,
          structuring, storing, adapting or modifying, extracting, consulting,
          use, disclosure by transmission, dissemination or making available in
          any other way, alignment or combination, restriction, deletion or
          destruction of data according to art. 4 point 2 of Reg. EU 679/2016.
          <br />
          <br />
          ”GDPR” Regulation (Eu) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with
          regard to the processing of personal data and on the free movement of
          such data, and repealing Directive 95/46/EC (General Data Protection
          Regulation)
          <br />
          <br />
          “User/data subject” refers to a person who uses the applications
          and/or has registered and registered with Savelit for the use or
          potential use of the service, the natural person whose data is
          processed.
          <br />
          <br />
          "Recipient" means the natural or legal person, public authority,
          agency, or other body which processes personal data provided by the
          Controller, on a contractual basis or pursuant to a legal obligation.
        </p>
      </section>

      <section className="textDefault">
        <h1>2. GENERAL PROVISIONS</h1>
        <p>
          Savelit does not intentionally gather personal information from
          individuals under the age of 14. The App is exclusively designed for
          adults aged 14 and older. If you suspect that your child under the age
          of 14 has used the App and shared personal data without your consent,
          please reach out to us at contact@savelit.com. We will delete the
          account from the App, along with the associated personal data, in
          accordance with the Terms and Conditions of Use.
        </p>
      </section>

      <section className="textDefault">
        <h1>3. SOURCES OF PERSONAL DATA</h1>
        <p>
          We collect and process personal data through various methods. Users
          voluntarily provide personal data when creating or modifying their
          user profile, during interactions with or usage of the Application, or
          via email.
          <br />
          <br />
          Payment information is processed by third party payment processors.
          The payment process is authorized by the payment processor and
          confirmed in our system.
          <br />
          <br />
          In addition, your personal data may be procured from external and
          public sources. For example, the App may verify the device's IP,
          determine your location, or use essential, operational, or analytical
          cookies related to your account.
        </p>
      </section>

      <section className="textDefault">
        <h1>4. PERSONAL DATA PROCESSED</h1>
        <p>
          Berrystack will process the following information of Savelit users:
          name, email address, citizenship, encrypted password, user login
          details, IP address, and billing address.
          <br />
          <br />
          While using the financial tracking and budgeting feature in the App,
          we will process your personal financial information, such as income,
          categories of expenses, budget details, etc., based on your request.
          Consequently, for the payment account synchronization, we will process
          credentials to access the payment account, transaction details
          (amount, description, note, date, and currency), and payment account
          details (account number, account name, balance, currency, and type of
          account).
          <br />
          <br />
          If you choose to log in through an Apple ID, Facebook, or Google
          account, we will also process identification details from the
          respective social media account.
          <br />
          <br />
          For improved financial analysis, you can provide additional personal
          data in your Savelit account, which may include birth date, sex,
          family status, place of residence, housing situation, education and
          employment details, property details, and economic situation.
          <br />
          <br />
          All the categories of personal data mentioned above in this section
          are collectively referred to as "Personal data." Savelit will process
          Personal data for the purpose of service provision until it is deleted
          by you or until either you or Savelit terminates the Agreement, if not
          otherwise provided by the law.
        </p>
        <br />
        <h3>
          a. Detailed Information on Third-Party Providers Processing Your
          Personal Data
        </h3>
        <br />
        <p>
          <h3>Analytics</h3>
          We may use third-party Service providers to monitor and analyze the
          use of our Service:
          <br />
          <br />
          <strong>- Google Analytics</strong>
          <br />
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <br />
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity.
          <br />
          You may opt-out of certain Google Analytics features through your
          mobile device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy Policy:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          For more information on the privacy practices of Google, please visit
          the Google Privacy and Terms web page:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          <br />
          <strong>- Firebase</strong>
          <br />
          Firebase is an analytics service provided by Google Inc. You may
          opt-out of certain Firebase features through your mobile device
          settings, such as your device advertising settings or by following the
          instructions provided by Google in their Privacy Policy:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          We also encourage you to review the Google's policy for safeguarding
          your data:{" "}
          <a href="https://support.google.com/analytics/answer/6004245">
            https://support.google.com/analytics/answer/6004245
          </a>
          <br />
          For more information on what type of information Firebase collects,
          please visit the Google Privacy and Terms web page:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          <br />
          <strong>- Adapty by Adapty Tech Inc.</strong> – revenue management
          platform
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://adapty.io/policy/">https://adapty.io/policy/</a>
          <br />
          <br />
        </p>
        <p>
          <h3>Advertising</h3>
          Our Service may include third-party advertising and links to other
          websites and apps. Third-party advertising partners may collect
          information about You when you interact with their content,
          advertising, and services. For more information about third-party
          advertising, including interest-based ads, please read our
          Interest-Based Ads notice. To adjust your advertising preferences
          please access My Data in the drawer menu of each app.
          <br />
          <br />
          <strong>- AdMob</strong>
          <br />
          AdMob by Google is provided by Google Inc.
          <br />
          You can opt-out from the AdMob by Google service by following the
          instructions described by Google:{" "}
          <a href="https://support.google.com/ads/answer/2662922?hl=en">
            https://support.google.com/ads/answer/2662922?hl=en
          </a>
          <br />
          For more information on how Google uses the collected information,
          please visit the "How Google uses data when you use our partners'
          sites or app" page:{" "}
          <a href="https://policies.google.com/technologies/partner-sites">
            https://policies.google.com/technologies/partner-sites
          </a>{" "}
          or visit the Privacy Policy of Google: {" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          <br />
          <strong>- Google AdSense and DoubleClick Cookie</strong>
          <br />
          Google, as a third party vendor, uses cookies to serve ads on our Service.
          Google's use of the DoubleClick cookie enables it and its partners to
          serve ads to our users based on their visit to our Service or other
          websites on the Internet.
          <br />
          You may opt out of the use of the DoubleClick Cookie for interest-based
          advertising by visiting the Google Ads Settings web page: {" "}
          <a href="http://www.google.com/ads/preferences/">
            http://www.google.com/ads/preferences/
          </a>
          <br />
          <br />
          <strong>- Facebook Audience Network </strong>
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.facebook.com/policy.php">
          https://www.facebook.com/policy.php
          </a>
          <br />
          <br />
          <strong>- Reddit Ads </strong>
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.reddit.com/policies/privacy-policy">
            https://www.reddit.com/policies/privacy-policy
          </a>
          <br />
          <br />
        </p>
        <p>
          <h3>Payments</h3>
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors). We will not store or collect Your payment
          card details. That information is provided directly to Our third-party
          payment processors whose use of Your personal information is governed
          by their Privacy Policy. These payment processors adhere to the
          standards set by PCI-DSS as managed by the PCI Security Standards
          Council, which is a joint effort of brands like Visa, Mastercard,
          American Express and Discover. PCI-DSS requirements help ensure the
          secure handling of payment information.
          <br />
          <br />
          <strong>- Apple Store In-App Payments</strong>
          <br />
          Their Privacy Policy can be viewed at{" "}
          <a href="https://www.apple.com/legal/privacy/en-ww/">
            https://www.apple.com/legal/privacy/en-ww/
          </a>
          <br />
          <br />
          <strong>- Google Play In-App Payments</strong>
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
          <br />
          <br />
        </p>
        <p>
          <h3>Behavioral Remarketing</h3>
          The Company uses remarketing services to advertise on third party
          websites to You after You visited our Service. We and Our third-party
          vendors use cookies to inform, optimize and serve ads based on Your
          past visits to our Service.
          <br />
          <br />
          <strong>- Google Ads (AdWords)</strong>
          <br />
          Google Ads (AdWords) remarketing service is provided by Google Inc
          <br />
          You can opt-out of Google Analytics for Display Advertising and customise
          the Google Display Network ads by visiting the Google Ads Settings page: {" "}
          <a href="https://www.google.com/settings/ads">
            https://www.google.com/settings/ads
          </a>
          <br />
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on  {" "}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a> for your web browser.
          Google Analytics Opt-out Browser Add-on provides visitors with the ability
          to prevent their data from being collected and used by Google Analytics.
          <br />
          For more information on the privacy practices of Google, please visit the
          Google Privacy and Terms web page: {" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          <br />
          <strong>- Facebook</strong>
          <br />
          Facebook remarketing service is provided by Facebook Inc.
          <br />
          You can learn more about interest-based advertising from Facebook by visiting
          this page: {" "}
          <a href="https://www.facebook.com/help/164968693837950">
            https://www.facebook.com/help/164968693837950
          </a>
          <br />
          To opt-out from Facebook's interest-based ads, follow these instructions from
          Facebook: {" "}
          <a href="https://www.facebook.com/help/568137493302217">
            https://www.facebook.com/help/568137493302217
          </a>
          <br />
          Facebook adheres to the Self-Regulatory Principles for Online Behavioural
          Advertising established by the Digital Advertising Alliance. You can also
          opt-out from Facebook and other participating companies through the
          Digital Advertising Alliance in the USA {" "}
          <a href="https://www.aboutads.info/choices/">
            https://www.aboutads.info/choices/
          </a>, the Digital Advertising Alliance of Canada in Canada {" "}
          <a href="https://youradchoices.ca/">
            https://youradchoices.ca/
          </a> or the European Interactive Digital Advertising Alliance in Europe {" "}
          <a href="http://www.youronlinechoices.eu/">
            http://www.youronlinechoices.eu/</a>, or
          opt-out using your mobile device settings.
          <br />
          For more information on the privacy practices of Facebook, please visit
          Facebook's Data Policy: {" "}
          <a href="https://www.facebook.com/privacy/explanation">
            https://www.facebook.com/privacy/explanation
          </a>
          <br />
          <br />
        </p>
        <p>
          <h3>Other third-party services</h3>
          <strong>- Salt Edge</strong> – Open Banking Gateway for bank and
          financial account data aggregation
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.saltedge.com/legal/privacy_policy">
            https://www.saltedge.com/legal/privacy_policy
          </a>
          <br />
          <br />
          <strong>- Sentry by Functional Software Inc.</strong> – Application
          performance monitoring platform
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>
          <br />
          <br />
          <strong>- Sendgrid</strong> – email service
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.twilio.com/en-us/legal/privacy">
            https://www.twilio.com/en-us/legal/privacy
          </a>
          <br />
          <br />
          <strong>- Zenduty</strong> – incident management solution
          <br />
          Their Privacy Policy can be viewed at {" "}
          <a href="https://www.zenduty.com/privacy/">
            https://www.zenduty.com/privacy/
          </a>
          <br />
          <br />
        </p>
      </section>

      <section className="textDefault">
        <h1>5. PURPOSES AND LEGAL BASIS OF PROCESSING</h1>
        <h3>a. Functionality of the App</h3>
        <p>
          We process your personal data, specifically, technical details such as
          your IP address and device type. We work with this information to
          deliver our services, ensure the proper functioning of the App,
          troubleshoot technical issues, provide you with the latest and
          accurate App version, and to improve its functionality.
          <br />
          <br />
          For the best experience, it's recommended to enable push notifications
          when using Savelit. These notifications are necessary for Savelit to
          inform you about application updates and available or updated offers.
          <br />
          <br />
          The legal basis for managing the technical operation and functionality
          of the App is Article 6, Paragraph 1, (f) of the GDPR (General Data
          Protection Regulation), based on our legitimate interest in delivering
          the App functions, as well as Article 6, Paragraph 1, (b) of the GDPR,
          which pertains to contract initiation and fulfillment.
        </p>
        <h3>b. Customer Service</h3>
        <p>
          When you register as a Savelit user, we collect your name, phone
          number, your e-mail address, your password, your IP address, and your
          device type as well as your debit/credit card number and the expiry
          date of the card (as described above).
          <br />
          <br />
          We use your personal data and contact details for the provision of our
          services and for service-related notifications. For instance, we may
          notify you when our services are temporarily unavailable due to
          maintenance work. Your personal data and registration information are
          used for the establishment and administration of your account. Please
          be aware that we retain the right to deactivate your account if there
          are suspicions of fraudulent or unlawful activities related to your
          use of the App or if you breach our Terms and Conditions of Use. More
          details can be found in Section 5.2. The legal basis for customer
          service is Art. 6 Para. 1 lit. f GDPR (based on our legitimate
          interest in providing you with the services) and Art. 6 Para. 1 lit. b
          GDPR (contract initiation and contract fulfillment).
        </p>
        <h3>c. Payment processing</h3>
        <p>
          If you register as a Savelit user, your debit/credit card data and
          data of other payment methods will be provided to the Payment
          Processing Provider and processed by the latter to execute payments
          for orders placed by you via the App. The legal basis for payment
          processing is Art. 6 Para. 1 lit. b GDPR (contract initiation and
          contract fulfillment).
        </p>
        <h3>d. Statistical data</h3>
        <p>
          Berrystack employs your personal data in an anonymous and aggregated
          manner to monitor which functions of the service are most frequently
          used, analyse usage patterns, and identify areas where we are
          delivering the right services and where development efforts should be
          concentrated.
        </p>
        <h3>e. Cookies</h3>
        <p>
          Savelit uses cookies. Cookies are small text files stored on your
          mobile device when you visit an App for the first time and they serve
          various purposes, such as remembering your interests and allowing us
          to analyse the performance of our platform or customize content to
          provide you with relevant information.
          <br />
          <br />
          We use essential cookies, vital for the proper functioning of the app
          that cannot be blocked by our administrators. They are generally used
          to support activities, such as privacy options, login processes, or
          form submissions.
          <br />
          <br />
          Operational cookies assist us in improving the effectiveness of
          marketing activities and tailoring our actions to your preferences and
          they remember the choices you make.
          <br />
          <br />
          Analytical cookies enable us to assess the number of visits to the App
          to gather data about the most popular sections of the App and
          understand how users navigate our platform.
          <br />
          <br />
          The legal basis for processing personal data using cookies is Article
          6, Paragraph 1, (f) of the GDPR, based on legitimate interest, and
          Article 6, Paragraph 1, (a) of the GDPR, relying on the consent of the
          data subject.
        </p>
        <h3>f. Legal basis for the processing the personal data</h3>
        <p>
          The processing of personal data is governed by various legal bases
          under the EU General Data Protection Regulation (GDPR).
          <br />
          <br />
          Consent: When we obtain the data subject's consent for processing
          personal data, the legal basis is Art. 6, Paragraph 1, (a) of the
          GDPR.
          <br />
          <br />
          Contractual Necessity: If the processing of personal data is necessary
          for the performance of a contract in which the data subject is a
          party, including pre-contractual measures, the legal basis is Art. 6,
          Paragraph 1, (b) of the GDPR.
          <br />
          <br />
          Legal Obligation: When processing personal data is required to fulfill
          a legal obligation to which our company is subject, the legal basis is
          Art. 6, Paragraph 1, (c) of the GDPR. For example, Berrystack may
          process your personal data in accordance with relevant Anti Money
          Laundering European Legislation, which pertains to measures against
          the legitimization of proceeds of crime and the financing of terrorism
          (hereinafter, "AML Law"). The data collected includes your first name,
          last name, sex, date of birth, place of birth, permanent address, and
          citizenship. This processing of personal data is necessary to fulfill
          the legal obligations imposed on Berrystack under the AML Law. Data
          regarding potentially suspicious transactions, as defined by the AML
          Law, will be retained for a period of 10 years, even after the
          deletion of your Savelit account.
          <br />
          <br />
          Berrystack is required to process data for the purpose of fulfilling
          legal obligations arising from accounting and tax legislation. This
          includes but is not limited to record-keeping, financial reporting,
          and tax compliance activities. The processing of data is necessary to
          ensure compliance with legal requirements and to meet the obligations
          imposed by accounting and tax authorities.
          <br />
          <br />
          Vital Interests: If processing is necessary to protect the vital
          interests of the data subject or another natural person, the legal
          basis is Art. 6, Paragraph 1, (d) of the GDPR.
          <br />
          <br />
          Legitimate Interests: When processing serves the legitimate interests
          of our company or a third party, as processing for App functionality
          or cookies usage, and such interests do not override the data
          subject's interests, fundamental rights, and freedoms, the legal basis
          is Art. 6, Paragraph 1, (f) of the GDPR.
        </p>
        <h3>g. Marketing communication</h3>
        <p>
          Savelit may use your email address if provided in the App and if we
          have your consent, to send you general updates about what's new at
          Savelit and to inform you about available promotions. You can request
          at any time that we cease contacting you for marketing purposes. It's
          important to note that withdrawing your consent will not affect the
          legality of previous processing.
          <br />
          <br />
          In certain cases, our marketing activities may be based on our
          legitimate interest in promoting our latest offers. We take all
          necessary steps to ensure your rights and interests are respected. You
          have the option to unsubscribe from these notifications at any time.
          For our contact details, please refer to section 12 of this document.
          The legal basis for processing for marketing purposes is Art. 6 Para.
          1 lit. a GDPR (consent of the data subject) and Art. 6 Para. 1 lit. f
          GDPR (based on our legitimate interest in promoting our activity).
        </p>
        <h3>h. No obligation to provide personal data</h3>
        <p>
          While providing your personal data is not mandatory, it is essential
          for us to deliver our services. Without your personal data, we may not
          be able to provide our services fully, or we may only offer them
          partially, depending on which personal data you choose not to provide.
          <br />
          <br />
          Additionally, push notifications are not obligatory when using the
          mobile application. Nevertheless, enabling push notifications is
          important for Savelit to keep you informed about application updates
          and provide you with information on available and updated offers.
        </p>
      </section>

      <section className="textDefault">
        <h1>6. DATA RECIPIENTS/TRANSMISSION IN THIRD COUNTRIES</h1>
        <p>
          We may use various third parties and external companies to enable or
          provide the services for us.
          <br />
          <br />
          We may, under certain circumstances, be legally bound to provide
          access to your personal data for investigative purposes related to
          criminal activities and legal violations. This may involve sharing
          your data with judicial or governmental agencies, law enforcement
          authorities, in accordance with warrants, subpoenas, or other
          governmental orders.
          <br />
          <br />
          Additionally, if you are registered with your social media account,
          your data may be shared with third-party social media providers. We
          recommend referring to the privacy policies of these social media
          providers to understand more about your account settings.
          <br />
          <br />
          Furthermore, as part of our daily data processing, we share your data
          with various data processors, such as our IT suppliers, hosting
          service providers, and providers of statistical and database software
          tools. Additionally, your personal data may be transmitted to third
          parties who are not under our direct control, such as our consultants,
          lawyers, or tax consultants. These third parties receive your data
          based on contractual agreements and process your personal data for
          legal reasons or to protect our interests.
          <br />
          <br />
          Given that we use various data processors and service providers to
          ensure the efficient operation of our services and recognizing that
          such partnerships may evolve or change over time due to business needs
          and advancements in technology, it is not viable to provide specific
          names of recipients of personal data. However, we commit to
          maintaining high data protection standards and will always engage with
          reputable and compliant entities in our data processing activities.
          <br />
          <br />
          Additionally, to protect your personal data, we have concluded with
          our suppliers data processing agreements, and where the case may be
          (for recipients outside the EU residing in a country without an
          Adequacy Decision from the European Commission), Standard Contractual
          Clauses with the recipients of your data abroad. Furthermore, our
          service providers are bound by the obligations of confidentiality and
          data protection. Upon your request, we can provide you with a list of
          recipients of your data.
          <br />
          <br />
          We disclose your personal data to the extent legally required or
          necessary for the establishment, exercise, and defence of legal claims
          and, in an emergency, also data relating to security.
        </p>
      </section>

      <section className="textDefault">
        <h1>7. YOUR RIGHTS</h1>
        <p>
          Under the Data Protection Legislation, you have certain rights that
          you can exercise in relation to your personal data.
          <br />
          <br />
          Right to Access: You have the right to request access to your personal
          data that we hold. This includes the right to obtain information
          regarding the purposes for which your data is processed, the
          categories of personal data concerned, and the recipients or
          categories of recipients to whom the data is disclosed.
          <br />
          <br />
          Right to Rectification: If you believe that the personal data we hold
          about you is inaccurate or incomplete, you have the right to request
          the correction or completion of your data.
          <br />
          <br />
          Right to Erasure (Right to Be Forgotten): Under certain circumstances,
          you have the right to request the deletion of your personal data. This
          right is not absolute and can be subject to legal requirements or our
          legitimate interests.
          <br />
          <br />
          Right to Restriction of Processing: You have the right to request the
          restriction of the processing of your personal data in certain
          situations. This means that we will temporarily suspend the processing
          of your data, but we may still store it.
          <br />
          <br />
          Right to Data Portability: You have the right to receive your personal
          data in a structured, commonly used, and machine-readable format and
          have the right to transmit that data to another data controller
          without hindrance.
          <br />
          <br />
          Right to Object: You have the right to object to the processing of
          your personal data based on our legitimate interests or for direct
          marketing purposes. We will stop processing your data unless we can
          demonstrate compelling legitimate grounds for the processing that
          override your interests.
          <br />
          <br />
          Right to Withdraw Consent: If we rely on your consent as the legal
          basis for processing your data, you have the right to withdraw that
          consent at any time. Withdrawing consent does not affect the
          lawfulness of processing based on consent before its withdrawal.
          <br />
          <br />
          Right to Lodge a Complaint: You have the right to lodge a complaint
          with the competent data protection supervisory authority or competent
          court.
          <br />
          <br />
          Please note that the exercise of these rights may be subject to
          certain conditions and limitations as provided by applicable data
          protection laws. You can exercise these rights by sending us an email
          or contacting us at the address provided in the section below. Please
          note that if you choose to submit a request, we may request
          identification documents for verification purposes.
          <br />
          <br />
          To exercise these rights, you can contact us at Berrystack Services
          S.R.L., headquartered in Iuliu Maniu Boulevard 15H, Bl. 2, Sc. 2, Et.
          11, Ap. 188, Bucharest, Romania, postal code 061075 where you can send
          us a written request, dated, and signed or at the e-mail address:
          contact@savelit.com or in our contact section on our App.
          <br />
          <br />
          Berrystack can charge you reasonable processing fees for providing
          relevant information in the event of unsubstantiated or excessive
          requests.
        </p>
      </section>

      <section className="textDefault">
        <h1>8. AUTOMATED DECISION-MAKING AND PROFILING</h1>
        <p>
          In connection with the provision of our services, you will not be
          subject to a decision based on automated processing in accordance with
          Article 22 of GDPR. If we use such procedures in individual cases, you
          will be informed about them and your associated rights within the
          framework of the legal requirements, including the right to object and
          to request human intervention.
          <br />
          <br />
          Within the scope of personal data processing for service provision,
          Savelit may employ profiling to assess or anticipate various aspects
          concerning your family status, housing situation, education and
          employment, property details, economic situation, and behavior. It's
          important to note that this profiling does not lead to any automated
          decision-making with legal repercussions or similarly significant
          effects.
        </p>
      </section>

      <section className="textDefault">
        <h1>9. DATA STORAGE AND THE RIGHT TO BE FORGOTTEN</h1>
        <p>
          Unless otherwise stated in this Privacy Policy, we store your data
          until you delete your Savelit account. If you would like to delete
          your account or submit a request for us to no longer use your data in
          the future to provide services to you, please contact us at the
          contact information provided below.
          <br />
          <br />
          If these are not necessary to comply with legal obligations (such as
          accounting and tax obligations) or to settle disputes or litigations,
          we will delete your personal data after deleting your account.
          <br />
          <br />
          However, the personal data used to respond to your request will be
          processed for a specified period of time, in the legitimate interest
          of the Controller to demonstrate compliance, confirmation, and
          response to your requests.
          <br />
          <br />
          If, at any time within the past 24 months, you have maintained an
          active subscription in the App, your data may be subject to deletion.
          This means that your user data, including personal information and
          account details, will be permanently removed from our system. It is
          your responsibility to ensure the continuity of your subscription to
          prevent data loss. We recommend regularly reviewing and if the case
          may be, renewing your subscription to safeguard your information.
        </p>
      </section>

      <section className="textDefault">
        <h1>
          10. INFORMATION ABOUT YOUR RIGHT TO OBJECT IN ACCORDANCE WITH ARTICLE
          21 GDPR
        </h1>
        <p>
          Right to object on a case-by-case basis
          <br />
          <br />
          You have the right, for reasons that arise from your particular
          situation, at any time, against the processing of your personal data,
          which is based on Article 6 (1) lit. e from the GDPR (data processing
          in the public interest) or lit. f GDPR (data processing based on a
          balance of interests), to object to data processing; this also applies
          to profiling based on these provisions.
          <br />
          <br />
          If you file an objection, we will no longer process your personal
          data. A refusal or objection only applies if we can demonstrate
          compelling legitimate grounds for the processing that outweigh your
          interests, rights, and freedoms or if the processing serves to assert,
          exercise, or defend legal claims.
          <br />
          <br />
          The objection can be made informally with the subject “objection”
          stating your name, address, and date of birth and should be addressed
          to the contact information as provided in section 12.
        </p>
      </section>

      <section className="textDefault">
        <h1>11. SAFETY MEASURES</h1>
        <p>
          Berrystack has implemented appropriate technical and organizational
          measures to safeguard your personal data against loss and unauthorized
          processing. These measures include the use of industry-standard
          security protocols, firewalls, and physical security measures at data
          storage locations.
          <br />
          <br />
          While we make all reasonable efforts to maintain the highest level of
          security, it's essential to understand that no security system is
          entirely without vulnerabilities. We cannot guarantee the absolute
          security of our database, nor can we guarantee that information you
          transmit to us over the internet will not be intercepted. Therefore,
          any data you provide to us is done at your own risk.
          <br />
          <br />
          To strengthen your data security, we strongly recommend that you do
          not share your password or any sensitive login credentials with
          anyone. Your password is a crucial element in protecting the
          confidentiality of your account.
          <br />
          <br />
          We continuously monitor and update our security measures to stay
          aligned with industry best practices and evolving threats to ensure
          the ongoing protection of your personal data.
          <br />
          <br />
          If you have any concerns or questions about the security of your data
          or wish to learn more about our data protection practices, please do
          not hesitate to contact us using the contact details provided below.
        </p>
      </section>

      <section className="textDefault">
        <h1>12. CONTACT INFORMATION</h1>
        <h3>You can contact us:</h3>
        <p>At the contact section in the App and Website.</p>
        <p>
          At Iuliu Maniu Boulevard 15H, Bl. 2, Sc. 2, Et. 11, Ap. 188,
          Bucharest, Romania postal code 061075.
        </p>
        <p>
          On E-mail at{" "}
          <a href="mailto:contact@savelit.com">contact@savelit.com</a>.
        </p>
      </section>
    </div>
  );
};

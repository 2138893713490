import { Grid } from "@mui/material";
import { animated, useScroll, useSpring, useSprings } from "@react-spring/web";
import { LanguageSelector, Link } from "components/atoms";
import { ACTION, CATEGORY, EVENT } from "const";
import { useScrollToElement } from "hooks";
import { t } from "i18next";
import { BurgerMenu, Close, Logo } from "img";
import mixpanel from "mixpanel-browser";
import {
  ABOUT_PATH,
  CONTACT_PATH,
  HOME_PATH,
  PARTNERS_PATH,
  SUPPORT_PATH,
} from "pages/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";
import { IMenuItem } from "types";
import styles from "./MenuSection.module.scss";
import {
  HOVER_INITIAL_ITEM,
  HOVER_SCROLLED_ITEM,
  INITIAL_HEADER,
  INITIAL_ITEM,
  INITIAL_LOGO,
  SCROLLED_HEADER,
  SCROLLED_ITEM,
  SCROLLED_LOGO,
} from "./animations";

interface IProps {
  menuItems?: IMenuItem[];
  defaultKey?: string;
}

export const MenuSection = ({
  menuItems: propsMenuItems,
  defaultKey,
}: IProps) => {
  const menuItems: IMenuItem[] = useMemo(
    () =>
      propsMenuItems ?? [
        { index: 0, label: t("menu.home"), key: "home", link: HOME_PATH },
        { index: 1, label: t("menu.about"), key: "about", link: ABOUT_PATH },
        {
          index: 2,
          label: t("menu.partners"),
          key: "partners",
          link: PARTNERS_PATH,
        },
        {
          index: 3,
          label: t("menu.support"),
          key: "support",
          link: SUPPORT_PATH,
        },
        {
          index: 4,
          label: t("menu.contact"),
          key: "contact",
          link: CONTACT_PATH,
        },
      ],
    [propsMenuItems],
  );

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const activeItem = useRef<IMenuItem>();

  const navigate = useNavigate();
  const location = useLocation();
  const { scrollTo } = useScrollToElement();

  const [animatedHeader, setAnimatedHeader] = useSpring(() => INITIAL_HEADER);
  const [animatedItem, setAnimatedItem] = useSprings(
    menuItems.length,
    () => INITIAL_ITEM,
  );
  const [animatedLogo, setAnimatedLogo] = useSpring(() => INITIAL_LOGO);
  const [animatedLanguage, setAnimatedLanguage] = useSpring(
    () => HOVER_INITIAL_ITEM,
  );

  const { scrollYProgress } = useScroll({
    onChange: ({ value: { scrollYProgress } }) => {
      setAnimatedHeader.start(
        scrollYProgress > 0 ? SCROLLED_HEADER : INITIAL_HEADER,
      );
      setAnimatedLogo.start(scrollYProgress > 0 ? SCROLLED_LOGO : INITIAL_LOGO);
      setAnimatedLanguage.start(
        scrollYProgress > 0 ? HOVER_SCROLLED_ITEM : HOVER_INITIAL_ITEM,
      );
      setAnimatedItemHandler(-1, scrollYProgress > 0);
    },
    default: {
      immediate: true,
    },
  });

  useEffect(() => {
    if (defaultKey) {
      const item = menuItems.find((elem) => elem.key === defaultKey);
      if (item) {
        activeItem.current = item;
      }
    }
  }, [menuItems, defaultKey]);

  useEffect(() => {
    if (location.pathname === HOME_PATH) {
      activeItem.current = menuItems[0];
      setAnimatedItemHandler(-1);
    } else if (location.pathname === ABOUT_PATH) {
      activeItem.current = menuItems[1];
      setAnimatedItemHandler(-1);
    } else if (location.pathname === PARTNERS_PATH) {
      activeItem.current = menuItems[2];
      setAnimatedItemHandler(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, menuItems]);

  const handleOnClick = (item: IMenuItem) => () => {
    if (item.link) {
      window.scrollTo(0, 0);
      navigate(item.link);
      return;
    }
    if (!item.gotoRef) {
      navigate("/", { state: { item } });
      return;
    }
    activeItem.current = item;
    mixpanel.track(EVENT.NAVIGATE, { where: item.label });
    ReactGA.event({
      category: CATEGORY.MENU_LINK,
      action: ACTION.NAVIGATE,
      label: item.label,
    });
    scrollTo(item.gotoRef);
    setShowMobileMenu(false);
  };

  const onMouseEnterHandler = (index: number) => () => {
    setAnimatedItemHandler(index);
  };

  const onMouseLeaveHandler = () => setAnimatedItemHandler(-1);

  const setAnimatedItemHandler = (
    hoveredIndex: number,
    isScrolled?: boolean,
  ) => {
    if (isScrolled === undefined) {
      isScrolled = scrollYProgress.get() > 0;
    }
    setAnimatedItem.start((current: number) =>
      current === hoveredIndex || current === activeItem.current?.index
        ? isScrolled
          ? HOVER_SCROLLED_ITEM
          : HOVER_INITIAL_ITEM
        : isScrolled
          ? SCROLLED_ITEM
          : INITIAL_ITEM,
    );
  };

  return (
    <div className={styles.SectionWrapper}>
      <animated.div className={styles.Menu} style={animatedHeader}>
        <Grid container gap={{ xl: 0.2, lg: 0.5, md: 0.75, sm: 1, xs: 1 }}>
          <Grid item xl={1.5} lg={1.5} md={0.5} sm={0.5} xs={0.25} />
          <Grid item xl={4.4} lg={4.4} md={4} xs={8.5}>
            <Link
              href={HOME_PATH}
              title={t("app.name")}
              className={styles.Link}>
              <Logo />
              <animated.div style={animatedLogo} className={styles.Logo}>
                Savelit
              </animated.div>
            </Link>
          </Grid>
          {menuItems.map((item, index) => (
            <Grid
              item
              xl={0.75}
              lg={0.75}
              md={1.1}
              sx={{ display: { xs: "none", md: "flex" } }}
              key={index}>
              <animated.div
                className={styles.MenuItem}
                style={animatedItem[index]}
                onClick={handleOnClick(item)}
                onMouseEnter={onMouseEnterHandler(index)}
                onMouseLeave={onMouseLeaveHandler}>
                <span className={styles.Label}>{item.label}</span>
              </animated.div>
            </Grid>
          ))}
          <Grid item xl={0.75} lg={0.5} md={0.8} xs={1.2}>
            <LanguageSelector animatedStyle={animatedLanguage} />
          </Grid>
          <Grid
            item
            xs={1}
            className={styles.BurgerMenu}
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <BurgerMenu />
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={0.5}
            sx={{ display: { xs: "none", md: "flex" } }}
          />
        </Grid>
      </animated.div>

      {showMobileMenu && (
        <div className={styles.MenuMobile}>
          <div
            className={styles.Close}
            onClick={() => setShowMobileMenu(false)}>
            <Close />
          </div>
          {menuItems?.map((item) => (
            <div
              className={styles.MenuItem}
              style={{
                borderColor:
                  activeItem.current?.label === item?.label
                    ? "#6052ffcc"
                    : "333333cc",
              }}
              onClick={handleOnClick(item)}>
              <span className={styles.Label}>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

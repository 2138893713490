import { MutableRefObject } from "react";

export const useScrollToElement = () => {
  const scrollTo = (element: MutableRefObject<any>) => {
    window.scrollTo({
      top: element.current.offsetTop - 94,
      behavior: "smooth",
    });
  };

  return { scrollTo };
};

export const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M12 19.625C11.7833 19.625 11.6043 19.55 11.463 19.4C11.321 19.25 11.25 19.075 11.25 18.875V7.25L5.97499 12.525C5.82499 12.675 5.64999 12.75 5.44999 12.75C5.24999 12.75 5.06665 12.675 4.89999 12.525C4.74999 12.375 4.67499 12.2 4.67499 12C4.67499 11.8 4.74999 11.625 4.89999 11.475L11.375 5.025C11.4583 4.925 11.5543 4.854 11.663 4.812C11.771 4.77067 11.8833 4.75 12 4.75C12.1167 4.75 12.2293 4.77067 12.338 4.812C12.446 4.854 12.5417 4.925 12.625 5.025L19.1 11.475C19.25 11.625 19.325 11.8 19.325 12C19.325 12.2 19.25 12.375 19.1 12.525C18.9333 12.675 18.75 12.75 18.55 12.75C18.35 12.75 18.175 12.675 18.025 12.525L12.75 7.25V18.875C12.75 19.075 12.6793 19.25 12.538 19.4C12.396 19.55 12.2167 19.625 12 19.625Z"
        fill="white"
      />
    </svg>
  );
};

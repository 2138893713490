import { Grid } from "@mui/material";
import { ContentWrapper, DownloadAppBtn, Link } from "components";
import {
  EVENT,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TIKTOK,
  TRACKING_LABEL,
  TWITTER,
  YOUTUBE,
} from "const";
import { useScrollToElement } from "hooks";
import { LangEnum } from "i18n/types";
import { Facebook, Instagram, Linkedin, TikTok, Twitter, Youtube } from "img";
import {
  ABOUT_PATH,
  CONTACT_PATH,
  HOME_PATH,
  PARTNERS_PATH,
  PRIVACY_PATH,
  SUPPORT_PATH,
  TERMS_PATH,
} from "pages/routes";
import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./FooterSection.module.scss";

interface FooterSectionProps {
  homeRefs?: {
    home: MutableRefObject<any>;
    about: MutableRefObject<any>;
    partners: MutableRefObject<any>;
  };
}

export const FooterSection = ({ homeRefs }: FooterSectionProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { scrollTo } = useScrollToElement();

  const handleSelectLang = (lang: LangEnum) => {
    i18n.changeLanguage(lang);
  };

  const onClickHandler = (type: "home" | "about" | "partners") => () => {
    if (homeRefs) {
      scrollTo(homeRefs[type]);
      return;
    }

    window.scrollTo(0, 0);
    switch (type) {
      case "home":
        navigate(HOME_PATH);
        break;
      case "about":
        navigate(ABOUT_PATH);
        break;
      case "partners":
        navigate(PARTNERS_PATH);
        break;
    }
  };

  return (
    <ContentWrapper className={styles.ContentWrapper} withoutAnimation>
      <Grid
        container
        columnSpacing={2}
        marginBottom={7.5}
        sx={{ marginBottom: { xs: 3, sm: 3 } }}>
        <Grid item md={2} xs={4}>
          <div className={styles.Title}>{t("footerSection.explore")}</div>
          <div className={styles.SubTitle} onClick={onClickHandler("home")}>
            {t("menu.home")}
          </div>
          <div className={styles.SubTitle} onClick={onClickHandler("about")}>
            {t("aboutSection.title")}
          </div>
          <div className={styles.SubTitle} onClick={onClickHandler("partners")}>
            {t("ourPartnersSection.title")}
          </div>
          <div>
            <Link
              className={styles.SubTitle}
              href={SUPPORT_PATH}
              title={t("links.support")}>
              {t("links.support")}
            </Link>
          </div>
          <div>
            <Link
              className={styles.SubTitle}
              href={CONTACT_PATH}
              title={t("links.contact")}>
              {t("links.contact")}
            </Link>
          </div>
        </Grid>
        <Grid item md={2} xs={4}>
          <div className={styles.Title}>{t("footerSection.legal")}</div>
          <div>
            <Link
              className={styles.SubTitle}
              href={TERMS_PATH}
              title={t("links.TC")}
              target="_self">
              {t("links.TC")}
            </Link>
          </div>
          <div>
            <Link
              className={styles.SubTitle}
              href={PRIVACY_PATH}
              title={t("links.privacyPolicy")}
              target="_self">
              {t("links.privacyPolicy")}
            </Link>
          </div>
        </Grid>
        <Grid item md={2} xs={4}>
          <div className={styles.Title}>{t("footerSection.language")}</div>
          <div
            className={styles.SubTitle}
            onClick={() => handleSelectLang(LangEnum.EN)}>
            {t("appLang.en")}
          </div>
          <div
            className={styles.SubTitle}
            onClick={() => handleSelectLang(LangEnum.DE)}>
            {t("appLang.de")}
          </div>
          <div
            className={styles.SubTitle}
            onClick={() => handleSelectLang(LangEnum.ES)}>
            {t("appLang.es")}
          </div>
          <div
            className={styles.SubTitle}
            onClick={() => handleSelectLang(LangEnum.FR)}>
            {t("appLang.fr")}
          </div>
          <div
            className={styles.SubTitle}
            onClick={() => handleSelectLang(LangEnum.CN)}>
            {t("appLang.cn")}
          </div>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          sx={{
            alignItems: { xs: "center", md: "flex-end" },
            marginTop: { xs: 3, md: 0 },
          }}>
          <div className={styles.Title}>{t("footerSection.download")}</div>
          <div className={styles.Buttons}>
            <DownloadAppBtn
              type="apple"
              label={TRACKING_LABEL.DOWLOAD_FOOTER}
              event={EVENT.DOWNLOAD_FOOTER_APP_STORE}
            />
            <DownloadAppBtn
              type="google"
              label={TRACKING_LABEL.DOWLOAD_FOOTER}
              event={EVENT.DOWNLOAD_FOOTER_GOOGLE_PLAY}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          sx={{
            justifyContent: { xs: "center", sm: "center", md: "flex-start" },
          }}>
          <div className={styles.Copyright}>
            {t("footerSection.copyright", { year: new Date().getFullYear() })}
          </div>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          sx={{
            justifyContent: { xs: "center", sm: "center", md: "flex-end" },
            marginTop: { xs: 3, sm: 3 },
          }}>
          <div className={styles.ChannelsWrapper}>
            <Link href={FACEBOOK} title="Facebook">
              <Facebook className={styles.Icon} />
            </Link>
            <Link href={INSTAGRAM} title="Instagram">
              <Instagram className={styles.Icon} />
            </Link>
            <Link href={TWITTER} title="Twitter">
              <Twitter className={styles.Icon} />
            </Link>
            <Link href={TIKTOK} title="TikTok">
              <TikTok className={styles.Icon} />
            </Link>
            <Link href={LINKEDIN} title="Linkedin">
              <Linkedin className={styles.Icon} />
            </Link>
            <Link href={YOUTUBE} title="Youtube">
              <Youtube className={styles.Icon} />
            </Link>
          </div>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

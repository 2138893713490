import { Grid } from "@mui/material";
import { useInView } from "@react-spring/web";
import { ContentWrapper } from "components";
import { MicrosoftLogo, SaltEdgeLogo } from "img";
import { PARTNERS_PATH } from "pages/routes";
import { MutableRefObject, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./OurPartnersSection.module.scss";

interface OurPartnersSectionProps {
  elementRef: MutableRefObject<HTMLDivElement | null>;
}

export const OurPartnersSection = ({ elementRef }: OurPartnersSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ref, inView] = useInView({ amount: "all" });

  useEffect(() => {
    if (inView) {
      navigate(PARTNERS_PATH);
    }
  }, [inView, navigate]);

  return (
    <ContentWrapper className={styles.ContentWrapper} elementRef={elementRef}>
      <Grid container ref={ref}>
        <Grid item md={12} xs={12} display="flex" justifyContent="center">
          <div className={styles.Title}>{t("ourPartnersSection.title")}</div>
        </Grid>
        <Grid item md={12} xs={12} display="flex" justifyContent="center">
          <div className={styles.SubTitle}>
            <Trans i18nKey="ourPartnersSection.subtitle" />
          </div>
        </Grid>
        <Grid container columnSpacing={10}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              textAlign: { xs: "center", md: "end" },
              marginBottom: { xs: 4, md: 0 },
            }}>
            <SaltEdgeLogo />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ textAlign: { xs: "center", md: "start" } }}>
            <MicrosoftLogo />
          </Grid>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

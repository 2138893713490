import { Checkbox, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Button, ContentWrapper, Link } from "components";
import { BANKS, EMAIL_OFFICE, SENDGRID_SEND_FORM_ENDPOINT } from "const";
import { InfoCircle } from "img";
import { PRIVACY_PATH } from "pages/routes";
import { ChangeEvent, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./Form.module.scss";

interface IValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  company: string;
  message: string;
  check: boolean;
}

interface IErrors {
  firstname: boolean;
  lastname: boolean;
  email: boolean;
  message: boolean;
  check: boolean;
}

const Form = () => {
  const [values, setValues] = useState<IValues>({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    check: false,
  });
  const [errors, setErrors] = useState<IErrors>({
    firstname: false,
    lastname: false,
    email: false,
    message: false,
    check: false,
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("GB");
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { t } = useTranslation();
  const { countryCode } = useParams();

  useEffect(() => {
    setCountryCodes(Object.keys(BANKS)?.sort((a, b) => a.localeCompare(b)));
  }, []);

  useEffect(() => {
    if (countryCode) {
      const isCodeInList = Object.keys(BANKS)?.includes(
        countryCode?.toUpperCase(),
      );
      setSelectedCountryCode(isCodeInList ? countryCode.toUpperCase() : "GB");
    }
  }, [countryCode]);

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
    setIsSent(false);
  };

  const checkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
    setErrors({ ...errors, [event.target.name]: false });
  };

  const onSelectChange = (code: string) => {
    setSelectedCountryCode(code);
  };

  const validateForm = () => {
    let newErrors: IErrors = { ...errors };
    if (!values.firstname || values.firstname.length < 3) {
      newErrors.firstname = true;
    }
    if (!values.lastname || values.lastname.length < 3) {
      newErrors.lastname = true;
    }
    if (
      !values.email ||
      !values.email?.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      newErrors.email = true;
    }
    if (!values.message || values.message.length < 10) {
      newErrors.message = true;
    }
    if (!values.check) {
      newErrors.check = true;
    }
    setErrors({ ...newErrors });

    if (Object.values(newErrors).includes(true)) {
      return false;
    }
    return true;
  };

  const getMessage = () =>
    `Firstname: ${values.firstname}\n Lastname: ${values.lastname}\n Email: ${values.email}\n Phone: ${values.phone}\n Company: ${values.company}\n Country: ${selectedCountryCode}\n Message: ${values.message}`;

  const handleSubmit = () => {
    if (validateForm()) {
      setInProgress(true);
      axios
        .post(
          SENDGRID_SEND_FORM_ENDPOINT,
          {
            personalizations: [
              {
                to: [
                  {
                    email: EMAIL_OFFICE,
                    name: "Office Savelit",
                  },
                ],
                subject: "Savelit Contact Form",
              },
            ],
            content: [
              {
                type: "text/plain",
                value: getMessage(),
              },
            ],
            from: {
              email: EMAIL_OFFICE,
              name: `${values.firstname} ${values.lastname}`,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY_FOR_CONTACT_FORM}`,
            },
          },
        )
        .then(() => {
          setIsSent(true);
          setInProgress(false);
        })
        .catch((error) => {
          setInProgress(false);
          console.log(error);
        });
    }
  };

  return (
    <ContentWrapper className={styles.ContentWrapper} withoutAnimation>
      <Grid container>
        <Grid item md={2} sm={2} xs={1} />
        <Grid item md={8} sm={8} xs={10}>
          {isSent && (
            <div className={styles.Message}>
              <InfoCircle className={styles.Icon} />
              <div className={styles.Text}>
                {t("contactPage.form.messageSent")}
              </div>
            </div>
          )}
          <Grid container columnSpacing={7.5} rowSpacing={3.75}>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Label}>
                  {t("contactPage.form.firstname")}
                </div>
                <TextField
                  fullWidth
                  id="firstname"
                  name="firstname"
                  required
                  value={values.firstname}
                  onChange={onChangeHandler}
                  error={errors.firstname}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Label}>
                  {t("contactPage.form.lastname")}
                </div>
                <TextField
                  fullWidth
                  id="lastname"
                  name="lastname"
                  required
                  value={values.lastname}
                  onChange={onChangeHandler}
                  error={errors.lastname}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Label}>
                  {t("contactPage.form.email")}
                </div>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  required
                  value={values.email}
                  onChange={onChangeHandler}
                  error={errors.email}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Row}>
                  <div className={styles.Label}>
                    {t("contactPage.form.phone")}
                  </div>
                  <div className={styles.Optional}>
                    {t("contactPage.form.optional")}
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  required
                  value={values.phone}
                  onChange={onChangeHandler}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Row}>
                  <div className={styles.Label}>
                    {t("contactPage.form.company")}
                  </div>
                  <div className={styles.Optional}>
                    {t("contactPage.form.optional")}
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="company"
                  name="company"
                  required
                  value={values.company}
                  onChange={onChangeHandler}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div>
                <div className={styles.Row}>
                  <div className={styles.Label}>
                    {t("contactPage.form.country")}
                  </div>
                  <div className={styles.Optional}>
                    {t("contactPage.form.optional")}
                  </div>
                </div>
                <ReactFlagsSelect
                  countries={countryCodes}
                  selected={selectedCountryCode}
                  onSelect={onSelectChange}
                  searchable={true}
                  className={styles.SelectBanks}
                  selectButtonClassName={styles.SelectBanksButton}
                />
              </div>
            </Grid>
            <Grid item md={12} xs={12}>
              <div>
                <div className={styles.Label}>
                  {t("contactPage.form.message")}
                </div>
                <TextField
                  fullWidth
                  id="message"
                  name="message"
                  required
                  value={values.message}
                  onChange={onChangeHandler}
                  error={errors.message}
                  multiline
                  rows={5}
                />
              </div>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              display="flex"
              flexDirection="row"
              alignItems="center">
              <Checkbox
                id="check"
                name="check"
                required
                checked={values.check}
                onChange={checkHandler}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                className={styles.CheckBox}
              />
              <div className={styles.PrivacyText}>
                {t("contactPage.form.privacyPolicy")}
              </div>
              <Link
                className={styles.Link}
                href={PRIVACY_PATH}
                title={t("links.privacyPolicy")}>
                {t("links.privacyPolicy")}
              </Link>
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                onClick={handleSubmit}
                loading={inProgress}
                disabled={isSent}>
                {t("contactPage.form.submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sm={2} xs={1} />
      </Grid>
    </ContentWrapper>
  );
};

export default Form;

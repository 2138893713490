import {
  ContentWrapper,
  FooterSection,
  MenuSection,
  StayUpToDateSection,
} from "components";
import { Book, School, Video } from "img";
import { SUPPORT_PATH } from "pages/routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Support.module.scss";
import { GuidelinesSection, SupportHeader } from "./sections";

enum SupportTabs {
  Guidelines = "guidelines",
  Tutorials = "video-tutorials",
  Base = "knowledge-base",
}

const Support = () => {
  const [tab, setTab] = useState<SupportTabs>(SupportTabs.Guidelines);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const path = location.pathname.split("/");
    if (path.length === 3) {
      setTab(path[2] as SupportTabs);
    } else {
      navigate(`${SUPPORT_PATH}/${SupportTabs.Guidelines}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabHandler = (value: SupportTabs) => () => {
    setTab(value);
    navigate(`${SUPPORT_PATH}/${value}`);
  };

  return (
    <div className={styles.App}>
      <MenuSection defaultKey="support" />
      <SupportHeader />
      <div className={styles.Tabs}>
        <div
          className={styles.TabButton}
          onClick={tabHandler(SupportTabs.Guidelines)}>
          <Book className={styles.TabIcon} />
          <div className={styles.TabTitle}>
            {t("supportPage.guidelines.title")}
          </div>
          {tab === SupportTabs.Guidelines && (
            <div
              className={styles.TabCorner}
              style={{ borderTopColor: "#6D7BF6" }}
            />
          )}
        </div>
        <div
          className={styles.TabButton}
          onClick={tabHandler(SupportTabs.Tutorials)}>
          <Video className={styles.TabIcon} />
          <div className={styles.TabTitle}>
            {t("supportPage.videoTutorials.title")}
          </div>
          {tab === SupportTabs.Tutorials && (
            <div
              className={styles.TabCorner}
              style={{ borderTopColor: "#FF5858" }}
            />
          )}
        </div>
        <div
          className={styles.TabButton}
          onClick={tabHandler(SupportTabs.Base)}>
          <School className={styles.TabIcon} />
          <div className={styles.TabTitle}>
            {t("supportPage.knowledgeBase.title")}
          </div>
          {tab === SupportTabs.Base && (
            <div
              className={styles.TabCorner}
              style={{ borderTopColor: "#3FCC5E" }}
            />
          )}
        </div>
        <div />
      </div>
      {tab === SupportTabs.Guidelines ? (
        <GuidelinesSection />
      ) : (
        <ContentWrapper className={styles.ComingSoon}>
          {t("supportPage.comingSoon")}
        </ContentWrapper>
      )}
      <StayUpToDateSection withoutAnimation />
      <FooterSection />
    </div>
  );
};

export default Support;

import { EVENT } from "const";
import mixpanel from "mixpanel-browser";
import { Contact, Home, Legal, Support } from "pages";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

// ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID, { debug: true });
// ReactGA.pageview(window.location.pathname);

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
  debug: true,
  ignore_dnt: true,
});
mixpanel.track(EVENT.VISITED);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:countryCode",
    element: <Home />,
  },
  {
    path: "/support",
    element: <Support />,
    children: [
      {
        path: "guidelines",
        element: <Support />,
      },
      {
        path: "video-tutorials",
        element: <Support />,
      },
      {
        path: "knowledge-base",
        element: <Support />,
      },
    ],
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/terms-and-conditions",
    element: <Legal />,
  },
  {
    path: "/privacy-policy",
    element: <Legal />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

reportWebVitals();

import { Grid } from "@mui/material";
import { ContentWrapper, Link } from "components";
import {
  CONTACT_OFFICE,
  CONTACT_SUPPORT,
  EMAIL_CONTACT,
  EMAIL_OFFICE,
} from "const";
import { InfoCircle, ManageAccounts } from "img";
import { useTranslation } from "react-i18next";
import styles from "./Info.module.scss";

const Info = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper className={styles.ContentWrapper} withoutAnimation>
      <Grid container>
        <Grid item md={2} sm={2} xs={1} />
        <Grid item md={8} sm={8} xs={10}>
          <Grid container columnSpacing={7.5} rowSpacing={3.75}>
            <Grid item md={6} xs={12}>
              <div className={styles.Content}>
                <InfoCircle className={styles.Icon} />
                <div>
                  <div className={styles.Title}>
                    {t("contactPage.info.generalInfo")}
                  </div>
                  <Link
                    className={styles.SubTitle}
                    href={CONTACT_OFFICE}
                    title={t("links.contact")}
                    target="_self">
                    {EMAIL_OFFICE}
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={styles.Content}>
                <ManageAccounts className={styles.Icon} />
                <div>
                  <div className={styles.Title}>
                    {t("contactPage.info.customerSupport")}
                  </div>
                  <Link
                    className={styles.SubTitle}
                    href={CONTACT_SUPPORT}
                    title={t("links.contact")}
                    target="_self">
                    {EMAIL_CONTACT}
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} sm={2} xs={1} />
      </Grid>
    </ContentWrapper>
  );
};

export default Info;

import classnames from "classnames";
import styles from "./VerticalMenu.module.scss";

export interface VerticalMenuItem {
  label: string;
  key: string;
}

interface VerticalMenuProps {
  title: string;
  items: VerticalMenuItem[];
  selectedKey: string;
  handleClick: (key: string) => void;
}

const VerticalMenu = ({
  title,
  items,
  selectedKey,
  handleClick,
}: VerticalMenuProps) => {
  const menuItem = ({ key, label }: VerticalMenuItem) => {
    const isSelected = selectedKey === key;
    return (
      <div className={styles.itemWrapper} key={key}>
        {isSelected && <div className={styles.selectedLine} />}
        <div
          className={classnames(styles.item, isSelected && styles.selected)}
          key={key}
          onClick={() => handleClick(key)}>
          {label}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.menu}>
      <div className={styles.title}>{title}</div>
      {items.map(menuItem)}
    </div>
  );
};

export default VerticalMenu;

import classnames from "classnames";
import { ContentWrapper } from "components";
import styles from "./HeaderSection.module.scss";

interface HeaderSectionProps {
  title: string;
  description?: string;
}

export const HeaderSection = ({ title, description }: HeaderSectionProps) => (
  <ContentWrapper className={styles.ContentWrapper} withoutAnimation>
    <div className={classnames(styles.Circle, styles.CircleLeft)} />
    <div className={classnames(styles.Circle, styles.CircleRight)} />
    <div className={styles.Content}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Description}>{description}</div>
    </div>
  </ContentWrapper>
);

import { Grid } from "@mui/material";
import { useInView } from "@react-spring/web";
import { ContentWrapper } from "components";
import {
  EnhancedAccountSecurity,
  ReadOnlyBankConnection,
  SecuredInfrastructure,
  YourSelfDirectedAccount,
} from "img";
import { ABOUT_PATH } from "pages/routes";
import { MutableRefObject, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./AboutSection.module.scss";

interface AboutSectionProps {
  elementRef: MutableRefObject<HTMLDivElement | null>;
}

export const AboutSection = ({ elementRef }: AboutSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ref, inView] = useInView({ amount: "all" });

  useEffect(() => {
    if (inView && window.scrollY !== 0) {
      navigate(ABOUT_PATH);
    }
  }, [inView, navigate]);

  return (
    <ContentWrapper className={styles.ContentWrapper} elementRef={elementRef}>
      <div className={styles.AboutSection} ref={ref}>
        <div className={styles.Title}>{t("aboutSection.title")}</div>
        <div className={styles.Subtitle}>{t("aboutSection.subtitle")}</div>
        <Grid container rowSpacing={5} columnSpacing={7.5}>
          <Grid item md={6} xs={12} display="flex">
            <div className={styles.Icon}>
              <SecuredInfrastructure />
            </div>
            <div className={styles.Content}>
              <div className={styles.TitleTile}>
                {t("aboutSection.section1.title")}
              </div>
              <div className={styles.SubtitleTitle}>
                {t("aboutSection.section1.subtitle")}
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12} display="flex">
            <div className={styles.Icon}>
              <ReadOnlyBankConnection />
            </div>
            <div className={styles.Content}>
              <div className={styles.TitleTile}>
                {t("aboutSection.section2.title")}
              </div>
              <div className={styles.SubtitleTitle}>
                {t("aboutSection.section2.subtitle")}
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12} display="flex">
            <div className={styles.Icon}>
              <EnhancedAccountSecurity />
            </div>
            <div className={styles.Content}>
              <div className={styles.TitleTile}>
                {t("aboutSection.section3.title")}
              </div>
              <div className={styles.SubtitleTitle}>
                {t("aboutSection.section3.subtitle")}
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12} display="flex">
            <div className={styles.Icon}>
              <YourSelfDirectedAccount />
            </div>
            <div className={styles.Content}>
              <div className={styles.TitleTile}>
                {t("aboutSection.section4.title")}
              </div>
              <div className={styles.SubtitleTitle}>
                {t("aboutSection.section4.subtitle")}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ContentWrapper>
  );
};

interface IProps {
  className?: string;
}

export const InfoCircle = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}>
      <path
        d="M22.5 33.5H25.5V22H22.5V33.5ZM24 18.6C24.4667 18.6 24.85 18.4413 25.15 18.124C25.45 17.808 25.6 17.4167 25.6 16.95C25.6 16.5167 25.45 16.1413 25.15 15.824C24.85 15.508 24.4667 15.35 24 15.35C23.5333 15.35 23.15 15.508 22.85 15.824C22.55 16.1413 22.4 16.5167 22.4 16.95C22.4 17.4167 22.55 17.808 22.85 18.124C23.15 18.4413 23.5333 18.6 24 18.6ZM24 43C21.3667 43 18.892 42.5 16.576 41.5C14.2587 40.5 12.25 39.15 10.55 37.45C8.85 35.75 7.5 33.7413 6.5 31.424C5.5 29.108 5 26.6333 5 24C5 21.3667 5.5 18.8913 6.5 16.574C7.5 14.258 8.85 12.25 10.55 10.55C12.25 8.85 14.2587 7.5 16.576 6.5C18.892 5.5 21.3667 5 24 5C26.6333 5 29.1087 5.5 31.426 6.5C33.742 7.5 35.75 8.85 37.45 10.55C39.15 12.25 40.5 14.258 41.5 16.574C42.5 18.8913 43 21.3667 43 24C43 26.6333 42.5 29.108 41.5 31.424C40.5 33.7413 39.15 35.75 37.45 37.45C35.75 39.15 33.742 40.5 31.426 41.5C29.1087 42.5 26.6333 43 24 43ZM24 40C28.4667 40 32.25 38.45 35.35 35.35C38.45 32.25 40 28.4667 40 24C40 19.5333 38.45 15.75 35.35 12.65C32.25 9.55 28.4667 8 24 8C19.5333 8 15.75 9.55 12.65 12.65C9.55 15.75 8 19.5333 8 24C8 28.4667 9.55 32.25 12.65 35.35C15.75 38.45 19.5333 40 24 40Z"
        fill="#333333"
        fillOpacity="0.8"
      />
    </svg>
  );
};

import { Grid } from "@mui/material";
import { animated, useInView, useSpring } from "@react-spring/web";
import classNames from "classnames";
import { MutableRefObject, useEffect } from "react";
import styles from "./ContentWrapper.module.scss";

interface ContentWrapperProps {
  children: React.ReactNode;
  withoutAnimation?: boolean;
  className?: string;
  elementRef?: MutableRefObject<HTMLDivElement | null>;
  showBottomArrow?: boolean; // To show bottom arrow you need to add "border-top-color" to ContentWrapper style
}

const VISIBLE_ANIMATION = {
  y: 0,
  opacity: 1,
};
const HIDDEN_ANIMATION = {
  y: 100,
  opacity: 0,
};

const ContentWrapper = ({
  children,
  withoutAnimation,
  className,
  showBottomArrow,
  elementRef,
}: ContentWrapperProps) => {
  const [ref, inView] = useInView({ amount: 0.2 });
  const [animatedStyles, api] = useSpring(() => ({
    from: withoutAnimation ? VISIBLE_ANIMATION : HIDDEN_ANIMATION,
  }));

  useEffect(() => {
    if (!withoutAnimation && inView) {
      api.start({
        to: VISIBLE_ANIMATION,
        config: {
          mass: 1,
          tension: 280,
          friction: 60,
        },
      });
    }
  }, [withoutAnimation, inView, api]);

  return (
    <Grid
      container
      ref={elementRef}
      className={classNames(
        styles.Wrapper,
        {
          [styles["Wrapper--arrow"]]: showBottomArrow,
        },
        className,
      )}>
      <Grid item lg={1.5} md={0.5} sm={0.5} xs={0.25} />
      <Grid item lg={9} md={11} sm={11} xs={11.5}>
        <animated.div ref={ref} style={animatedStyles}>
          {children}
        </animated.div>
      </Grid>
      <Grid item lg={1.5} md={0.5} sm={0.5} xs={0.25} />
    </Grid>
  );
};

export default ContentWrapper;

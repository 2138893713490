export interface IBankItem {
    name: string,
    logoUrl: string,

}

export const BANKS: { [key: string]: IBankItem[] } = {
    "NO": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Nordea Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Nordea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Ikano Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "yA/YX (Resurs Bank)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/ya_yx_se.svg"
        },
        {
            "name": "Nordea Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Resurs Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/resurs_bank_se.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/esseno22_lu.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "DNB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/no/dnb_no.svg"
        },
        {
            "name": "Morrow Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/no/komplett_bank_no.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "DE": [
        {
            "name": "Bank im Bistum Essen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Burgdorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Alb eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Heinsberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Biberach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Berliner Volksbank eG Abteilung BIT",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Aresing-Gerolsbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Allgäuer Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Dettinger Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Rottaler Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSSK Kaufbeuren",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kssk_de.svg"
        },
        {
            "name": "SPK im Landkreis Schwandorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Heidenheimer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Lechrain eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Arnstadt-Ilmenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Eckernförder Bank eG Volksbank-Raiffeisenbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Grimma eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Beckum-Lippstadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Düsseldorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank eG, Warendorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Küps-Mitwitz-Stockheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Geseke",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bensberger Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volks- und Raiffeisenbank Prignitz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank DreiFranken eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Kelheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Donnersberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Schwaben Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Donau-Mindel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Ebersberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Regensburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Würzburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Göppingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Moselkrampen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Kreis Calw eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Höchberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Schwelm",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Flachsmeer eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bordesholmer Sparkasse AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bordesholmer_sparkasse_ag_de.svg"
        },
        {
            "name": "SPK Rottal-Inn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Feldatal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Herford",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_herford_de.svg"
        },
        {
            "name": "GRENKE BANK AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Wermelskirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Münsterländische Bank Thie & Co. KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Ostfriesische Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Visbek eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhein-Wehra eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bühlertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bad Oeynhausen - Porta Westfalica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Westerwald-Sieg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Voreifel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Südliche Weinstraße-Wasgau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Anröchte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Oder-Spree",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank eG, Hildesheim-Lehrte-Pattensen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wegscheid eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Nienburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Walsrode",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Chemnitz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Grebenhain",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Gera-Greiz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Plochingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Neuss",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VakifBank International AG, Wien ZN Deutschland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Ulm",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Haslach-Zell",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VBU Volksbank im Unterland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Sondelfingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "DZ BANK AG Deutsche Zentral-Genossenschaftsbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Raiffeisenbank Holzkirchen-Otterfing eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Strücklingen- Idafehn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Hirschau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Sangerhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Kusel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Daaden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Bitburg-Prüm",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "MUFG Bank (Europe) N.V.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/mufg_de.svg"
        },
        {
            "name": "VR-Bank Bonn Rhein-Sieg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Märkisch-Oderland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "KSK Soltau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Beckum-Wadersloh",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Moormerland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Schaafheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Forchheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Unteres Vilstal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Schwarzwald-Baar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bankhaus E. Mayer AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Jerichower Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Frankfurter Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/frankfurter_sparkasse_de.svg"
        },
        {
            "name": "SPK Prignitz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Glan-Münchweiler eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bergkamen-Bönen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Wiesental",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK St. Wendel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Amberg-Sulzbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank München-Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Bayern Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Witten",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Wittenberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Grävenwiesbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Erft eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank am Dreisessel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Südwestpfalz eG Pirmasens - Zweibrücken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Türkheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Breisgau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Herne",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Floß eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Feuchtwangen-Dinkelsbühl eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Offenburg/Ortenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Börßum-Hornburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Mönchengladbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Consorsbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/consors_finanz_de.svg"
        },
        {
            "name": "SPK Coburg-Lichtenfels",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Emsland-Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Kreditbank Evangelisch-Freikirchlicher Gemeinden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Ortenburg-Kirchberg v.W. eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Kontist GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kontist_de.svg"
        },
        {
            "name": "Raiffeisenbank Nördliche Bergstraße eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Uckermark",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Altötting-Mühldorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Deggendorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Niedere Alb eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Westmünsterland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank am Kulm eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Niedergrafschaft eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Altmark West",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Herford-Mindener Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK SoestWerl",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Göttingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Marl-Recklinghausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Oldenburgische Landesbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/olb_de.svg"
        },
        {
            "name": "Raiffeisenbank Mutlangen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Kreditbank eG, Hammah",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "NORD/LB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/nord_lb_de.svg"
        },
        {
            "name": "KSK Euskirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SSK Wedel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "SPK Harburg-Buxtehude",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Karlsruhe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Leverkusen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberpfalz NordWest eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Battenberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bank11 für Privatkunden und Handel GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Haag-Gars-Maitenbeth eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Europäisch-Iranische Handelsbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/eihbank_de.svg"
        },
        {
            "name": "SPK Saarbrücken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Ostprignitz - Ruppin eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Gemen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Salzlandsparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/salzlandsparkasse_de.svg"
        },
        {
            "name": "Raiffeisenbank Hochfranken West eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Biberach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "VR Bank Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Werhahn GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bankhaus_werhahn_de.svg"
        },
        {
            "name": "KSK Rottweil",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Wetzlar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Elztal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Neustadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wiesedermeer- Wiesede-Marcardsmoor eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Rosenheim-Bad Aibling",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Esens eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Baden-Württemberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "SPK Neumarkt i.d.OPf.-Parsberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Stendal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Consors Finanz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/consors_finanz_de.svg"
        },
        {
            "name": "Raiffeisenbank Kalbe-Bismark eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Kaiserstuhl eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Deutsche Kreditbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/dkb_de.svg"
        },
        {
            "name": "Bankhaus J. Faisst OHG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bankhaus_faisst_de.svg"
        },
        {
            "name": "Merck Finck Privatbankiers AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/merck_finck_de.svg"
        },
        {
            "name": "SPK Bühl",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Dessau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Augsburger Land West eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Weschnitztal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "SSK Schwedt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "SPK Aurich-Norden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Enniger-Ostenfelde-Westkirchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Pfullendorf-Meßkirch",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Odenwaldkreis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR-Bank Nordeifel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Ostprignitz-Ruppin",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Kasseler Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kasseler_sparkasse_de.svg"
        },
        {
            "name": "Volksbank Pfullendorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Beilngries eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Welling eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Trier",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR Bank Schwäbischer Wald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Augsburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "MLP Finanzdienstleistungen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Uckermark-Randow eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "MERKUR PRIVATBANK KGaA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Westküste eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Essen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR-Bank Mittelsachsen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Ehningen-Nufringen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Oberpfälzer Jura eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hypovereinsbank eBanking Global",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Raiffeisenbank Eifeltor eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Bösel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Erding eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ostlippe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Brandoberndorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Vogtland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Donner & Reuschel AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Lindenberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dessau-Anhalt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bank für Sozialwirtschaft AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sozialbank_de.svg"
        },
        {
            "name": "SPK Landshut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Straubing eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/raiffeisen_de.svg"
        },
        {
            "name": "Volksbank Essen-Cappeln eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Emmerich-Rees eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Arnstorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Aschaffenburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Wilhelmshaven eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Selm-Bork eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Westthüringen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Heinsberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ulrichstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank am Württemberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Nord-Ostsee SPK",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/nord_ostsee_spk_de.svg"
        },
        {
            "name": "Raiffeisenbank Reute-Gaisbeuren eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Genobank Mainz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "WEG Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/weg_bank_de.svg"
        },
        {
            "name": "Volksbank Bruchsal-Bretten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Deggendorf-Plattling-Sonnenwald",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Offenbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Bochum Witten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Enz plus eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "VR-Bank Spangenberg-Morschen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK zu Lübeck AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank im Grabfeld eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Heuchelheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Knoblauchsland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Oberberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Abtsgmünder Bank -Raiffeisen- eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Neumarkt i.d.OPf. eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Hochrhein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Freisinger Bank eG Volksbank-Raiffeisenbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Chemnitz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Ennepetal-Breckerfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Donau-Heuberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Garmisch-Partenkirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Donau-Iller Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Rottal-Inn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Franken eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Krefeld eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Alzey-Worms eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raesfeld und Erle eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Pfaffenwinkel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Grafschafter Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Heilsbronn-Windsbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinte Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Unstrut-Hainich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Bramgau-Wittlage eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Iserlohn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Wartburg-Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/wartburg_sparkasse_de.svg"
        },
        {
            "name": "Hagnauer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Suedwestpfalz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Pfaffenhofen a.d.Glonn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Aschau-Samerberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Altschweier eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Zeller Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Marburg-Biedenkopf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR Bank Starnberg-Herrsching-Landsberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Bad Staffelstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bochum",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Waldaschaff-Heigenbrücken eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Maßbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Seesen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Rhein-Mosel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "CVW-Privatbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Gladbacher Bank AG von 1922",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Nürnberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_nuernberg_client_de.svg"
        },
        {
            "name": "Volksbank Westerstede eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Westholstein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Heidelberger Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Lüneburger Heide eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Holvi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/holvi_de.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "SPK Dortmund",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Viersen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Dietersheim und Umgebung eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Niederrheinische Sparkasse RheinLippe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/niederrheinische_sparkasse_rheinlippe_de.svg"
        },
        {
            "name": "MKB Mittelstandskreditbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Stuttgart eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Schweinfurt-Haßberge",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Riesa eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Olpe-Drolshagen-Wenden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Vereinigte Volksbank eG Ganderkesee - Hude- Bookholzberg - Lemwerder",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volks- und Raiffeisenbank Saarpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank München",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "Volksbank eG Delmenhorst Schierbrok",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Fulda eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Mainschleife - Steigerwald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Heilbronn Schwäbisch Hall eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bankverein Bad Hersfeld-Rotenburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Allgäuer Volksbank eG Kempten-Sonthofen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Südkirchen-Capelle-Nordkirchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Solingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Ersingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Halle (Westf.)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "ABN AMRO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/abn_amro_asset_manager_oauth_client_be.svg"
        },
        {
            "name": "SSK Düsseldorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Mittweida eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank München eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_muenchen_client_de.svg"
        },
        {
            "name": "KSK Bautzen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "VR Bank Ihre Heimatbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Aidlingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dettenhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "ING (DiBa)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ing_diba_de.svg"
        },
        {
            "name": "Volksbank Süd-Emsland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "net-m privatbank 1891 AG Aktiengesellschaft",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mittelholstein AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Rhein Neckar Nord",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Saarpfalz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Darmstadt - Südhessen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Erlenbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Gelnhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "KSK Heidenheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Backnang eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Genossenschaftsbank Unterallgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hümmlinger Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Postbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/postbank_de.svg"
        },
        {
            "name": "SPK Neuburg-Rain",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Gießen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR BANK Dinklage-Steinfeld eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Kreis Kelheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR PLUS Altmark-Wendland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Lauterbach-Schlitz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Rupertiwinkel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Ehekirchen-Oberhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Bad Kissingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Versmold eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Waldecker Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bibertgrund eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Nienburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Nahe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Haßberge eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Dornstetten-Horb eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Gummersbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volks- und Raiffeisenbank Fürstenwalde Seelow-Wriezen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Lüdenscheid",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank pur eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Osnabrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Langenfeld (Rheinland)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Emstek eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VSK Emsdetten-Ochtrup",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/vsk_emsdetten_ochtrup_de.svg"
        },
        {
            "name": "Aktivbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Gronau-Ahaus eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Budenheimer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Kyffhäusersparkasse Artern-Sondershausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kyffhausersparkasse_artern_sondershausen_de.svg"
        },
        {
            "name": "Volksbank im Wesertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Fürst Fugger Privatbank Aktiengesellschaft",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Rüsselsheimer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Zorneding eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank in Südoldenburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Konstanz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Berliner Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/berliner_sparkasse_csv_de.svg"
        },
        {
            "name": "SPK Einbeck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank eG Wümme-Wieste",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Kempen-Grefrath eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Schwaben-Bodensee",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Mittelthüringen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Bamberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Bodensee",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Hildburghausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Kirtorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Lippstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Vereinigte Sparkasse im Märkischen Kreis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/vereinigte_sparkasse_im_markischen_kreis_de.svg"
        },
        {
            "name": "Mittelbrandenburgische SPK in Potsdam",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/mittelbrandenburgische_spk_in_potsdam_de.svg"
        },
        {
            "name": "Nassauische Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/nassauische_sparkasse_de.svg"
        },
        {
            "name": "KSK Schwalm-Eder",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Wittgenstein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Trossingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR GenoBank DonauWald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Neckartal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Main-Spessart eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Berlin-Brandenburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_berlin_brandenburg_client_de.svg"
        },
        {
            "name": "SPK Mansfeld-Südharz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Uecker-Randow",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bopfinger Bank Sechta-Ries eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ulm-Biberach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ascheberg-Herbern eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hameln-Weserbergland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Ühlfeld-Dachsbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank eG, Monheim am Rhein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Werdenfels eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Vogtland-Saale-Orla eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank in Südwestfalen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hochsauerland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Obermain Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Ravensburg-Weingarten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Weberbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/weberbank_de.svg"
        },
        {
            "name": "Hannoversche Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Fränkische Schweiz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rheinböllen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hanau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Düsseldorf Neuss eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Steinheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhein-Nahe-Hunsrück eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Isar-Vils eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Neuburg-Rain eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Schwanewede eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "St. Galler Kantonalbank Deutschland AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Traunstein-Trostberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "VR Bank Weimar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Fläming-Elsterland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bidingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Darlehnskasse Bockum-Hövel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Neuwied",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Meißen Großenhain eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Rhein-Nahe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Regensburg-Wenzenbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Uelzen-Salzwedel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Kreditbank Bühlertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Wittgenstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Lichtenfels-Ebern eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Gymnich eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparkasse an der Lippe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparkasse_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberaudorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Unicredit Bank AG (Hypovereinsbank) UK branch",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "SPK Bad Tölz-Wolfratshausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Burbach-Neunkirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Westeifel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Remscheid",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Bissingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Seestermühe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Pfaffenhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Dieburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank im Donautal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Spree-Neiße eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Bamberg-Forchheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "1822direkt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_1822_direct_de.svg"
        },
        {
            "name": "SPK Niederlausitz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Borken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Leonberg-Strohgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "HSBC Trinkaus & Burkhardt AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ar/hsbc_ar.svg"
        },
        {
            "name": "Raiffeisenbank Borken Nordhessen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Ansbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Buch-Eching eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Augsburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Hypovereinsbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Volks- und Raiffeisenbank Saale-Unstrut eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "National-Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/au/national_bank_of_australia_au.svg"
        },
        {
            "name": "SSK Mönchengladbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Mittlerer Neckar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Trier eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bechhofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Fürth",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Baumberge eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Cronbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- u. Kredit-Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "European Bank for Financial Services",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ebase_de.svg"
        },
        {
            "name": "Kieler Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Biebergrund-Petersberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Passau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Hohenloher Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Neustadt a.d. Aisch-Bad Windsheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "J&T BANKA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Raiffeisenbank Geislingen-Rosenfeld eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Volkmarsen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Böblingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Regenstauf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Berghülen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Barnim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Koblenz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Rheine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "SSK Wuppertal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Heimbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bad Kötzting eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Münsterland Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK München Starnberg Ebersberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Gütersloh-Rietberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "PSD Bank Kiel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_kiel_client_de.svg"
        },
        {
            "name": "SPK Mittelmosel-Eifel-Mosel-Hunsrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Gunzenhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Fulda",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Lengerich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Mittelhessen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Pfaffenhofen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VSK Goch-Kevelaer-Weeze",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/vsk_goch_kevelaer_weeze_de.svg"
        },
        {
            "name": "VR-Bank Hunsrück-Mosel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "SPK Hochfranken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisen - meine Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Kurhessische Landbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Berlin eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wesermarsch-Süd eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Sprockhövel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Amberg-Sulzbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Geeste-Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Kierspe-Meinerzhagen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Genossenschaftsbank Weil im Schönbuch eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Nordharz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Baisweil-Eggenthal-Friesenried eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Fürstlich Castell'sche Bank Credit-Casse AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberteuringen-Meckenbeuren eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "BTV Bank für Tirol und Vorarlberg AG Zweigniederlassung Deutschland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Oberpfalz Nord",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Hohenzollern-Balingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Meißen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Mittleres Erzgebirge eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Oyten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Neustadt-Vohenstrauß eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Sigmaringen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Senden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Plankstetten AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Steinfurt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Krautheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "EDEKABANK AG Hamburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Rastatt-Gernsbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Worms-Alzey-Ried",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Eschenbach Neustadt (a.d. Waldnaab)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Rosenstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Burglengenfeld eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Geest eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "LSK Oldenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/lsk_oldenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Rehling eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Alb-Blau-Donau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Internationales Bankhaus Bodensee AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Niederbayern-Mitte",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bankhaus Max Flessa KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Harzsparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/harzsparkasse_de.svg"
        },
        {
            "name": "Volksbank eG - Die Gestalterbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Westerwald Bank eG Volks- und Raiffeisenbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Osterholz-Scharmbeck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Commerzbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/commerzbank_de.svg"
        },
        {
            "name": "Steyler Bank GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Rhein-Haardt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Wunstorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank-Raiffeisenbank Glauchau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhein-Ruhr eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Ostalb eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Miesbach-Tegernsee",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Lüneburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Bad Windsheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bank 1 Saar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Griesstätt-Halfing eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Saale-Orla",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Niederrhein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Siegen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Westkreis Fürstenfeldbruck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Erlangen Höchstadt Herzogenaurach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Wiedenbrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Kaarst eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Starkenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Landesbank Saar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/landesbank_saar_de.svg"
        },
        {
            "name": "Commerzbank Embedded",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/commerzbank_de.svg"
        },
        {
            "name": "SPK Bensheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "RSB Retail+Service Bank GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Flein-Talheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Südwest eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Schleswig-Mittelholstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Emden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Haaren eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Kieselbronn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Bad Salzungen Schmalkalden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Magdeburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Rhön-Rennsteig SPK",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/rhon_rennsteig_spk_de.svg"
        },
        {
            "name": "Volksbank Kleverland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Weißenburg-Gunzenhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Heilbronn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Wyhl eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Darmstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "PSD Bank Karlsruhe-Neustadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_karlsruhe_neustadt_client_de.svg"
        },
        {
            "name": "Raiffeisenbank Hallertau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Alteglofsheim-Hagelstadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Bönen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Nordthüringer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSSK Dillingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kssk_de.svg"
        },
        {
            "name": "Volksbank Emstal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bernhauser Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Halle/Westf. eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Müritz-Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/muritz_sparkasse_de.svg"
        },
        {
            "name": "Deutsche Bundesbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bundesbank_de.svg"
        },
        {
            "name": "Volksbank Klettgau-Wutöschingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "LBBW (Landesbank Baden-Württemberg)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/lbbw_de.svg"
        },
        {
            "name": "Raiffeisenbank München-Süd eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Eurocity Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Gotha",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SSK Bad Pyrmont",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Meßkirch eG Raiffeisenbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Rhein-Ruhr eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_rhein_ruhr_client_de.svg"
        },
        {
            "name": "VR-Bank Westmünsterland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Kastellaun eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Unteres Inntal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Braunschweig eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Minden-Lübbecke",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Münchner Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Koblenz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hamburger Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Nordschwarzwald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dreiländereck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Erding-Dorfen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Bühl eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank eG, Itzehoe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Oder-Spree eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Unteres Zusamtal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Halle (Saale) eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Ikano Bank AB (publ) Zweigniederlassung Deutschland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Nüdlingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Schlangen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Altenburger Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Zwickau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Singoldtal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Landwirtschaftliche Rentenbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Merzig-Wadern",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Paderborn-Detmold",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_paderborn_detmold_de.svg"
        },
        {
            "name": "KSK Waiblingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Ried eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinigte Volksbank eG, Brakel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Haan (Rheinland)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "SPK Regen-Viechtach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VietinBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "KSK Saalfeld-Rudolstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Mainspitze eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Deutsche Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/deutsche_bank_de.svg"
        },
        {
            "name": "VR-Bank Taufkirchen-Dorfen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Breisgau Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hamburger Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/hamburger_sparkasse_de.svg"
        },
        {
            "name": "Ikano Bank AB Cards (publ) Zweigniederlassung Deutschland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "SPK Waldeck-Frankenberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Rattiszell-Konzell eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Taufkirchen-Oberneukirchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Köthen-Bitterfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Radevormwald-Hückeswagen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Lohne-Mühlen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Saalesparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/saalesparkasse_de.svg"
        },
        {
            "name": "SPK Hamm",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Erkelenz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Südhardt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VerbundVolksbank OWL eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Weser-Elbe Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/weser_elbe_sparkasse_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberursel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Coburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mitten im Sauerland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Triodos Bank N.V. Deutschland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Ziraat Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ziraat_bank_de.svg"
        },
        {
            "name": "KSK Northeim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Aitrang-Ruderatshofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Gilching eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Oberhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Mehring-Leiwen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Störmede-Hörste eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Nottuln eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank GMHütte-Hagen-Bissendorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Langendernbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Schlüchtern",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "VR Bank Kitzingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Burgenlandkreis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Schwäbisch Hall-Crailsheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Vereinigte Volksbank Raiffeisenbank eG, Wittlich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Freiburg-Nördlicher Breisgau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Aschaffenburg-Alzenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Lauterecken eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wittelsbacher Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Südmünsterland-Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Mecklenburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Limburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Tüngental eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank HessenLand eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Olpe-Wenden-Drolshagen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "BBBank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_bbbank_client_de.svg"
        },
        {
            "name": "SPK Wittenberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Südholstein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "levoBank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "GLS Gemeinschaftsbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bethmann Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bethmann_bank_de.svg"
        },
        {
            "name": "Volksbank Remseck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "DKM Darlehnskasse Münster eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Südliches Ostallgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Pommersche Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Unterwesterwald",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Friedrichshafen-Tettnang eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Vechta eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Hannover eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/psd_bank_de.svg"
        },
        {
            "name": "SPK IngolstadtEichstätt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Vereinigte Volksbank eG Saarlouis - Losheim am See - Sulzbach/Saar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Westhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "solarisBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/solaris_bank_de.svg"
        },
        {
            "name": "SPK Bad Hersfeld-Rotenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Wüstenselbitz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Werratal-Landeck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Demmin eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Worpswede eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank in Südniedersachsen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dünnwald-Holweide eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Mittelhaardt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Stendal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Eisenberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Überwald-Gorxheimertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Südstormarn Mölln eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Mittelschwaben eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Main-Rhön eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spreewaldbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Santander",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ar/santander_rio_ar.svg"
        },
        {
            "name": "SSK Schwerte",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Schrozberg- Rot am See eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Bank Eschweiler eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bank für Kirche und Caritas eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Schwabmünchen-Stauden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Günzburg-Krumbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "HAUSBANK MÜNCHEN eG Bank für Haus- und Grundbesitz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Chamer Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinigte VR Bank Kur- und Rheinpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Westenholz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Syke",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank-Raiffeisenbank Laupheim-Illertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Bersenbrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Hohenlohe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Grünberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Nordhorn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Struvenhütten",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Sinzing eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Aachener Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Kassel Göttingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Anger eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Fredenbeck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Lampe KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bankhaus_lampe_de.svg"
        },
        {
            "name": "VR Bank Niederbayern-Oberpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Emsländische Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Grebenstein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Lübeck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Märkische Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Thüringen Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "TARGOBANK AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/targobank_es.svg"
        },
        {
            "name": "SSK München",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "KSK Börde",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Gruibingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Thannhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rottweil eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bad Saulgau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Oldenburg Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Erding eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "ODDO BHF-Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/oddo_bhf_de.svg"
        },
        {
            "name": "Qonto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "SPK Bonndorf-Stühlingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank eG im Kreis Freudenstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Kempten-Oberallgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "UNION-BANK AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Alzey-Land-Schwabenheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Heroldsbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Chiemgau-Nord-Obing eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Westallgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Staufen-Breisach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Hanauerland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Berliner Sparkasse (Embedded)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/berliner_sparkasse_csv_de.svg"
        },
        {
            "name": "Bankhaus Rautenschlein AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR PartnerBank eG Chattengau-Schwalm-Eder",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Stade",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Köln Bonn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Münsingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Mittelfranken Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Volksbank Bremen-Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Scheeßel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Rhein-Lippe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Ismaning Hallbergmoos Neufahrn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Sauerland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Zuffenhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Lahr eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "SPK Langen-Seligenstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Neunkirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR Bank Südpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Augsburg-Ostallgäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Ries eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Kaiserslautern",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "VR Bank Riedlingen-Federsee eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Freudenberg-Niederfischbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Salem-Heiligenberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Mittlerer Schwarzwald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Mötzingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Bauer Aktiengesellschaft",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Airbus Bank GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Pirna eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Bayreuth-Hof eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Schrobenhausener Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Freiburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Donauwörth",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR-Bank Alzenau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Sandhofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Lausitz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Main-Kinzig-Büdingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bruck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Döbeln",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "KSK Anhalt-Bitterfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Rastede eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Main-Tauber eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Jena-Saale-Holzland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Eichenbühl und Umgebung eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Isar-Loisachtal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Fürstenfeldbruck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Muldental",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Spar- und Kreditbank des Bundes Freier evangelischer Gemeinden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bremer LB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bremer_lb_de.svg"
        },
        {
            "name": "Standard Chartered",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/standard_chartered_gb.svg"
        },
        {
            "name": "SPK Werra-Meißner",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank - Raiffeisenbank Vilshofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Alxing-Brucker Genossenschaftsbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Frankfurter Volksbank RHEIN-MAIN eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/frankfurter_volksbank_de.svg"
        },
        {
            "name": "Sparkasse an Volme und Ruhr",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR Bank Lahn-Dill eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sylter Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Südheide - Isenhagener Land - Altmark",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Elsavatal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "IBM Deutschland Kreditbank GmbH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Heidelberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Brenztal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank in Holstein eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Berg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Evangelische Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK KölnBonn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Kulmbach-Kronach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank im Fuldaer Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Memmingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Haltern am See",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Vorpommern eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Köln",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "OstseeSparkasse Rostock",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ostseesparkasse_rostock_de.svg"
        },
        {
            "name": "SPK St.Blasien",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Mainfranken Würzburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Oberbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/oberbank_at.svg"
        },
        {
            "name": "SPK Bayreuth",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Südpfalz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Elbe-Elster",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Echterdinger Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Herzogtum Lauenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "KSK Ludwigsburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK im Landkreis Cham",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Lauenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberferrieden-Burgthann eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Löningen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ober-Mörlen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Tuttlingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Dortmund-Nordwest eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Owschlag",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Baden-Baden Gaggenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank im Hochsauerland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dresden-Bautzen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ruhr Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Hessen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "VR-Bank Gerolzhofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mittelfranken-Süd",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Schwalmstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Beilstein-Ilsfeld- Abstatt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Gmund am Tegernsee eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Bremerhaven-Cuxland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Burgebrach-Stegaurach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Immenstadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Düren",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Filder eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Mosbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rathenow eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Rheingauer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank zwischen den Meeren eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Butjadingen- Abbehausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Laubach-Hungen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Märkisches Sauerland Hemer - Menden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR Bank München Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Allgäu-Oberschwaben eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Kirnau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank eG, Alsheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Fichtelgebirge- Frankenwald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ochtrup-Laer eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Holstein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Schnathorst eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Niederwallmenach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ing_diba_de.svg"
        },
        {
            "name": "SPK Hattingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        },
        {
            "name": "Volksbank eG, Wolfenbüttel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhede eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank eG Heuberg-Winterlingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ettlingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Gladbeck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Zollernalb",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "PSD Bank RheinNeckarSaar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_rheinneckarsaar_client_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberes Gäu eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Delitzsch eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Scharrel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Saarlouis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Otto M. Schröder Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Lübbecker Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Berchtesgadener Land",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Attendorn-Lennestadt-Kirchhundem",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Bremische Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Isbank AG Zentrale Frankfurt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/isbank_de.svg"
        },
        {
            "name": "SSK Magdeburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "VR Bank Dreieich-Offenbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Harzer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Birkenfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Oldenburger Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Kreditbank Rheinstetten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Passau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Baunatal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Zevener Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wald-Görisried eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wimsheim-Mönsheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Onstmettinger Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Degussa Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/degussa_bank_de.svg"
        },
        {
            "name": "Volksbank Lastrup eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Vest Recklinghausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Kierspe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rietberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Wiesbadener Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSSK Wasserburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/kssk_de.svg"
        },
        {
            "name": "VR-Bank NordRhön eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Nürnberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR-Bank Neu-Ulm eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Groß-Gerau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Stauden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Parkstetten eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Hamburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "KSK Esslingen-Nürtingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SSK Versmold",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank Wißmar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank in der Hohen Mark eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinigte Volksbanken eG, Sindelfingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Roth-Schwabach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Bad Saulgau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Leipziger Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Stade - Altes Land",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Lemgo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Leezen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Gevelsberg-Wetter",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Hohenlimburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Beuerberg-Eurasburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Tüssling-Unterneukirchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhein-Erft-Köln eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank West",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "Bankhaus Anton Hafner KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bankhaus_anton_de.svg"
        },
        {
            "name": "Raiffeisenbank Mittenwald eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Rhein-Hunsrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Mecklenburger Seenplatte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Spar- und Darlehnskasse Börde Lamstedt-Hechthausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bayerische BodenseeBank - Raiffeisen - eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Pforzheim eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "LIGA Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Kraichgau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Butzbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hildesheim Goslar Peine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Ravensburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Kirchweihtal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank eG Magstadt-Weissach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Schrobenhausener Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Schupbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Neckartal-Odenwald",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Evenord-Bank eG-KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Oberfranken Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Hannover eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Uelzen Lüchow-Dannenberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Braunlage eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Bad Laer-Borgloh-Hilter-Melle",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Böllingertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Kaiserslautern eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Allgäu",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "BSK Reichenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bsk_reichenau_de.svg"
        },
        {
            "name": "SPK Vorpommern",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Überherrn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hegau-Bodensee",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Tauberfranken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Euskirchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank eG Bergisch Gladbach-Leverkusen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Vorderpfalz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Landshuter Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Rahden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Volksbank eG Braunschweig Wolfsburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank eG, Wyk auf Föhr",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Ostbayern eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "SPK am Niederrhein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Bütthard-Gaukönigshofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rot eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Südtiroler Sparkasse AG Niederlassung München",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Dithmarscher Volks- und Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Varel - Nordenham eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Grafschaft Diepholz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Baiertal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Sonneberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Cuxhaven",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "SPK Arnsberg-Sundern",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Frankfurter Bankgesellschaft (Deutschland) AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bremen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Schaumburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Westerkappeln-Saerbeck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Schwarzwald-Donau-Neckar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mittelsachsen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Vulkaneifel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Haselünne eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Neumünster eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Sulmtal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wangen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Bocholt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "SPK Rotenburg Osterholz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "EthikBank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ethikbank_de.svg"
        },
        {
            "name": "SPK Neu-Ulm-Illertissen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Elbmarsch eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Grainet eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Nürnberger Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Dortmunder Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Wickede (Ruhr) eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Rhein-Neckar eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Aschberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Landsberg-Ammersee eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Mayen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK LeerWittmund",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Staufen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Rhein-Lahn-Limburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Hengersberg-Schöllnach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Landkreis Passau-Nord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Dammer Berge eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SSK Delbrück",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "Raiffeisenbank Raisting eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Dachau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Oberhessen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Donauwörth eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Dachau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Wilhelmshaven",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "VR-Bank Ostbayern-Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Überlingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Deisslingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Bobingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Pax-Bank eG Kirche und Caritas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Eifel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Neubrandenburg-Demmin",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Pforzheim Calw",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Fresena eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Eutin Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Landsberg-Dießen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Bottrop",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Moosburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Duderstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Eschlkam-Lam- Lohberg-Neukirchen b. Hl. Blut eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG, Adelebsen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Gebhardshain eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Hardt-Bruhrain eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Möckmühl eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sparda-Bank Nürnberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sparda_de.svg"
        },
        {
            "name": "SPK Duisburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Simmerath",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Ahrweiler",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "SPK Hochrhein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Winterbacher Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank in Mittelbaden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hoerner Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Frankenberger Bank Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Bad Salzuflen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank HessenNord eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Brandenburger Bank Volksbank-Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Oberpfalz Süd eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Gebr. Martin AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bankhaus_gebr_martin_de.svg"
        },
        {
            "name": "Volksbank Breisgau- Markgräflerland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Börde-Bernburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Ellwanger & Geiger AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinigte Volksbank eG Bramgau Osnabrück Wittlage",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Engen-Gottmadingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Stadt- und Kreissparkasse Leipzig",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/stadt_und_kreissparkasse_leipzig_de.svg"
        },
        {
            "name": "Volksbank Albstadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bank für Kirche und Diakonie eG - KD-Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Förde SPK",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/forde_spk_de.svg"
        },
        {
            "name": "Scharnhauser Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Schermbeck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Münsterland-Ost",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Vereinigte Volksbank Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volks- und Raiffeisenbank Muldental eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Bankhaus Neelmeyer",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bhn_de.svg"
        },
        {
            "name": "Volksbank Löbau-Zittau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank eG, Würselen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Eichsfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Mainzer Volksbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Ammerbuch eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank im Oberland eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Nordhümmling eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hilden-Ratingen-Velbert",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Niedersachsen-Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Lorup eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen Spar+Kreditbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Gescher eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Vereinigte Volksbank Raiffeisenbank eG, Reinheim",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Ottenbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank im Harz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank West eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_west_client_de.svg"
        },
        {
            "name": "VR-Bank Ellwangen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Wallgau-Krün eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Falkenstein-Wörth eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Rodenbach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Dillenburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Hamm / Sieg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Reutlingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Raiffeisenbank Bad Schussenried-Aulendorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Kraichgau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Melle",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Bankhaus RSA eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank an der Niers eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Maitis eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Westfalen-Lippe eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psd_westfalen_lippe_client_de.svg"
        },
        {
            "name": "Raiffeisenbank eG Büchen-Crivitz-Hagenow-Plate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Landau-Mengkofen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Erzgebirgssparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/erzgebirgssparkasse_de.svg"
        },
        {
            "name": "VR-Bank Landshut eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Aindling eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Ostalb",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Nordoberpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Brühler Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Celle-GifhornWolfsburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Gelsenkirchen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Tübingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Oberbayern Südost eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Genossenschaftsbank eG München",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Metropolregion Nürnberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hochschwarzwald",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank eG, Gardelegen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Spree-Neiße",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Wolfach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Kurpfalz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Gabler-Saliter Bankgeschäft AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank in der Region eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mülheim a.d. Ruhr",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Bielefeld-Gütersloh eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Emsland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "Taunus Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/taunus_sparkasse_de.svg"
        },
        {
            "name": "VR Bank Bad Orb-Gelnhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Nordhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Ostsächsische Sparkasse Dresden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ostsachsische_sparkasse_dresden_de.svg"
        },
        {
            "name": "SPK Mecklenburg-Strelitz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Thurnauer Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Elmshorn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Ermstal-Alb eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hohenlohekreis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Mecklenburg-Schwerin",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Verden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank eG Gera · Jena · Rudolstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Niederschlesien eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Aachen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Bocholt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Hiltenfingen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Neustadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bad Neustadt a.d. Saale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Oberland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank RheinAhrEifel eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Osterode am Harz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK UnnaKamen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SSK Barsinghausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ssk_de.svg"
        },
        {
            "name": "KSK Freudenstadt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "KT Bank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Volksbank im Bergischen Land eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank eG Westrhauderfehn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Auerbach-Freihung eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Hannover",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Altshausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Weilburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "GENO BANK ESSEN eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Handels- und Gewerbebank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mecklenburg-Nordwest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Südwestbank AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Neumarkt- St. Veit - Reischach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Solling eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Ludwigsburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Krefeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Winsener Marsch eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Zwickau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Mainz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Aichhalden-Hardt- Sulgen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Elsen-Wewer-Borchen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "meine Volksbank Raiffeisenbank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank eG, Todenbüttel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Hermsdorfer Kreuz eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisen-Volksbank Wemding eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Bielefeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Oberlausitz-Niederschlesien",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Jever eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Altenburger Land",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Bad Kissingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Sulzbach-Rosenberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Aichach-Schrobenhausen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank in Schaumburg und Nienburg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Höxter",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Hameln-Stadthagen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR Bank Hohenneuffen-Teck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Hüttenberger Bank eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Comdirect",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/comdirect_de.svg"
        },
        {
            "name": "VR-Bank Mitte eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Miltenberg-Obernburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "SPK Lörrach-Rheinfelden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "KSK Göppingen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Fürstenfeldbruck eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "PSD Bank Hessen-Thüringen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_raiffeisen_psdbank_ht_client_de.svg"
        },
        {
            "name": "Raiffeisenbank Am Goldenen Steig eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Raiffeisenbank Regensburg-Schwandorf eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank eG Osnabrücker Nordland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Frankenhardt-Stimpfach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "VR-Bank Bonn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Markgräflerland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Seligenstadt eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Sydbank A/S Filiale Flensburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sydbank_de.svg"
        },
        {
            "name": "Landbank Horlofftal eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Rhein-Maas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Volksbank Heiden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Geiselhöring-Pfaffenberg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "North Channel Bank GmbH & Co. KG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Freising",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Norisbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/norisbank_de.svg"
        },
        {
            "name": "Volksbank eG, Friesoythe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Raiffeisenbank Denzlingen-Sexau eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Delbrück-Hövelhof eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Limbach eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "SPK Freyung-Grafenau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/spk_aschaffenburg_de.svg"
        },
        {
            "name": "Raiffeisenbank Ichenhausen eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Raiffeisenbank Steingaden eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Stade-Cuxhaven eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "Volksbank Hellweg eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/volksbank_de.svg"
        },
        {
            "name": "KSK Syke",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ksk_de.svg"
        }
    ],
    "BE": [
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/ing_be.svg"
        },
        {
            "name": "CBC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/cbc_oauth_client_be.svg"
        },
        {
            "name": "Deutsche Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/deutsche_bank_de.svg"
        },
        {
            "name": "Fintro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/fintro_be.svg"
        },
        {
            "name": "Banque CPH",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/cph_be.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Triodos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/triodos_es.svg"
        },
        {
            "name": "KBC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/kbc_be.svg"
        },
        {
            "name": "MeDirect Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/medirect_bank_be.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Argenta",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/argenta_bank_be.svg"
        },
        {
            "name": "bpost bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bpost_bank_be.svg"
        },
        {
            "name": "Belfius",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/belfius_be.svg"
        },
        {
            "name": "Hello bank!",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/hello_bank_be.svg"
        },
        {
            "name": "BNP Paribas Fortis (Easy Banking Business)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bnpparibas_fortis_be.svg"
        },
        {
            "name": "ABN AMRO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/abn_amro_asset_manager_oauth_client_be.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Byblos Bank Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/byblos_bank_be.svg"
        },
        {
            "name": "ABN AMRO Independent Asset Manager",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/abn_amro_asset_manager_oauth_client_be.svg"
        },
        {
            "name": "CBP Quilvest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/cbpxlull_be.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crelan",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/crelan_be.svg"
        },
        {
            "name": "ABN AMRO Private Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/abn_amro_asset_manager_oauth_client_be.svg"
        },
        {
            "name": "Axa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/axa_be.svg"
        },
        {
            "name": "Banque Transatlantique",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/cmcibeb1_be.svg"
        },
        {
            "name": "Bank de Kremer",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bank_de_kremer_oauth_client_be.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "KBC Brussels",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/kbc_brussels_be.svg"
        },
        {
            "name": "Banx",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/banx_be.svg"
        },
        {
            "name": "BNP Paribas Fortis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bnpparibas_fortis_be.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/ing_be.svg"
        },
        {
            "name": "Banque de Luxembourg Belgique",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bluxbe41_be.svg"
        },
        {
            "name": "Byblos Bank Retail",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/byblos_bank_be.svg"
        },
        {
            "name": "Fintro (Easy Banking Business)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/fintro_be.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "FI": [
        {
            "name": "American Express",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/american_express_at.svg"
        },
        {
            "name": "Nordea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/nordea_fi.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Ikano Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "Resurs Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/resurs_bank_se.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Säästöpankki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/saving_bank_fi.svg"
        },
        {
            "name": "SEB Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/seb_ee.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Oma Säästöpankki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/oma_savings_bank_fi.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "OP",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/op_pohjola_fi.svg"
        },
        {
            "name": "Nordea Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/nordea_fi.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Nordea Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/nordea_fi.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "S-Pankki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/spankki_fi.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/seb_ee.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Ålandsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/alandsbanken_fi.svg"
        },
        {
            "name": "POP Pankki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/pop_pankki_fi.svg"
        },
        {
            "name": "Handelsbanken Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "PT": [
        {
            "name": "Banco Atlântico Europa S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/atlantico_europa_oauth_client_pt.svg"
        },
        {
            "name": "Banco Comercial Português S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/banco_comercial_portugues_oauth_client_pt.svg"
        },
        {
            "name": "Caixa de Credito Agrícola Mutuo da Chamusca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/ccamc_oauth_client_pt.svg"
        },
        {
            "name": "Novo Banco S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/novo_banco_acores_client_pt.svg"
        },
        {
            "name": "Banco Santander S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/santander_pt.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Caixa Geral de Depósitos S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/cgd_oauth_client_pt.svg"
        },
        {
            "name": "Caixa Central de Crédito Agrícola Mútuo CRL",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/credito_agricola_oauth_client_pt.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Banco Português de Investimento S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/bpi_oauth_client_pt.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "UNICRE - Instituição Financeira de Crédito",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Banco CTT S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/ctt_oauth_client_pt.svg"
        },
        {
            "name": "Banco de Investimento Global S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/big_oauth_client_pt.svg"
        },
        {
            "name": "Banco Português de Gestão S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/bpg_oauth_client_pt.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Caixa de Crédito Agrícola Mútuo de Bombarral CRL",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/ccamb_oauth_client_pt.svg"
        },
        {
            "name": "Bankinter S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bankinter_es.svg"
        },
        {
            "name": "Banco BIC Português S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/bic_oauth_client_pt.svg"
        },
        {
            "name": "Cofidis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/cofidis_oauth_client_pt.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banco Montepio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/montepio_oauth_client_pt.svg"
        },
        {
            "name": "Banco Activobank S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/activobank_oauth_client_pt.svg"
        },
        {
            "name": "Caixa Económica da Misericórdia de Angra do Heroísmo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/cemah_pt.svg"
        },
        {
            "name": "Caixa Agrícola de Mafra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/ccamm_oauth_client_pt.svg"
        },
        {
            "name": "Abanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/abanca_es.svg"
        },
        {
            "name": "Novo Banco dos Açores S.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/novo_banco_acores_client_pt.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "Caixa de Crédito Agrícola Mútuo de Leiria CRL",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/credito_agricola_leiria_oauth_client_pt.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Caixa de Credito Agrícola Mutuo de Torres Vedras",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/ccamtv_oauth_client_pt.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "BG": [
        {
            "name": "BACB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/bacb_bg.svg"
        },
        {
            "name": "Tokuda Bank AD",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/tokudabank_bg.svg"
        },
        {
            "name": "Ziraat Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/ziraatbank_de.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/crypto_bg.svg"
        },
        {
            "name": "Пощенска Банка",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/postbank_bg.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Centralna Kooperativna Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "UniCredit Bulbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/unicredit_bulbank_bg.svg"
        },
        {
            "name": "UBB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ubb_bg.svg"
        },
        {
            "name": "Investbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/br/citibank_br.svg"
        },
        {
            "name": "Transact Europe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/transact_bg.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "ProCredit Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/md/procreditbank_md.svg"
        },
        {
            "name": "KBC Online Bulgaria",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/raiffeisen_oauth_client_bg.svg"
        },
        {
            "name": "Paynetics",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/paynetics_bg.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "DSK Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/dsk_bg.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Fibank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/my_fibank_bg.svg"
        },
        {
            "name": "TBIBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/tbibank_bg.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Bulgarian Development Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/bbr_bg.svg"
        },
        {
            "name": "DBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/sydbank_de.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "DK": [
        {
            "name": "Vestjysk Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/vestijysk_dk.svg"
        },
        {
            "name": "Pleo Financial Services A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/pleo_dk.svg"
        },
        {
            "name": "Nordea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Frørup Andelskasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/frorup_andelskasse_dk.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Ikano Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "Resurs Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/resurs_bank_se.svg"
        },
        {
            "name": "Coop Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/coop_bank_dk.svg"
        },
        {
            "name": "Swedbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Nykredit Bank A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nykredit_dk.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Maj Bank A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/maj_bank_dk.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Lollands Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/lollands_dk.svg"
        },
        {
            "name": "PenSam",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/pen_sam_dk.svg"
        },
        {
            "name": "Faster Andelskasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/faster_dk.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Nordea Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Hvidbjerg Bank A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/hvidbjerg_dk.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Møns Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/mons_dk.svg"
        },
        {
            "name": "Nordea Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/nordea_dk.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Fynske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/fynske_dk.svg"
        },
        {
            "name": "Jutlander Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/jutlander_dk.svg"
        },
        {
            "name": "Andelskassen Fælleskassen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Skandinaviska Enskilda Banken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/skandinaviska_enskilda_dk.svg"
        },
        {
            "name": "Frøslev - Mollerup Sparekasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/froslev_mollerup_dk.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Merkur Andelskasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/merkur_dk.svg"
        },
        {
            "name": "Danske Andelskassers Bank A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Lægernes Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/lagernes_dk.svg"
        },
        {
            "name": "Spar Nord Bank A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/spar_nord_dk.svg"
        },
        {
            "name": "Arbejdernes Landsbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/arbejdernes_landsbank_dk.svg"
        },
        {
            "name": "Oikos Andelskasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Totalbanken A/S",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/totalbanken_dk.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "LT": [
        {
            "name": "Swedbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lt/swedbank_lt.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/seb_ee.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Citadele Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/citadele_ee.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Luminor",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/luminor_ee.svg"
        },
        {
            "name": "Šiaulių Bankas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lt/siauliu_bankas_lt.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "LU": [
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ing_oauth_client_lu.svg"
        },
        {
            "name": "Banque de Luxembourg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bluxlull_lu.svg"
        },
        {
            "name": "BGL BNP Paribas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bgl_bnp_paribas_lu.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bitstamp",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bitstamp_lu.svg"
        },
        {
            "name": "Eurobank Private Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/erbklull_lu.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Intesa Sanpaolo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/sebklull_lu.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Argenta",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/argenta_bank_be.svg"
        },
        {
            "name": "Banco Safra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bsaflull_lu.svg"
        },
        {
            "name": "Skandinaviska Enskilda Banken S.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/esselull_lu.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Julius Baer",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/baerlulu_lu.svg"
        },
        {
            "name": "Banque de Patrimoines Prives",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bbpplull_lu.svg"
        },
        {
            "name": "Spuerkeess",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bceelull_lu.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "East-West United Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ewublull_lu.svg"
        },
        {
            "name": "Societe Generale Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_societe_generale_fr.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ccralull_lu.svg"
        },
        {
            "name": "BIL",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bil_oauth_client_lu.svg"
        },
        {
            "name": "CBP Quilvest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/cbpxlull_be.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "POST Luxembourg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ccpllull_lu.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Banque Transatlantique",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/cmcilul1_lu.svg"
        },
        {
            "name": "Union Bancaire Privée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/dbsalull_lu.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Bankinter",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/bkbklull_lu.svg"
        },
        {
            "name": "DNB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ubnllull_lu.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lu/ing_oauth_client_lu.svg"
        },
        {
            "name": "Handelsbanken Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "UniCredit International Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "HR": [
        {
            "name": "Zagrebacka banka d.d.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Karlovačka Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/kaba_hr.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/raiffeisen_oauth_client_hr.svg"
        },
        {
            "name": "Agram Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/agram_banka_hr.svg"
        },
        {
            "name": "Podravska Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/podravska_banka_hr.svg"
        },
        {
            "name": "Zagrebacka banka d.d. eBanking Global",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Slatinska Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/slatinska_banka_hr.svg"
        },
        {
            "name": "Erste&Steiermärkische Bank d.d.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/erste_hr.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Istarska Kreditna Banka Umag",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/istarska_kreditna_banka_umag_hr.svg"
        },
        {
            "name": "Zagrebacka banka d.d. Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "HPB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/hpb_hr.svg"
        },
        {
            "name": "Privredna Banka Zagreb",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/pbz_hr.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "LV": [
        {
            "name": "Swedbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lv/swedbank_lv.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/seb_ee.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Citadele Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/citadele_ee.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Luminor",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/luminor_ee.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "FR": [
        {
            "name": "Arkéa Banking Services",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/arkea_bank_fr.svg"
        },
        {
            "name": "Banque Populaire du Sud",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/sud_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Crédit Agricole des Savoie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole Val De France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque BCP",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/banque_bcp_fr.svg"
        },
        {
            "name": "Banque Populaire Méditerranée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/mediterranee_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Crédit Agricole Banque Chalus",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole du Morbihan",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse d'Epargne Grand Est Europe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_grand_est_europe_fr.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Crédit Agricole de Centre France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_centre_france_fr.svg"
        },
        {
            "name": "Crédit Agricole Centre Loire",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "La Banque Postale Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_banque_postale_fr.svg"
        },
        {
            "name": "Crédit Agricole Martinique-Guyane",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_martinique_guyane_fr.svg"
        },
        {
            "name": "Crédit Agricole Atlantique Vendée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_atlantique_vendee_fr.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Boursorama",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/boursorama_fr.svg"
        },
        {
            "name": "Crédit Agricole Provence Côte d'Azur",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "American Express",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/american_express_at.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Aquitaine Poitou-Charentes",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_aquitaine_fr.svg"
        },
        {
            "name": "Crédit Agricole de Normandie-Seine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Arkéa Banque Privée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/arkea_bank_fr.svg"
        },
        {
            "name": "Crédit Agricole du Languedo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque de Savoie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/banque_de_savoie_fr.svg"
        },
        {
            "name": "Banque Populaire Rives de Paris",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/rivesparis_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Crédit Agricole d'Aquitaine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole du Nord Est",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_nord_est_oauth_client_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance d'Auvergne et du Limousin",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_auvergne_limousin_fr.svg"
        },
        {
            "name": "Hello bank!",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/hello_fr.svg"
        },
        {
            "name": "Crédit Agricole Nord Midi-Pyrénées",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_nmp_fr.svg"
        },
        {
            "name": "Société Générale (Particuliers)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_societe_generale_fr.svg"
        },
        {
            "name": "Crédit Agricole de Charente-Maritime Deux-Sèvres",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole de Guadeloupe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_guadeloupe_fr.svg"
        },
        {
            "name": "Crédit Agricole Sud Rhone Alpes",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Ile-de-France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_ile_de_france_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Côte d'Azur",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_cote_d_azur_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance de Bourgogne Franche-Comté",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_bourgogne_fr.svg"
        },
        {
            "name": "Crédit Agricole Alpes Provence",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Mutuel du Sud-Ouest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/credit_mutuel_sud_ouest_fr.svg"
        },
        {
            "name": "Crédit Mutuel de Bretagne",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/credit_mutuel_bretagne_fr.svg"
        },
        {
            "name": "Crédit Agricole de la Corse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque Populaire Auvergne Rhône Alpes",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bpaura_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Ma Banque Enterprise (BNP Paribas)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bnpparibas_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Bretagne-Pays de Loire",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_bretagne_fr.svg"
        },
        {
            "name": "Crédit Agricole de Champagne Bourgogne",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_champagne_bourgogne_fr.svg"
        },
        {
            "name": "Shine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/shine_fr.svg"
        },
        {
            "name": "Crédit Agricole de La Réunion",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_reunion_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Loire Drôme Ardèche",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_loire_drome_ardeche_fr.svg"
        },
        {
            "name": "Crédit Agricole Toulouse 31",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance du Languedoc Roussillon",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_languedoc_roussillon_fr.svg"
        },
        {
            "name": "Banque Populaire du Nord",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/nord_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Natixis Wealth Management France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/wealthmanagement_natixis_fr.svg"
        },
        {
            "name": "Axa Banque",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banque Populaire Val de France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/valdefrance_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Crédit Agricole de Normandie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_normandie_fr.svg"
        },
        {
            "name": "Crédit Agricole de Charente-Périgord",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_charente_perigord_fr.svg"
        },
        {
            "name": "CIC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/cic_fr.svg"
        },
        {
            "name": "LCL Enterprise",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/lcl_prepaye_fr.svg"
        },
        {
            "name": "Crédit Agricole de la Touraine et du Poitou",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole du Centre Ouest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Coopératif",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/credit_cooperatif_fr.svg"
        },
        {
            "name": "Connexis Cash (BNP Paribas)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bnpparibas_fr.svg"
        },
        {
            "name": "LCL",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/lcl_prepaye_fr.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Ma Banque (BNP Paribas)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bnpparibas_fr.svg"
        },
        {
            "name": "Crédit Agricole de Paris et d'Ile-de-France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque Populaire Alsace Lorraine Champagne",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bpalc_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "BPE",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_sassari_bpercard_it.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ing_csv_fr.svg"
        },
        {
            "name": "Crédit Agricole du Finistere",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        },
        {
            "name": "Qonto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ing_csv_fr.svg"
        },
        {
            "name": "Crédit Agricole Loire Haute-Loire",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Société Générale (Professionnels)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_societe_generale_fr.svg"
        },
        {
            "name": "Crédit Agricole de l'Anjou et du Maine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Crédit Agricole Pyrenees Gascogne",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_pyrenees_gascogne_fr.svg"
        },
        {
            "name": "Crédit Agricole Sud Méditerranée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_sud_mediterranee_fr.svg"
        },
        {
            "name": "Crédit Agricole de Franche Compte",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "La Banque Postale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_banque_postale_fr.svg"
        },
        {
            "name": "Caisse d'Epargne CEPAC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_provence_alpes_corse_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Loire-Centre",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_loire_centre_fr.svg"
        },
        {
            "name": "Crédit Agricole Centre-est",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance de Midi-Pyrénées",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_midi_pyrenees_fr.svg"
        },
        {
            "name": "Crédit Agricole d'Ille-et-Villaine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque Populaire Bourgogne Franche-Comté",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bpbfc_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Hauts de France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_hauts_de_france_fr.svg"
        },
        {
            "name": "Banque populaire Grand Ouest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bpgo_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Crédit Agricole de Lorraine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_lorraine_fr.svg"
        },
        {
            "name": "Allianz Banque",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/allianz_bank_fr.svg"
        },
        {
            "name": "CIC Privée",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/cic_fr.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Crédit Agricole Alsace Vosges",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Banque Populaire Occitane",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/occitane_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Arkéa Banque Entreprises et Institutionnels",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/arkea_bank_fr.svg"
        },
        {
            "name": "Fortuneo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/fortuneo_fr.svg"
        },
        {
            "name": "Banque populaire Aquitaine Centre Atlantique",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/bpaca_banque_populaire_oauth_client_fr.svg"
        },
        {
            "name": "Société Générale (Enterprises)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_societe_generale_fr.svg"
        },
        {
            "name": "Crédit Agricole des Côtes d'Armor",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance Normandie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_normandie_fr.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "Crédit Agricole Nord de France",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse d'épargne et de prévoyance de Rhône Alpes",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/caisse_epargne_rhone_alpes_fr.svg"
        },
        {
            "name": "Crédit Agricole Brie Picardie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_alsace_vosges_fr.svg"
        },
        {
            "name": "Caisse Fédérale de Crédit Mutuel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/credit_mutuel_bretagne_fr.svg"
        }
    ],
    "HU": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/raiffeisen_hu.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "MBH Bank (ex-MKB)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "MBH Bank (ex-Takarékbank)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Erste Bank Hungary Zrt. (Corporate)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/erste_bank_hu.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "K&H",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/kh_hu.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "UniCredit Bank Hungary Zrt",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/unicredit_hu.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "CIB Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/cib_hu.svg"
        },
        {
            "name": "MagNet Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Raiffeisen Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/raiffeisen_hu.svg"
        },
        {
            "name": "CIB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/cib_hu.svg"
        },
        {
            "name": "Erste Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/erste_bank_hu.svg"
        },
        {
            "name": "OTP Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/otpbank_hu.svg"
        },
        {
            "name": "GRÁNIT Bank ZRT.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/granit_bank_hu.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "SE": [
        {
            "name": "American Express",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/american_express_at.svg"
        },
        {
            "name": "Nordea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/nordea_se.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Ikano Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "Resurs Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/resurs_bank_se.svg"
        },
        {
            "name": "Swedbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/swedbank_se.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "SEB Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/seb_se.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Northmill Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Nordea Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/nordea_se.svg"
        },
        {
            "name": "ICA Banken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/ica_api_se.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "SBAB Bank AB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/sbab_se.svg"
        },
        {
            "name": "Nordea Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/nordea_se.svg"
        },
        {
            "name": "SVEA Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/sveasess_se.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Lansforsakringar Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/seb_se.svg"
        },
        {
            "name": "Skandia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Prepaid Deluxe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/prepaid_deluxe_se.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Ålandsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fi/alandsbanken_fi.svg"
        },
        {
            "name": "Handelsbanken Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "SI": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Intesa Sanpaolo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/intesa_sanpaolo_it.svg"
        },
        {
            "name": "NLB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mk/nlb_tutunska_mk.svg"
        },
        {
            "name": "UniCredit Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Delavska Hranilnica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/delavska_hranilnica_si.svg"
        },
        {
            "name": "LON",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/colonya_es.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Deželna banka Slovenije",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/dbs_oauth_client_si.svg"
        },
        {
            "name": "SKB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/skb_si.svg"
        },
        {
            "name": "Sparkasse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/erste_bank_sparkasse_at.svg"
        },
        {
            "name": "Addiko Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/addiko_si.svg"
        },
        {
            "name": "Primorska hranilnica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/phv_si.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Gorenjska Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/gbkr_si.svg"
        },
        {
            "name": "Nova KBM",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/nkbm_si.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "BKS Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/si/bksbank_si.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "SK": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Fio Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/fio_token_sk.svg"
        },
        {
            "name": "VUB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/vub_sk.svg"
        },
        {
            "name": "ČSOB (KBC)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/csob_sk.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/raiffeisen_sk.svg"
        },
        {
            "name": "UniCredit Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Prima banka Slovensko",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/primabanka_sk.svg"
        },
        {
            "name": "Slovenská sporiteľňa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/slovenska_sporitelna_sk.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Tatra Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sk/tatra_banka_sk.svg"
        },
        {
            "name": "mBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/mbank_cz.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "GB": [
        {
            "name": "Lloyds Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/lloyds_gb.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Marks and Spencer",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/marks_and_spencer_oauth_client_gb.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Amazon Mastercard",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Bank of Scotland Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_scotland_gb.svg"
        },
        {
            "name": "Virgin Money",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/virgin_money_credit_card_gb.svg"
        },
        {
            "name": "Credecard",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Bank of Scotland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_scotland_gb.svg"
        },
        {
            "name": "Ulster Bank ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/ulster_gb.svg"
        },
        {
            "name": "HyperJar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/hyperjar_gb.svg"
        },
        {
            "name": "Topshop Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_topshop_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "thinkmoney",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/thkm_gb.svg"
        },
        {
            "name": "Cater Allen Private Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/cater_allen_gb.svg"
        },
        {
            "name": "MBNA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/mbna_gb.svg"
        },
        {
            "name": "Outfit Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_outfit_gb.svg"
        },
        {
            "name": "First Direct",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/first_direct_gb.svg"
        },
        {
            "name": "Barclaycard",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclaycard_gb.svg"
        },
        {
            "name": "Laura Ashley Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_lauraashley_gb.svg"
        },
        {
            "name": "Standard Chartered",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/standard_chartered_gb.svg"
        },
        {
            "name": "Fluid Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_fluid_gb.svg"
        },
        {
            "name": "Royal Bank of Scotland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_oauth_client_gb.svg"
        },
        {
            "name": "Starling",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/starlingbank_oauth_client_gb.svg"
        },
        {
            "name": "Co-operative Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/dk/coop_bank_dk.svg"
        },
        {
            "name": "Soldo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/soldo_it.svg"
        },
        {
            "name": "Handelsbanken Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "American Express",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/american_express_gb.svg"
        },
        {
            "name": "Royal Bank of Scotland ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Burton Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_burton_gb.svg"
        },
        {
            "name": "Guaranty Trust Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/gt_bank_gb.svg"
        },
        {
            "name": "Royal Bank of Scotland Bankline",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Allied Irish Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/aib_gb.svg"
        },
        {
            "name": "Evans Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_evans_gb.svg"
        },
        {
            "name": "Coutts",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/coutts_clearspend_gb.svg"
        },
        {
            "name": "Monzo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/monzo_oauth_client_gb.svg"
        },
        {
            "name": "Chelsea Building Society",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/cbs_bank_gb.svg"
        },
        {
            "name": "Habib Bank AG Zurich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/habib_bank_ag_zurich_gb.svg"
        },
        {
            "name": "NatWest International Online",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/natwest_business_gb.svg"
        },
        {
            "name": "Chase UK",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/jpmel_gb.svg"
        },
        {
            "name": "HSBC Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/hsbc_gb.svg"
        },
        {
            "name": "Argos Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Citi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/br/citi_connect_br.svg"
        },
        {
            "name": "Bank of Ireland 365 Online",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_ireland_gb.svg"
        },
        {
            "name": "TUI Credit Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_tui_gb.svg"
        },
        {
            "name": "NatWest ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/natwest_business_gb.svg"
        },
        {
            "name": "Ulster Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/ulster_gb.svg"
        },
        {
            "name": "Nomo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/nomo_gb.svg"
        },
        {
            "name": "Barclays",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclays_gb.svg"
        },
        {
            "name": "NatWest",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/natwest_oauth_client_gb.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "Halifax",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/halifax_oauth_client_gb.svg"
        },
        {
            "name": "Newpay Credit",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Dorothy Perkins Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_dorothyperkins_gb.svg"
        },
        {
            "name": "Ulster Bank Bankline",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/ulster_gb.svg"
        },
        {
            "name": "Yorkshire Building Society",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/ybs_bank_gb.svg"
        },
        {
            "name": "First Trust Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/first_trust_bank_gb.svg"
        },
        {
            "name": "NatWest Bankline",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/natwest_business_gb.svg"
        },
        {
            "name": "Santander",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/santander_gb.svg"
        },
        {
            "name": "Bank of Ireland Business On Line",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_ireland_gb.svg"
        },
        {
            "name": "Barclays Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclays_gb.svg"
        },
        {
            "name": "First Trust Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/first_trust_bank_gb.svg"
        },
        {
            "name": "Allpay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Lloyds Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/lloyds_oauth_client_gb.svg"
        },
        {
            "name": "NatWest International",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/natwest_business_gb.svg"
        },
        {
            "name": "Triodos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/triodos_gb.svg"
        },
        {
            "name": "Coutts ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/coutts_clearspend_gb.svg"
        },
        {
            "name": "Aqua Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_aqua_gb.svg"
        },
        {
            "name": "Pulse Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Danske Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Shanghai Commercial Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/shacombank_gb.svg"
        },
        {
            "name": "The Bank of East Asia, Limited",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_east_asia_oauth_client_gb.svg"
        },
        {
            "name": "TSB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/tsb_gb.svg"
        },
        {
            "name": "UW",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/utility_warehouse_oauth_client_gb.svg"
        },
        {
            "name": "Bank of Scotland Commercial",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_scotland_gb.svg"
        },
        {
            "name": "Virgin Money (Clydesdale)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/virgin_money_credit_card_gb.svg"
        },
        {
            "name": "Alpha FX",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Opus Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_opus_gb.svg"
        },
        {
            "name": "Marbles Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_marbles_gb.svg"
        },
        {
            "name": "HSBC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/hsbc_oauth_client_gb.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Onepay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/onepay_gb.svg"
        },
        {
            "name": "Connexis Cash (BNP Paribas)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bnpparibas_fortis_be.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Royal Bank of Scotland International Limited EQ",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Airwallex (Token)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/token_airwallex_gb.svg"
        },
        {
            "name": "United National Bank Limited (UBL)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/united_limited_bank_gb.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Capital One",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/capital_one_gb.svg"
        },
        {
            "name": "House of Fraser Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_houseoffraser_gb.svg"
        },
        {
            "name": "Mizuho Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/mizuho_bank_gb.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        },
        {
            "name": "Wallis Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_wallis_gb.svg"
        },
        {
            "name": "QNB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/qnb_fr.svg"
        },
        {
            "name": "Barclaycard Commercial Payments",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclaycard_commercial_payments_gb.svg"
        },
        {
            "name": "John Lewis Finance",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Nationwide",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/nationwide_gb.svg"
        },
        {
            "name": "Sumup",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "RBS One Account",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Arcadia Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Tesco Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/tesco_bank_gb.svg"
        },
        {
            "name": "Vanquis Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/vanquis_gb.svg"
        },
        {
            "name": "RBS Virgin One Account",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "HSBC Kinetic",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/hsbc_business_gb.svg"
        },
        {
            "name": "ABN AMRO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/abn_amro_asset_manager_oauth_client_be.svg"
        },
        {
            "name": "Cumberland Building Society",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/cumberland_gb.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "RBS NatWest One Account",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Allied Irish Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/aib_gb.svg"
        },
        {
            "name": "Barclays Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclays_gb.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Miss Selfridge Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_missselfridge_gb.svg"
        },
        {
            "name": "Lloyds Bank Commercial",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/lloyds_gb.svg"
        },
        {
            "name": "Debenhams Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_debenhams_gb.svg"
        },
        {
            "name": "Topman Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_topman_gb.svg"
        },
        {
            "name": "Royal Bank of Scotland International Limited ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/rbs_digital_banking_gb.svg"
        },
        {
            "name": "Tide",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/tide_gb.svg"
        },
        {
            "name": "Bip Cardless credit",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/newday_amazon_gb.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "B Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/sg/cimb_bank_sg.svg"
        },
        {
            "name": "iPayLinks",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/ipaylinks_gb.svg"
        }
    ],
    "IE": [
        {
            "name": "Elavon Financial Services",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/elavon_ie.svg"
        },
        {
            "name": "Bank of Ireland Business On Line",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_ireland_gb.svg"
        },
        {
            "name": "An Post Money Credit Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/anpostmoneycc_ie.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Sumup",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Allied Irish Banks Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/aib_ie.svg"
        },
        {
            "name": "Bank of Ireland 365 Online",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/bank_of_ireland_gb.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Barclays Bank Ireland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclays_gb.svg"
        },
        {
            "name": "Allied Irish Banks",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/aib_ie.svg"
        },
        {
            "name": "Ulster Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/ulster_ie.svg"
        },
        {
            "name": "Ulster Bank ClearSpend",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/ulster_ie.svg"
        },
        {
            "name": "Soldo Ireland and Europe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Barclays Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/barclays_gb.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Avantcard",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/avantcard_ie.svg"
        },
        {
            "name": "Ulster Bank Bankline",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ie/ulster_ie.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "EE": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Citadele Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/citadele_ee.svg"
        },
        {
            "name": "Coop Pank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/cooppank_ee.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Swedbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/lt/swedbank_lt.svg"
        },
        {
            "name": "SEB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/seb_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "LHV",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/lhv_ee.svg"
        },
        {
            "name": "AS TBB Pank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/tabu_ee.svg"
        },
        {
            "name": "Luminor",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/luminor_ee.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "MT": [
        {
            "name": "MeDirect Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/medirect_bank_be.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Bank of Valletta",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/bov_oauth_client_mt.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Lombard",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_territorio_lombardo_oauth_client_it.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "IS": [
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Islandsbanki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Arion banki",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Landsbankinn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "GR": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Piraeus Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/piraeus_bank_oauth_client_gr_xf.svg"
        },
        {
            "name": "Alpha Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gr/alpha_bank_gr.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "National Bank of Greece",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/national_bank_of_greece_oauth_client_gr_xf.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Eurobank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/eurobank_client_cy_xf.svg"
        },
        {
            "name": "COSMOTE Payments",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gr/cosmote_gr.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "IT": [
        {
            "name": "BCC Centro Lazio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_centro_lazio_oauth_client_it.svg"
        },
        {
            "name": "Banca Reale SpA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_reale_it.svg"
        },
        {
            "name": "Banca Popolare del Lazio Privati e Famiglie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bp_lazio_it.svg"
        },
        {
            "name": "ZKB - TRIESTE GORIZIA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_carso_oauth_client_it.svg"
        },
        {
            "name": "Banca Generali Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/generali_bank_oauth_client_at.svg"
        },
        {
            "name": "BCC Territorio Lombardo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_territorio_lombardo_oauth_client_it.svg"
        },
        {
            "name": "Sella Personal Credit",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sella_it.svg"
        },
        {
            "name": "BCC Alto Tirreno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_alto_tirreno_oauth_client_it.svg"
        },
        {
            "name": "BCC Dei Colli Albani",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_dei_colli_albani_oauth_client_it.svg"
        },
        {
            "name": "BCC Dell'Oglio e del Serio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_delloglio_e_del_serio_oauth_client_it.svg"
        },
        {
            "name": "CRA Castellana Grotte C.C.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "BCC Cassano delle Murge",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_cassano_delle_murge_oauth_client_it.svg"
        },
        {
            "name": "Cassa Raiffeisen Alta Pusteria (Raiffeisenkasse Hochpustertal)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "UnipolPay Retail S.P.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Sepafin",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sepafin_it.svg"
        },
        {
            "name": "Banca di Piacenza Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_piacenza_it.svg"
        },
        {
            "name": "BCC Marina di Ginosa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_marina_di_ginosa_oauth_client_it.svg"
        },
        {
            "name": "Imprebanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/imprebanca_it.svg"
        },
        {
            "name": "Banca di Verona e Vicenza Credito Cooperativo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_s_giorgio_quinto_valle_agno_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Freienfeld",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Banca di Credito Cooperativo di Cambiano Scarl",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_credito_cooperativo_di_cambiano_scarl_it.svg"
        },
        {
            "name": "Credito Valtellinese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_valtellinese_oauth_client_it.svg"
        },
        {
            "name": "Fiditalia Carta-Eureka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/fiditalia_eureka_it.svg"
        },
        {
            "name": "Credit Agricole Friuladria Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "BCC della Calabria Ulteriore",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_catanzarese_it.svg"
        },
        {
            "name": "Banca Nazionale Sammarinese s.p.a.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_nazionale_sammarinese_it.svg"
        },
        {
            "name": "BCC Alberobello, Sammichele e Monopoli",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_alberobello_e_sammichele_oauth_client_it.svg"
        },
        {
            "name": "BCC di Castiglione Messer Raimondo e Pianella",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_castiglione_messer_raimondo_e_pianella_oauth_client_it.svg"
        },
        {
            "name": "BCC Gran Sasso d'Italia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_gran_sasso_ditalia_oauth_client_it.svg"
        },
        {
            "name": "CR Dolomiti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "BCC Circeo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_circeo_oauth_client_it.svg"
        },
        {
            "name": "BCC Marca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_marca_oauth_client_it.svg"
        },
        {
            "name": "BCC Terra di Lavoro S. Vincenzo De' Paoli Scpa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_terra_di_lavoro_s_vincenzo_de_paoli_scpa_oauth_client_it.svg"
        },
        {
            "name": "CR Val di Non",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "The Bank Of Tokyo - Mitsubishi Ufj, Ltd.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/the_bank_of_tokyo_mitsubishi_it.svg"
        },
        {
            "name": "Credito Emiliano (Carta Ego)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_emiliano_it.svg"
        },
        {
            "name": "BCC Leverano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_leverano_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Untereisacktal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "CR Renon",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Banca Alpi Marittime",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_alpi_marittime_it.svg"
        },
        {
            "name": "BCC DELLE MADONIE",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_sgiuseppe_madonie_oauth_client_it.svg"
        },
        {
            "name": "BCC DI VENEZIA, PADOVA E ROVIGO - BANCA ANNIA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_annia_oauth_client_it.svg"
        },
        {
            "name": "CityPoste",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/city_poste_it.svg"
        },
        {
            "name": "Banca Popolare del Frusinate Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpf_it.svg"
        },
        {
            "name": "Banca Sistema",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sistema_it.svg"
        },
        {
            "name": "CheBanca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/chebanca_it.svg"
        },
        {
            "name": "PrimaCassa Credito Cooperativo FVG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/primacassa_credito_cooperativo_fvg_it.svg"
        },
        {
            "name": "Banca di Sconto e Conti Correnti S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bansco_it.svg"
        },
        {
            "name": "BCC Castelli e Iblei",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_castelli_e_iblei_oauth_client_it.svg"
        },
        {
            "name": "Banco Desio e della Brianza Aziende (Web Remote Banking)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_desio_it.svg"
        },
        {
            "name": "Banca Monte di Lucca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_monte_di_lucca_it.svg"
        },
        {
            "name": "Raiffeisenkasse Deutschnofen-Aldein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "TIM",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/ca_charente_maritime_fr.svg"
        },
        {
            "name": "BancaTer Credito Cooperativo FVG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bancater_credito_cooperativo_fvg_it.svg"
        },
        {
            "name": "Banca Sviluppo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sviluppo_spa_it.svg"
        },
        {
            "name": "Fiditalia Carta-Bianca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/fiditalia_bianca_it.svg"
        },
        {
            "name": "Banco di Lucca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_monte_di_lucca_it.svg"
        },
        {
            "name": "Banca Veronese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_veronese_di_concamarise_it.svg"
        },
        {
            "name": "CCN Servizi di Pagamento S.P.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/ccn_sevizi_di_pagamento_it.svg"
        },
        {
            "name": "Deutsche Bank Corporate Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/deutsche_bank_it.svg"
        },
        {
            "name": "Banca di Pisa e Fornacette",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_pisa_e_fornacette_it.svg"
        },
        {
            "name": "BCC Avetrana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_avetrana_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare Etica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_etica_it.svg"
        },
        {
            "name": "Banco Fiorentino-Mugello Impruneta Signa-Cred.Coop",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_fiorentinomugello_impruneta_signacredcoop_it.svg"
        },
        {
            "name": "Banca Profilo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_profilo_it.svg"
        },
        {
            "name": "BCC Longi Valle del Fitalia R.L.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_longi_valle_del_fitalia_rl_oauth_client_it.svg"
        },
        {
            "name": "Banca Leonardo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_leonardo_it.svg"
        },
        {
            "name": "Credit Agricole Cariparma Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "BCC Sarsina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_sarsina_oauth_client_it.svg"
        },
        {
            "name": "Banca di Anghiari e Stia C.C.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_anghiari_e_stia_cc_it.svg"
        },
        {
            "name": "BCC Locorotondo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_locorotondo_oauth_client_it.svg"
        },
        {
            "name": "BCC Alta Murgia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_alta_murgia_oauth_client_it.svg"
        },
        {
            "name": "Banca di Pescia e Cascina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_pescia_e_cascina_it.svg"
        },
        {
            "name": "BCC di Nettuno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_nettuno_oauth_client_it.svg"
        },
        {
            "name": "CR Alta Vallagarina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Banca Carige",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_carige_it.svg"
        },
        {
            "name": "Banca di Credito Peloritano Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcpme_it.svg"
        },
        {
            "name": "Banca 2021 – Cilento, Vallo di Diano e Lucania",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_cilento_e_della_lucania_it.svg"
        },
        {
            "name": "BCC di Ostra e Morro D´Alba",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_ostra_e_morro_dalba_oauth_client_it.svg"
        },
        {
            "name": "Qonto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banca Promos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_promos_it.svg"
        },
        {
            "name": "BCC CRA di Paliano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_cra_di_paliano_oauth_client_it.svg"
        },
        {
            "name": "CRA Boves",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Aigis Banca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/gbm_it.svg"
        },
        {
            "name": "BCC Bergamasca e Orobica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_bergamasca_e_orobica_oauth_client_it.svg"
        },
        {
            "name": "Banca Valsabbina Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_valsabbina_it.svg"
        },
        {
            "name": "Intesa Sanpaolo Persone, Famiglie e Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/intesa_sanpaolo_it.svg"
        },
        {
            "name": "ICONTO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/iconto_it.svg"
        },
        {
            "name": "Banca di Imola Aziende",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_imola_it.svg"
        },
        {
            "name": "BCC di Pratola Peligna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_pratola_peligna_oauth_client_it.svg"
        },
        {
            "name": "BCP di Torre del Greco Imprese (BCP@corporate)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcp_torre_greco_it.svg"
        },
        {
            "name": "Banca di Piacenza",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_piacenza_it.svg"
        },
        {
            "name": "Raiffeisenkasse Schenna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca 5 Consumatori",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca5_consumatori_it.svg"
        },
        {
            "name": "Raiffeisenkasse Schlanders",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "ChiantiBanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/chiantibanca_it.svg"
        },
        {
            "name": "BCC Degli Ulivi Terra di Bari",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_degli_ulivi_terra_di_bari_oauth_client_it.svg"
        },
        {
            "name": "BCC di Gambatesa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_gambatesa_oauth_client_it.svg"
        },
        {
            "name": "Banca del Piceno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_piceno_oauth_client_it.svg"
        },
        {
            "name": "Banco BPM Privati (YouWeb)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpm_it.svg"
        },
        {
            "name": "Istituto per il Credito Sportivo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_sportivo_oauth_client_it.svg"
        },
        {
            "name": "Banca di Bologna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_bologna_it.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Poste Italiane Patrimonio BancoPostaImpresa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_posta_business_link_oauth_client_it.svg"
        },
        {
            "name": "Moneynet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/moneynet_it.svg"
        },
        {
            "name": "Banca Popolare Vesuviana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_vesuviana_it.svg"
        },
        {
            "name": "Blu Banca S.p.a. (Banca Sviluppo Tuscia S.p.a.) Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sviluppo_tuscia_it.svg"
        },
        {
            "name": "BCC Staranzano e Villesse",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_staranzano_e_villesse_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare Pugliese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpp_it.svg"
        },
        {
            "name": "UnipolPay Corporate S.P.A",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Poste Italiane MyPoste Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_posta_business_link_oauth_client_it.svg"
        },
        {
            "name": "MPS - Banca Monte dei Paschi di Siena Small Business / Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/carta_mps_it.svg"
        },
        {
            "name": "BNL - Banca Nazionale del Lavoro Professionisti e Imprese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bnl_it.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Banca Investis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_intermobiliare_it.svg"
        },
        {
            "name": "Banca Albertini Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_albertini_it.svg"
        },
        {
            "name": "BCC ROMAGNOLO - BCC DI CESENA E GATTEO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_cooperativo_romagnolo_oauth_client_it.svg"
        },
        {
            "name": "BCC Canosa Loconia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_canosa_loconia_oauth_client_it.svg"
        },
        {
            "name": "BCC Basilicata - Credito Cooperativo di Laurenzana e Comuni Lucani",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_basilicata_oauth_client_it.svg"
        },
        {
            "name": "Banca Regionale Di Sviluppo Retail",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/brs_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Kastelruth - St. Ulrich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Mediocredito Trentino Alto Adige Spa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/mediocredito_trentino_it.svg"
        },
        {
            "name": "BCC Aquara",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_aquara_oauth_client_it.svg"
        },
        {
            "name": "Banco Marchigiano Credito Cooperativo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_marchigiano_credito_cooperativo_it.svg"
        },
        {
            "name": "BCC di Mozzanica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_mozzanica_oauth_client_it.svg"
        },
        {
            "name": "BCC Monte Pruno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_monte_pruno_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Latsch",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Fideuram S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_fideuram_it.svg"
        },
        {
            "name": "BCC Appulo Lucana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_spinazzola_oauth_client_it.svg"
        },
        {
            "name": "Extrabanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/extrabanca_it.svg"
        },
        {
            "name": "BCP di Torre del Greco Privati (BCP@home)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcp_torre_greco_it.svg"
        },
        {
            "name": "Smartika",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/smartika_it.svg"
        },
        {
            "name": "Banca Popolare Di San Felice Sul Panaro Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sanfelice_it.svg"
        },
        {
            "name": "BCC S. Giovanni Rotondo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_s_giovanni_rotondo_oauth_client_it.svg"
        },
        {
            "name": "BCC Romagna Occidentale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_romagna_occidentale_oauth_client_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Fermo Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/carifermo_it.svg"
        },
        {
            "name": "BCC Don Rizzo - Alcamo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_don_rizzo_alcamo_oauth_client_it.svg"
        },
        {
            "name": "CR Rotaliana e Giovo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Banca Albertini",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_albertini_it.svg"
        },
        {
            "name": "SIA Pay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sia_pay_it.svg"
        },
        {
            "name": "BCCPAY",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Bcc Umbria e Velino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_spello_e_bettona_oauth_client_it.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Banca Centro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_centropadana_it.svg"
        },
        {
            "name": "BPER Card",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bper_corporate_it.svg"
        },
        {
            "name": "BCC Anagni",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_anagni_oauth_client_it.svg"
        },
        {
            "name": "BCC di Ostra Vetere",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_ostra_vetere_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Toblach",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Lottomaticard (Cartalis)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cartalis_it.svg"
        },
        {
            "name": "Borsa del Credito",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/borsa_del_credito_it.svg"
        },
        {
            "name": "Banco Desio CoRe Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BCC Agrigentino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_agrigentino_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Sarntal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Credit Agricole Friuladria Nowbanking Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "EFG Bank Luxembourg Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/efg_luxembourg_it.svg"
        },
        {
            "name": "Nexi (Carte Prepagate)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/nexi_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Volterra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/crvolterra_it.svg"
        },
        {
            "name": "Raiffeisenkasse Bozen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca di Andria",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_andria_it.svg"
        },
        {
            "name": "Banca Popolare Sant'Angelo Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sant_angelo_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Bolzano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_di_risparmio_di_bolzano_it.svg"
        },
        {
            "name": "CRA Borgo S. Giacomo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Banca Mediolanum",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_mediolanum_it.svg"
        },
        {
            "name": "BCC Bene Vagienna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_bene_vagienna_oauth_client_it.svg"
        },
        {
            "name": "BCC Valle del Torto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "CRA di Binasco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Banco BPM Imprese (YouBusinessWeb)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpm_it.svg"
        },
        {
            "name": "Banca Popolare del Frusinate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpf_it.svg"
        },
        {
            "name": "BCC Sibillini",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_sibillini_oauth_client_it.svg"
        },
        {
            "name": "BCC del Garda - BCC Colli Morenici del Garda",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_del_garda_bcc_colli_morenici_del_garda_oauth_client_it.svg"
        },
        {
            "name": "CR Alta Valsugana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "BCC Erchie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_erchie_oauth_client_it.svg"
        },
        {
            "name": "BANCA DI CAGLIARI CREDITO COOPERATIVO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_cagliari_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare Valconca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_valconca_it.svg"
        },
        {
            "name": "Banca Cassa di Risparmio di Savigliano S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bancacrs_it.svg"
        },
        {
            "name": "BCC S. Marzano di S. Giuseppe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Welsberg-Gsies-Taisten",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Popolare delle Province Molisane",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_province_molisane_it.svg"
        },
        {
            "name": "BCC di Milano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_milano_oauth_client_it.svg"
        },
        {
            "name": "BCC Centro Calabria",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_centro_calabria_oauth_client_it.svg"
        },
        {
            "name": "BCC S. Marco dei Cavoti e Sannio Calvi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_s_marco_dei_cavoti_e_sannio_calvi_oauth_client_it.svg"
        },
        {
            "name": "Banco di Sardegna Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_di_sardegna_it.svg"
        },
        {
            "name": "Mediobanca Banca di Credito Finanziario",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/mediobanca_it.svg"
        },
        {
            "name": "Banca Centropadana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_centropadana_it.svg"
        },
        {
            "name": "Volkswagen Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/volkswagen_bank_it.svg"
        },
        {
            "name": "BCC Pianfei e Rocca de Baldi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_pianfei_e_rocca_de_baldi_oauth_client_it.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Banca Versilia Lunigiana e Garfagnana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_versilia_lunigiana_e_garfagnana_it.svg"
        },
        {
            "name": "Banco Desio e della Brianza Privati e Aziende (Dweb)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_desio_it.svg"
        },
        {
            "name": "Cherry Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_delle_tre_venezie_it.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Banca di Imola Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_imola_it.svg"
        },
        {
            "name": "BCC di Carate Brianza",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_carate_brianza_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare dell'Alto Adige (CoBa)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Postepay (Prepagata)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/poste_italiane_it.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        },
        {
            "name": "Raiffeisenkasse Tisens",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "illimity",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/illimity_it.svg"
        },
        {
            "name": "CR Vallagarina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Banca Popolare di Puglia e Basilicata (Corporate)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bppb_it.svg"
        },
        {
            "name": "BNL - Banca Nazionale del Lavoro BNL Individui e Famiglie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bnl_it.svg"
        },
        {
            "name": "Banca Popolare di Fondi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bp_fondi_it.svg"
        },
        {
            "name": "Imprebanca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/imprebanca_it.svg"
        },
        {
            "name": "BCC di Lezzeno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_lezzeno_oauth_client_it.svg"
        },
        {
            "name": "Banque Chaabi du Maroc",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banque_chaabi_du_maroc_it.svg"
        },
        {
            "name": "Credit Agricole Cariparma Piccole Imprese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "BCC di Basciano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_basciano_oauth_client_it.svg"
        },
        {
            "name": "BCC Valdostana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_valdostana_oauth_client_it.svg"
        },
        {
            "name": "Banca Sicana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sicana_it.svg"
        },
        {
            "name": "Friulovest Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/friulovest_banca_it.svg"
        },
        {
            "name": "Credito Lombardo Veneto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_lombardo_veneto_oauth_client_it.svg"
        },
        {
            "name": "Banca Valsabbina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_valsabbina_it.svg"
        },
        {
            "name": "BCC Pergola e Corinaldo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_pergola_e_corinaldo_oauth_client_it.svg"
        },
        {
            "name": "BCC Scafati e Cetara",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_scafati_e_cetara_oauth_client_it.svg"
        },
        {
            "name": "BCC Conversano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_conversano_oauth_client_it.svg"
        },
        {
            "name": "BCC Malatestiana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_malatestiana_oauth_client_it.svg"
        },
        {
            "name": "Intesa Sanpaolo InBiz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/intesa_sanpaolo_it.svg"
        },
        {
            "name": "Raiffeisenkasse Laas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "CR Valsugana e Tesino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Cassa Rurale FVG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_rurale_fvg_it.svg"
        },
        {
            "name": "Banca Popolare Lajatico",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_lajatico_it.svg"
        },
        {
            "name": "Banca del Veneto Centrale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/centroveneto_bassano_banca_it.svg"
        },
        {
            "name": "Banco BPM (Webank)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpm_it.svg"
        },
        {
            "name": "Mediocredito del FVG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/mediocredito_trentino_it.svg"
        },
        {
            "name": "Banca 5 Tabbaconist",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca5_it.svg"
        },
        {
            "name": "Raiffeisenkasse Bruneck",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Capasso Antonio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bancacapasso_it.svg"
        },
        {
            "name": "BVR Banca - Banche Venete Riunite",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_alto_vicentino_oauth_client_it.svg"
        },
        {
            "name": "BCC Ancona e Falconara",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_ancona_e_falconara_oauth_client_it.svg"
        },
        {
            "name": "Solution Bank S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/solutionbank_it.svg"
        },
        {
            "name": "Banco di Sardegna Privati BPERCARD",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_sardegna_bpercard_it.svg"
        },
        {
            "name": "Banca Dell'Elba",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_dellelba_it.svg"
        },
        {
            "name": "Banco di Credito P. Azzoaglio Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/azzoaglio_it.svg"
        },
        {
            "name": "Banca Della Valsassina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BCC Caraglio-Cuneese-Riviera dei Fiori",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "BCC Casalgrasso e S. Albano Stura",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_casalgrasso_e_s_albano_stura_oauth_client_it.svg"
        },
        {
            "name": "BCC del Valdarno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_del_valdarno_oauth_client_it.svg"
        },
        {
            "name": "Banca Privata Leasing",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_privata_leasing_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Cento",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/crcento_it.svg"
        },
        {
            "name": "Banca San Francesco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_san_francesco_it.svg"
        },
        {
            "name": "Credit Agricole Friuladria Corpor@te",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "Banca Akros",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_akros_it.svg"
        },
        {
            "name": "Banca di Credito Peloritano Imprese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcpme_it.svg"
        },
        {
            "name": "Banca Finnat Euramerica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bfe_it.svg"
        },
        {
            "name": "Mooney",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/mooney_it.svg"
        },
        {
            "name": "UniCredit S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/unicredit_it.svg"
        },
        {
            "name": "Banca Popolare Cortona",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_cortona_it.svg"
        },
        {
            "name": "BCC Abruzzese Cappelle sul Tavo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "BCC di Roma",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_roma_oauth_client_it.svg"
        },
        {
            "name": "Banca Passadore",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_passadore_cards_it.svg"
        },
        {
            "name": "Banca Popolare Di San Felice Sul Panaro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sanfelice_it.svg"
        },
        {
            "name": "Poste Italiane S.p.A.- Patrimonio BancoPosta",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_posta_business_link_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Obervinschgau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Progetto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_progetto_it.svg"
        },
        {
            "name": "Banca Popolare del Mediterraneo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_del_mediterraneo_it.svg"
        },
        {
            "name": "Banca Patavina di Sant'Elena e Piove di Sacco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "CR Val di Fiemme",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "QUI!",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/qfs_it.svg"
        },
        {
            "name": "ADMIRAL Pay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banca Popolare dell'Alto Adige (Carta prepagata)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Postepay Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/poste_italiane_it.svg"
        },
        {
            "name": "Iccrea Banca Carta BCC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/iccrea_banca_it.svg"
        },
        {
            "name": "Credem Euromobiliare Private Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_euromobiliare_it.svg"
        },
        {
            "name": "Raiffeisenkasse Prad-Taufers",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Raiffeisenkasse Salurn",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Privata Leasing Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_privata_leasing_it.svg"
        },
        {
            "name": "Banca Nazionale del Lavoro Corporate Carte",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bnl_it.svg"
        },
        {
            "name": "Banca Popolare Sant'Angelo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sant_angelo_it.svg"
        },
        {
            "name": "BCC Dell'Adriatico Teramano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_delladriatico_teramano_oauth_client_it.svg"
        },
        {
            "name": "BCC di Napoli",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_napoli_oauth_client_it.svg"
        },
        {
            "name": "Banca di Pesaro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_pesaro_it.svg"
        },
        {
            "name": "Compass Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/compass_it.svg"
        },
        {
            "name": "Cassa di Sovvenzioni e Risparmio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/csrpbi_it.svg"
        },
        {
            "name": "Cassa di risparmio di Orvieto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cariorvieto_it.svg"
        },
        {
            "name": "Raiffeisen Landesbank Südtirol AG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisen_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Fossano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/crfossano_it.svg"
        },
        {
            "name": "Raiffeisenkasse Tirol",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Don Stella di Resuttano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_don_stella_di_resuttano_oauth_client_it.svg"
        },
        {
            "name": "Banca Galileo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_galileo_it.svg"
        },
        {
            "name": "Banca Cremasca e Mantovana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_cremasca_e_mantovana_it.svg"
        },
        {
            "name": "Banca di Udine",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_udine_it.svg"
        },
        {
            "name": "BCC Lazio Nord",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_lazio_nord_oauth_client_it.svg"
        },
        {
            "name": "BCC G. Toniolo e San Michele di San Cataldo (Caltanissetta) - Societa Cooperativa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_g_toniolo_di_san_cataldo_oauth_client_it.svg"
        },
        {
            "name": "BCC del Metauro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_del_metauro_oauth_client_it.svg"
        },
        {
            "name": "BCC Flumeri",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_flumeri_oauth_client_it.svg"
        },
        {
            "name": "BCC di Capaccio Paestum e Serino S.C.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_capaccio_paestum_e_serino_sc_oauth_client_it.svg"
        },
        {
            "name": "BCC di Filottrano - Credi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_filottrano_credi_oauth_client_it.svg"
        },
        {
            "name": "CR Val di Sole",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "BCC del Basso Sebino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_del_basso_sebino_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Schlern-Rosengarten",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Raiffeisenkasse Villnöß",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC di Triuggio e Della Valle del Lambro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_triuggio_e_della_valle_del_lambro_oauth_client_it.svg"
        },
        {
            "name": "Bcc Abruzzi e Molise",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_sangro_teatina_oauth_client_it.svg"
        },
        {
            "name": "BCC di Arborea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_arborea_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Meran",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Centro Emilia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_centro_emilia_oauth_client_it.svg"
        },
        {
            "name": "BCC Pordenonese e Monsile",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_pordenonese_e_monsile_oauth_client_it.svg"
        },
        {
            "name": "CentroMarca Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/centromarca_banca_it.svg"
        },
        {
            "name": "Igea Digital Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Raiffeisenkasse Untervinschgau",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Fineco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/fineco_it.svg"
        },
        {
            "name": "Banca Finnat Euramerica Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bfe_it.svg"
        },
        {
            "name": "Banca Arner SpA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_arner_it.svg"
        },
        {
            "name": "CRA Dell'Agro Pontino - BCC Societa' Cooperativa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Tinaba con Banca Profilo Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/tinaba_it.svg"
        },
        {
            "name": "Credem - Credito Emiliano Imprese e Professionisti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_emiliano_it.svg"
        },
        {
            "name": "Banca Popolare di Sondrio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_populare_di_sondrio_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Fermo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/carifermo_it.svg"
        },
        {
            "name": "Raiffeisenkasse Eisacktal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Santa Giulia S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_santa_giulia_it.svg"
        },
        {
            "name": "BCC Castelli Romani",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_castelli_romani_oauth_client_it.svg"
        },
        {
            "name": "Credit Agricole Friuladria Piccole Imprese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "BPER Banca Privati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_sassari_bpercard_it.svg"
        },
        {
            "name": "Raiffeisenkasse Niederdorf",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Felsinea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_felsinea_oauth_client_it.svg"
        },
        {
            "name": "Hype",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/hype_it.svg"
        },
        {
            "name": "BCC di Altofonte e Caccamo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_altofonte_e_caccamo_oauth_client_it.svg"
        },
        {
            "name": "Blu Banca S.p.a. (Banca Sviluppo Tuscia S.p.a.)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sviluppo_tuscia_it.svg"
        },
        {
            "name": "Banca Ifis",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_ifis_it.svg"
        },
        {
            "name": "Banca Prealpi Sanbiagio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_prealpi_oauth_client_it.svg"
        },
        {
            "name": "Banca Consulia SpA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_ipibi_it.svg"
        },
        {
            "name": "Banca Popolare Pugliese Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bpp_it.svg"
        },
        {
            "name": "MPS - Banca Monte dei Paschi di Siena Persone",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/carta_mps_it.svg"
        },
        {
            "name": "Banca Investis Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_intermobiliare_it.svg"
        },
        {
            "name": "Raiffeisenkasse Unterland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Emil Banca - Credito Cooperativo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_emilbanca_oauth_client_it.svg"
        },
        {
            "name": "Credit Agricole Cariparma Nowbanking Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "CR Ledro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Credem - Credito Emiliano Privati e Famiglie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_emiliano_it.svg"
        },
        {
            "name": "HelloBank!",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bnl_business_it.svg"
        },
        {
            "name": "IBL Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_castelli_e_iblei_oauth_client_it.svg"
        },
        {
            "name": "Banca del Sud",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_sud_it.svg"
        },
        {
            "name": "Banca del Mezzogiorno - MedioCredito Centrale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_mezzogiorno_it.svg"
        },
        {
            "name": "BCC Vicentino Pojana Maggiore",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_vicentino_pojana_maggiore_oauth_client_it.svg"
        },
        {
            "name": "BCC Recanati e Colmurano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_recanati_e_colmurano_oauth_client_it.svg"
        },
        {
            "name": "Zurich Bank Italia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BCC Terre Venete",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare di Fondi Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bp_fondi_it.svg"
        },
        {
            "name": "BCC di Caravaggio Adda e Cremasco CR",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_caravaggio_adda_e_cremasco_cr_oauth_client_it.svg"
        },
        {
            "name": "BCC Valdarno Fiorentino Banca di Cascia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_valdarno_fiorentino_banca_di_cascia_oauth_client_it.svg"
        },
        {
            "name": "CRA di Cantu' BCC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Credifarma Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/farbanca_it.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "BCC di Gaudiano di Lavello",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_gaudiano_di_lavello_oauth_client_it.svg"
        },
        {
            "name": "BCC Laudense-Lodi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_laudenselodi_oauth_client_it.svg"
        },
        {
            "name": "BCC San Giuseppe di Mussomeli",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_san_giuseppe_di_mussomeli_oauth_client_it.svg"
        },
        {
            "name": "BCC Cherasco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_cherasco_oauth_client_it.svg"
        },
        {
            "name": "Riviera Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/riviera_banca_it.svg"
        },
        {
            "name": "Raiffeisenkasse Marling",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Sella",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/sella_it.svg"
        },
        {
            "name": "RomagnaBanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/romagnabanca_it.svg"
        },
        {
            "name": "Credito Padano BCC",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_padano_bcc_oauth_client_it.svg"
        },
        {
            "name": "Banca Popolare di Bari",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_di_bari_it.svg"
        },
        {
            "name": "Banca Farmafactoring Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bff_it.svg"
        },
        {
            "name": "Banca Popolare dell'Alto Adige",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "BCC Mutuo Soccorso di Gangi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_mutuo_soccorso_di_gangi_oauth_client_it.svg"
        },
        {
            "name": "Banca di Bari e Taranto Credito Cooperativo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_bari_oauth_client_it.svg"
        },
        {
            "name": "Findomestic Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/findomestic_banca_spa_it.svg"
        },
        {
            "name": "Deutsche Bank Privati e Small Business (La Mia Banca)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/deutsche_bank_it.svg"
        },
        {
            "name": "BCC Velino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_velino_oauth_client_it.svg"
        },
        {
            "name": "Banca del Fucino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_fucino_it.svg"
        },
        {
            "name": "Buddybank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/buddybank_it.svg"
        },
        {
            "name": "Banca di Sassari",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_sassari_bpercard_it.svg"
        },
        {
            "name": "Credit Agricole Cariparma BankLink.Net2 Aziende",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/agricole_it.svg"
        },
        {
            "name": "Allianz Bank Financial Advisors",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/allianz_bg.svg"
        },
        {
            "name": "ViViBanca SpA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/vivibanca_it.svg"
        },
        {
            "name": "Banco di Lucca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_monte_di_lucca_it.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BCC Della Valle del Trigno",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_della_valle_del_trigno_oauth_client_it.svg"
        },
        {
            "name": "CR Alto Garda",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "BCC Brescia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_brescia_oauth_client_it.svg"
        },
        {
            "name": "BCC Credito Etneo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_credito_etneo_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Wipptal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Patrimoni Sella & C.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_patrimoni_it.svg"
        },
        {
            "name": "Compass Banca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/compass_it.svg"
        },
        {
            "name": "BCC Ravennate, Forlivese e Imolese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_ravennate_oauth_client_it.svg"
        },
        {
            "name": "Extrabanca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/extrabanca_it.svg"
        },
        {
            "name": "BCC Di Alba, Langhe, Roero E Del Canavese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_abruzzese_cappelle_sul_tavo_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Überetsch",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Macerata Spa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bancamacerata_it.svg"
        },
        {
            "name": "CR San Martino in Passiria",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Raiffeisenkasse Passeier",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Ubae",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_ubae_it.svg"
        },
        {
            "name": "BCC di Bellegra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_bellegra_oauth_client_it.svg"
        },
        {
            "name": "Paytipper",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/paytipper_it.svg"
        },
        {
            "name": "Banca Agricola Popolare di Ragusa Privati e Famiglie",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bapr_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Asti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_di_asti_it.svg"
        },
        {
            "name": "CRA Cortina d'Ampezzo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "CiviBank - Banca Popolare di Cividale Imprese e Professionisti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/civibank_it.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "BCC Castagneto Carducci",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_castagneto_carducci_oauth_client_it.svg"
        },
        {
            "name": "Banca Generali",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/generali_bank_oauth_client_at.svg"
        },
        {
            "name": "Raiffeisenkasse Lana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banca Farmafactoring",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bff_it.svg"
        },
        {
            "name": "Banca Popolare di Cividale",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/civibank_it.svg"
        },
        {
            "name": "BPER Banca Imprese e Professionisti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_di_sassari_bpercard_it.svg"
        },
        {
            "name": "Banca Stabiese",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_stabiese_it.svg"
        },
        {
            "name": "BCC Ripatransone e Fermano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_ripatransone_e_fermano_oauth_client_it.svg"
        },
        {
            "name": "BCC Barlassina",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_barlassina_oauth_client_it.svg"
        },
        {
            "name": "BCC Campania Centro CRA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_campania_centro_cra_oauth_client_it.svg"
        },
        {
            "name": "Societa' Servizi di Pagamento - Societa' Per Azioni",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/societa_servizi_di_pagamento_societa_per_azioni_it.svg"
        },
        {
            "name": "Banca Popolare Cassinate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_popolare_cassinate_it.svg"
        },
        {
            "name": "Banca Popolare Valconca Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_valconca_it.svg"
        },
        {
            "name": "BANCA TEMA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_terre_etrusche_e_di_maremma_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Ravenna Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/lacassa_ravenna_it.svg"
        },
        {
            "name": "Cassa Padana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_padana_it.svg"
        },
        {
            "name": "Banca Popolare del Lazio Imprese e Professionisti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bp_lazio_it.svg"
        },
        {
            "name": "Banca delle terre venete",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Cassa Lombarda",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_lombarda_it.svg"
        },
        {
            "name": "CR BCC di Treviglio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Banca Passadore (Foreign Accounts)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_passadore_foreign_it.svg"
        },
        {
            "name": "Banca Profilo Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_profilo_it.svg"
        },
        {
            "name": "Allianz Bank Financial Advisors Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/allianz_bg.svg"
        },
        {
            "name": "BCC di Pachino",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_pachino_oauth_client_it.svg"
        },
        {
            "name": "EFG Bank Luxembourg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/efg_luxembourg_it.svg"
        },
        {
            "name": "BCC La Riscossa di Regalbuto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_la_riscossa_di_regalbuto_oauth_client_it.svg"
        },
        {
            "name": "CRA di Rivarolo Mantovano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cra_it.svg"
        },
        {
            "name": "Banca Widiba",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/widiba_it.svg"
        },
        {
            "name": "Guber Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/guber_banca_it.svg"
        },
        {
            "name": "Cassa di Risparmio di Ravenna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/lacassa_ravenna_it.svg"
        },
        {
            "name": "Banca Mediolanum Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_mediolanum_it.svg"
        },
        {
            "name": "Banca Popolare di Puglia e Basilicata (Personal)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bppb_it.svg"
        },
        {
            "name": "Banca Ifigest SpA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_ifigest_it.svg"
        },
        {
            "name": "BCC San Michele di Caltanissetta e Pietraperzia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_san_michele_di_caltanissetta_e_pietraperzia_oauth_client_it.svg"
        },
        {
            "name": "Nexi (Carte Yap)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/nexi_yap_it.svg"
        },
        {
            "name": "BCC di Fano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_fano_oauth_client_it.svg"
        },
        {
            "name": "Banca Agricola Popolare di Ragusa Imprese ed Enti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bapr_it.svg"
        },
        {
            "name": "Raiffeisenkasse Vintl",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Credito Cooperativo Friuli",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_credito_cooperativo_friuli_oauth_client_it.svg"
        },
        {
            "name": "BCC di Pontassieve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_pontassieve_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Val Badia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Raiffeisenkasse Ulten-St. Pankraz-Laurein",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Raiffeisenkasse Partschins",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Brianza e Laghi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_brianza_e_laghi_oauth_client_it.svg"
        },
        {
            "name": "CR Novella e Alta Anaunia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "BANCA DI CREDITO COOPERATIVO DI PUTIGNANO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_putignano_scpa_oauth_client_it.svg"
        },
        {
            "name": "Banca Aletti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_aletti_it.svg"
        },
        {
            "name": "Banca Adria Colli Euganei",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_adria_colli_euganei_it.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Banco di Credito P. Azzoaglio",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/azzoaglio_it.svg"
        },
        {
            "name": "Cassa di Trento, Lavis, Mezzocorona e Valle Cembra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Raiffeisenkasse Algund",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC Provincia Romana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_provincia_romana_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Tauferer-Ahrntal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "BCC di Busto Garolfo e Buguggiate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_busto_garolfo_e_buguggiate_oauth_client_it.svg"
        },
        {
            "name": "Banco di Sardegna Imprese e Professionisti",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_di_sardegna_it.svg"
        },
        {
            "name": "Banca del Piemonte",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_piemonte_it.svg"
        },
        {
            "name": "Banca Alta Toscana Credito Cooperativo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_alta_toscana_it.svg"
        },
        {
            "name": "CheBanca!",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/chebanca_it.svg"
        },
        {
            "name": "BCC Santeramo in Colle",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_santeramo_in_colle_oauth_client_it.svg"
        },
        {
            "name": "Tinaba con Banca Profilo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/tinaba_it.svg"
        },
        {
            "name": "BCC Valpolicella Benaco Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_valpolicella_benaco_banca_oauth_client_it.svg"
        },
        {
            "name": "BCC di Buccino e Dei Comuni Cilentani",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_buccino_e_dei_comuni_cilentani_oauth_client_it.svg"
        },
        {
            "name": "BCC di Montepaone",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_montepaone_oauth_client_it.svg"
        },
        {
            "name": "Hypo Tirol Bank Ag Succursale Italia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/hypotirol_ag_succursale_italia_it.svg"
        },
        {
            "name": "BCC di Terra D'Otranto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_terra_dotranto_oauth_client_it.svg"
        },
        {
            "name": "Intesa Sanpaolo Private Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/intesa_sanpaolo_it.svg"
        },
        {
            "name": "Postepay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/poste_italiane_it.svg"
        },
        {
            "name": "Prader Bank S.p.A.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/praderbank_it.svg"
        },
        {
            "name": "BCC Agrobresciano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_agrobresciano_oauth_client_it.svg"
        },
        {
            "name": "Banca Sistema Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_sistema_it.svg"
        },
        {
            "name": "Cassa Centrale Banca Credito Coop. Italiano",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cassa_centrale_banca_it.svg"
        },
        {
            "name": "BCC Mediocrati",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_mediocrati_oauth_client_it.svg"
        },
        {
            "name": "CR Adamello Giudicarie Valsabbia Paganella",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/cr_bologna_it.svg"
        },
        {
            "name": "Credito Valtellinese Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/credito_cooperativo_romagnolo_oauth_client_it.svg"
        },
        {
            "name": "Raiffeisenkasse Etschtal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Raiffeisenkasse Gröden",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/raiffeisenkasse_it.svg"
        },
        {
            "name": "Banco Marchigiano Credito Cooperativo SimplyBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banco_marchigiano_credito_cooperativo_it.svg"
        },
        {
            "name": "BCC di Ostuni",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/bcc_di_ostuni_oauth_client_it.svg"
        },
        {
            "name": "Alto Adige Banca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/alto_adige_banca_it.svg"
        }
    ],
    "ES": [
        {
            "name": "Caja Rural San Jose De Almassora",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Utrera",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Deutsche Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/deutsche_bank_de.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Triodos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/triodos_es.svg"
        },
        {
            "name": "Caja Rural Jaen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Cajasur Banco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cajasur_es.svg"
        },
        {
            "name": "Sabadell",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/sabadell_es.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Laboral Kutxa",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/laboral_kutxa_es.svg"
        },
        {
            "name": "Banca Pueyo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/banca_pueyo_es.svg"
        },
        {
            "name": "Caja Rural Nueva Carteya",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Asturias",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Casas Ibañez",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural San Isidro Les Coves Vinroma",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural L'alcudia",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Teruel",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caixa de Enginyers",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/caixa_enginyers_xls_es.svg"
        },
        {
            "name": "Caja Rural Gijon",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Granada",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Arquia (Caja de Arquitectos)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/arquia_es.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banco Cooperativo Español (Caja Rural)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bce_es.svg"
        },
        {
            "name": "Caja Rural Onda",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Albal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural El Salvador Vinaroz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Self Trade Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/selfbank_es.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "EVO Banco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/evobanco_es.svg"
        },
        {
            "name": "Deutsche Bank Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/deutsche_bank_de.svg"
        },
        {
            "name": "Banco Pichincha",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ec/banco_pichincha_ec.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/ing_direct_business_es.svg"
        },
        {
            "name": "Caja Rural de Aragon (Bantierra)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Salamanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural San Jose Alcora",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Fuentealamo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        },
        {
            "name": "Caja Rural Baena",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Cajamar",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cajamar_es.svg"
        },
        {
            "name": "Fiare Banca Etica",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/fiare_es.svg"
        },
        {
            "name": "TargoBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/targobank_es.svg"
        },
        {
            "name": "Caixa Rural Benicarlo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Soria",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Qonto",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/ing_direct_business_es.svg"
        },
        {
            "name": "Banco Europeo De Finanzas (BEF)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/banco_europe_de_finanzas_es.svg"
        },
        {
            "name": "Caja Rural Cajasiete",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caixa Rural Ruralnostra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural Adamuz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caixa Popular",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Cajaviva",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Bank Norwegian",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bank_norwegian_de.svg"
        },
        {
            "name": "Caja Rural Navarra",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Eurocaja Rural",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/eurocaja_rural_es.svg"
        },
        {
            "name": "Caja Rural Cañete De Las Torres",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Caja Rural Extremadura",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural San Isidro Vall D'uxo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Banco Mediolanum",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_mediolanum_it.svg"
        },
        {
            "name": "Santander Corporate & Investment Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/santander_es.svg"
        },
        {
            "name": "CaixaBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/caixabank_es.svg"
        },
        {
            "name": "Colonya",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/colonya_es.svg"
        },
        {
            "name": "Banca March",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bancamarch_es.svg"
        },
        {
            "name": "WiZink",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/wizink_es.svg"
        },
        {
            "name": "Caja Rural Algemesi",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Caja Rural del Sur",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "imaginBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/imagin_bank_es.svg"
        },
        {
            "name": "Caja Rural Zamora",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Kutxabank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/kutxabank_es.svg"
        },
        {
            "name": "Bankoa (Credit Agricole)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bankoa_es.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "UBS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "Renta 4 Banco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/renta4_es.svg"
        },
        {
            "name": "Globalcaja",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Abanca",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/abanca_es.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "BBVA",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "Caixa Rural Galega",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Aresbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Bankinter",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bankinter_es.svg"
        },
        {
            "name": "Caja Rural Orihuela",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Ibercaja Banco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/ibercaja_es.svg"
        },
        {
            "name": "OpenBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/openbank_es.svg"
        },
        {
            "name": "Caja Rural Villamalea",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/cr_es.svg"
        },
        {
            "name": "Santander",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/santander_es.svg"
        },
        {
            "name": "Unicaja Banco",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/unicaja_es.svg"
        },
        {
            "name": "Banco Caminos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/banco_caminos_es.svg"
        }
    ],
    "XF": [
        {
            "name": "Fake Business Demo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with Delayed Payment Authorization",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Rabobank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/rabobank_oauth_client_nl_xf.svg"
        },
        {
            "name": "Fake Bank with optional Fields",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fineco (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/fineco_oauth_client_it_xf.svg"
        },
        {
            "name": "Fake Bank with Error",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Handelsbanken (Sandbox SE)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "ING (Sandbox NL)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Priora Demobank with SMS-PIN (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banca Comerciala Romana (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/bcr_client_oauth_ro_xf.svg"
        },
        {
            "name": "Fake Interactive Bank with Client Keys (CAPTCHA)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banco Santander S.A (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pt/santander_pt.svg"
        },
        {
            "name": "Fake Bank API with Fields",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank With Natures",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Swedbank EE (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Coutts (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/coutts_clearspend_gb.svg"
        },
        {
            "name": "Fakebank with dynamic select",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fakebank with file CSV",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BBVA (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/es/bbva_es.svg"
        },
        {
            "name": "Fake Bank with Image",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Demo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Air Bank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/airbank_cz.svg"
        },
        {
            "name": "Volksbank-Raiffaisenbank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fakebank Inactive",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with PSU-ID",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with eIDAS Certificates",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banca Transilvania (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/bt_trans_ro.svg"
        },
        {
            "name": "Fake Bank with Error Web Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank OAuth with Pending Transactions and Available Balance",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Natwest (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/natwest_oauth_client_gb_xf.svg"
        },
        {
            "name": "Fake Bank Simple",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Bank of Valletta (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/bov_oauth_client_mt.svg"
        },
        {
            "name": "Fake Bank OAuth",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with Client Keys",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "UniCredit Bank (Sandbox HU)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/unicredit_hu.svg"
        },
        {
            "name": "Fake Bank OAuth with N Transactions",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Raiffeisen (Sandbox HU)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/raiffeisen_hu.svg"
        },
        {
            "name": "Piraeus Bank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/piraeus_bank_oauth_client_gr_xf.svg"
        },
        {
            "name": "Commerzbank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/commerzbank_de.svg"
        },
        {
            "name": "Fakebank with rememberable credentials",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake OAuth Interactive Redirect with Client Keys",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with SMS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with Updates",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Nordea (Sandbox SE)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/nordea_se.svg"
        },
        {
            "name": "Fake Bank Simple With Possible Duplicates",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BNP Paribas France (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/bnpparibas_stet_client_fr_xf.svg"
        },
        {
            "name": "Erste Bank and Sparkassen (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/erste_bank_sparkasse_at.svg"
        },
        {
            "name": "Societe Generale Luxembourg Corporate (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/fr/la_societe_generale_fr.svg"
        },
        {
            "name": "Fake Bank with Token",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake OAuth Bank with Client Keys",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank With Partial Data",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with 2 step Interactive",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Banca del Piemonte (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/banca_del_piemonte_it.svg"
        },
        {
            "name": "Fake Bank OAuth with Pending Transactions",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank With Crypto Assets",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Knab (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/knab_nl.svg"
        },
        {
            "name": "Curve Sandbox",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Citadele Bank (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/citadele_ee.svg"
        },
        {
            "name": "Fake Interactive Bank with Client Keys (decoupled)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Priora Demobank with Strong Customer Authentication (Sandbox)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with optional SMS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Fake Bank with Select",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        }
    ],
    "AT": [
        {
            "name": "Bank 99",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bank_99_oauth_client_at.svg"
        },
        {
            "name": "Hypo Noe",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/hypo_noe_oauth_client_at.svg"
        },
        {
            "name": "BTV",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/btv_at.svg"
        },
        {
            "name": "Volksbank Tirol",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Volksbank Wien",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Addiko Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/addiko_at.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Western Union",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Bank Austria (Unicredit eBanking Global)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Volksbank Oberosterreich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Hypo Vorarlberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/hypo_vorarlberg_oauth_client_at.svg"
        },
        {
            "name": "Bank Austria (Unicredit BusinessNet)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Volksbank Salzburg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Bank Austria (Unicredit)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/unicredit_at.svg"
        },
        {
            "name": "Sodexo",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/sodexo_at.svg"
        },
        {
            "name": "Volksbank Niederosterreich",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Erste Bank and Sparkassen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/erste_bank_sparkasse_at.svg"
        },
        {
            "name": "Generali Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/generali_bank_oauth_client_at.svg"
        },
        {
            "name": "Bank99 (ex-ING)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bank99_at.svg"
        },
        {
            "name": "Volksbank Vorarlberg",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Easybank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/easybank_at.svg"
        },
        {
            "name": "Marchfelder Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/marchfelder_bank_oauth_client_at.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/raiffeisen_at.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Dolomiten Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/dolomiten_bank_oauth_client_at.svg"
        },
        {
            "name": "Hypo Tirol",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/hypo_tirol_oauth_client_at.svg"
        },
        {
            "name": "Schoellerbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/schoellerbank_at.svg"
        },
        {
            "name": "VR-Bank Rottal-Inn eG",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/vr_at.svg"
        },
        {
            "name": "BKS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hr/bks_banka_hr.svg"
        },
        {
            "name": "Bankhaus Schelhammer & Schattera Aktiengesellschaft",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bankhaus_schelhammer_schattera_aktiengesellschaft_oauth_client_at.svg"
        },
        {
            "name": "Volksbank Steiermark",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Bank Winter",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bank_winter_oauth_client_at.svg"
        },
        {
            "name": "Bankhaus Spangler",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bankhaus_spangler_oauth_client_at.svg"
        },
        {
            "name": "Osterreichische Arzte- und Apothekerbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/osterreichische_arzte_und_apothekerbank_oauth_client_at.svg"
        },
        {
            "name": "Oberbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/oberbank_at.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Volksbank Kaernten",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/volksbank_at.svg"
        },
        {
            "name": "Bank Burgenland",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bank_burgenland_oauth_client_at.svg"
        },
        {
            "name": "SPARDA Bank (BAWAG P.S.K.)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/sparda_at.svg"
        },
        {
            "name": "Consors Finanz",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/consors_finanz_de.svg"
        },
        {
            "name": "Austrian Anadi Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/anadi_bank_oauth_client_at.svg"
        },
        {
            "name": "BAWAG P.S.K.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/bawag_at.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "CY": [
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Bank of Cyprus",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Hellenic Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cy/hellenic_bank_cy.svg"
        },
        {
            "name": "Oro Pay",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cy/oro_pay_cy.svg"
        },
        {
            "name": "Eurobank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/eurobank_client_cy_xf.svg"
        },
        {
            "name": "Jordan Ahli",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cy/jordan_ahli_cy.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "CZ": [
        {
            "name": "Fio Banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/fio_cz.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "Komerční banka",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/kb_cz.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/raiffeisen_cz.svg"
        },
        {
            "name": "UniCredit Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/unicredit_cz.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Creditas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Max banka as",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/expobank_cz.svg"
        },
        {
            "name": "Airbank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/airbank_cz.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "MONETA Money Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/moneta_cz.svg"
        },
        {
            "name": "Česká Spořitelna",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/csas_oauth_client_cz_xf.svg"
        },
        {
            "name": "mBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/mbank_cz.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "CSOB",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/cz/csob_oauth_client_cz.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "XO": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_xo.svg"
        },
        {
            "name": "Society for Worldwide Interbank Financial Telecommunication SWIFT (MT940)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Revolut CSV",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "Quicken Interchange Format QIF",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Business Demo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/business_demobank_xo.svg"
        },
        {
            "name": "Coinbase",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/coinbase_xo.svg"
        },
        {
            "name": "WebMoney",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/webmoney_xo.svg"
        },
        {
            "name": "UBS (EBICS)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ch/ubs_ch.svg"
        },
        {
            "name": "Demo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/demobank_xo.svg"
        },
        {
            "name": "QIWI",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/qiwi_xo.svg"
        },
        {
            "name": "Payoneer",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/payoneer_xo.svg"
        },
        {
            "name": "QuickBooks online bank statement QBO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "ЮMoney (Яндекс.Деньги)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/yandex_money_xo.svg"
        },
        {
            "name": "Open Financial Exchange OFX",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/ofx_xo.svg"
        },
        {
            "name": "Fentury CSV",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "HSBCnet Web Banking",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbc_xo.svg"
        },
        {
            "name": "Coinbase Pro",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/gdax_xo.svg"
        },
        {
            "name": "Binance",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/binance_xo.svg"
        },
        {
            "name": "EBICS",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/aliexpress_xo.svg"
        },
        {
            "name": "BUXFER",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/buxfer_xo.svg"
        },
        {
            "name": "Quicken Financial Exchange QFX",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        }
    ],
    "PL": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/ing_csv_pl.svg"
        },
        {
            "name": "PKO Bank Polski Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/pkobp_pl.svg"
        },
        {
            "name": "PKO Bank Polski",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/pkobp_pl.svg"
        },
        {
            "name": "Alior Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/alior_bank_pl.svg"
        },
        {
            "name": "Danske Bank Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/danske_bank_gb.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Bank Pekao",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/bank_pekao_oauth_client_pl.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "BNP Paribas Business",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bnp_paribas_de.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "mBank Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/mbank_pl.svg"
        },
        {
            "name": "mBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/pl/mbank_pl.svg"
        },
        {
            "name": "Citi Handlowy",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/br/citi_connect_br.svg"
        },
        {
            "name": "Millenium Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "BNP Paribas",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/bnp_paribas_de.svg"
        },
        {
            "name": "Ikano Bank AB (publ)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/bg/ikano_finland_bg.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "LI": [
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "RO": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/ing_ro.svg"
        },
        {
            "name": "Raiffeisen",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/raiffeisen_ro.svg"
        },
        {
            "name": "Alpha Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gr/alpha_bank_gr.svg"
        },
        {
            "name": "UniCredit Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/unicredit_ro.svg"
        },
        {
            "name": "BRD Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/mybrd_brd_ro.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "CEC Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/cec_ro.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "Orange Money",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/orange_money_ro.svg"
        },
        {
            "name": "Intesa Sanpaolo Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/it/intesa_sanpaolo_it.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Garantibank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/garantibank_nl.svg"
        },
        {
            "name": "BRD",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/mybrd_brd_ro.svg"
        },
        {
            "name": "Banca Transilvania",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/bt_trans_ro.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/ing_ro.svg"
        },
        {
            "name": "Raiffeisen Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/raiffeisen_ro.svg"
        },
        {
            "name": "Banca Comerciala Romana",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/bcr_ro.svg"
        },
        {
            "name": "Porsche Bank A.G.",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ro/porschebank_ro.svg"
        },
        {
            "name": "OTP Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/hu/otpbank_hu.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ],
    "NL": [
        {
            "name": "PayPal",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg"
        },
        {
            "name": "ING",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "Knab",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/knab_nl.svg"
        },
        {
            "name": "Wise (former Transferwise)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg"
        },
        {
            "name": "SNS Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/snsbank_nl.svg"
        },
        {
            "name": "Curve",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xf/placeholder_global.svg"
        },
        {
            "name": "Crypto.com",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/mt/crypto_com_oauth_client_mt.svg"
        },
        {
            "name": "Triodos",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/triodos_nl.svg"
        },
        {
            "name": "Handelsbanken",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "ASN Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/asnbank_nl.svg"
        },
        {
            "name": "European Depositary Bank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/ee/swedbank_ee.svg"
        },
        {
            "name": "N26",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/de/n26_de.svg"
        },
        {
            "name": "Connexis Cash (BNP Paribas)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/bnpparibas_fortis_be.svg"
        },
        {
            "name": "Revolut",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg"
        },
        {
            "name": "Garantibank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/garantibank_nl.svg"
        },
        {
            "name": "HSBCnet",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/hsbcnet_xo.svg"
        },
        {
            "name": "Argenta",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/be/argenta_bank_be.svg"
        },
        {
            "name": "bunq",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/bunq_nl.svg"
        },
        {
            "name": "Rabobank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/rabobank_nl.svg"
        },
        {
            "name": "RegioBank",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/regiobank_nl.svg"
        },
        {
            "name": "ING Wholesale Banking (InsideBusiness)",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/at/ing_diba_at.svg"
        },
        {
            "name": "ABN AMRO",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/abn_amro_nl.svg"
        },
        {
            "name": "Handelsbanken Corporate",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/se/handelsbanken_se.svg"
        },
        {
            "name": "Wamo.io",
            "logoUrl": "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/nl/wamo_oauth_client_nl.svg"
        }
    ]
}


import { Grid } from "@mui/material";
import axios from "axios";
import { Button, ContentWrapper } from "components";
import { SENDGRID_ADD_EMAIL_ENDPOINT } from "const";
import { ChangeEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./StayUpToDateSection.module.scss";

interface StayUpToDateSectionProps {
  withoutAnimation?: boolean;
}

export const StayUpToDateSection = ({
  withoutAnimation,
}: StayUpToDateSectionProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [subscribeClicked, setSubscribeClicked] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [callingSendGrid, setCallingSendGrid] = useState(false);

  const handleSubscribe = () => {
    setSubscribeClicked(true);

    if (isValidEmail) {
      setCallingSendGrid(true);
      axios
        .put(
          SENDGRID_ADD_EMAIL_ENDPOINT,
          {
            contacts: [{ email }],
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
            },
          },
        )
        .then(() => {
          setSubscribeSuccess(true);
          setCallingSendGrid(false);
        })
        .catch((error) => {
          setCallingSendGrid(false);
          console.log(error);
        });
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const isValid = !!value?.match(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    setEmail(value);
    setIsValidEmail(isValid);
  };

  return (
    <ContentWrapper
      className={styles.ContentWrapper}
      withoutAnimation={withoutAnimation}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className={styles.Title}>{t("stayUpToDateSection.title")}</div>
        </Grid>

        <Grid item md={2} xs={1} />
        <Grid item md={8} xs={10}>
          {!subscribeSuccess ? (
            <div className={styles.SubTitle}>
              <Trans i18nKey="stayUpToDateSection.subtitle" />
            </div>
          ) : (
            <div className={styles.SubTitle}>
              <Trans i18nKey="stayUpToDateSection.subscribeSuccess" />
            </div>
          )}
        </Grid>
        <Grid item md={2} xs={1} />

        <Grid item md={3} xs={1} />
        <Grid item md={6} xs={10}>
          {!subscribeSuccess && (
            <div className={styles.Form}>
              <div className={styles.Row}>
                <input
                  type="text"
                  placeholder={t("stayUpToDateSection.placeholder")}
                  className={styles.Input}
                  value={email}
                  disabled={callingSendGrid}
                  onChange={handleOnChange}
                />
                <Button
                  className={styles.Button}
                  onClick={handleSubscribe}
                  loading={callingSendGrid}>
                  {t("stayUpToDateSection.subscribe")}
                </Button>
              </div>

              {subscribeClicked && !isValidEmail && email ? (
                <div className={styles.Error}>
                  {t("stayUpToDateSection.errorEmail")}
                </div>
              ) : (
                <div className={styles.Error} />
              )}
            </div>
          )}
        </Grid>
        <Grid item md={3} xs={1} />
      </Grid>
    </ContentWrapper>
  );
};

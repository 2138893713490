import classnames from "classnames";
import { ContentWrapper } from "components";
import { useTranslation } from "react-i18next";
import styles from "./SupportHeader.module.scss";

export const SupportHeader = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper className={styles.SectionWrapper} withoutAnimation>
      <div className={classnames(styles.Circle, styles.CircleLeft)} />
      <div className={classnames(styles.Circle, styles.CircleRight)} />
      <div className={styles.Content}>
        <div className={styles.Title}>{t("supportPage.title")}</div>
        <div className={styles.Description}>{t("supportPage.description")}</div>
      </div>
    </ContentWrapper>
  );
};

export const Check = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M7.95828 14.3959C7.86106 14.3959 7.76745 14.3784 7.67745 14.3434C7.58689 14.309 7.50689 14.257 7.43745 14.1876L3.95828 10.7084C3.83328 10.5834 3.77411 10.434 3.78078 10.2601C3.788 10.0867 3.84717 9.93758 3.95828 9.81258C4.08328 9.68758 4.23272 9.62508 4.40661 9.62508C4.57995 9.62508 4.72217 9.68758 4.83328 9.81258L7.95828 12.9376L15.1666 5.72925C15.2777 5.60425 15.4236 5.54175 15.6041 5.54175C15.7847 5.54175 15.9305 5.60425 16.0416 5.72925C16.1666 5.85425 16.2291 6.00341 16.2291 6.17675C16.2291 6.35064 16.1666 6.50008 16.0416 6.62508L8.47911 14.1876C8.40967 14.257 8.32995 14.309 8.23995 14.3434C8.14939 14.3784 8.0555 14.3959 7.95828 14.3959Z"
        fill="#6052FF"
      />
    </svg>
  );
};

interface IProps {
    className?: string;
}

export const Facebook = ({ className }: IProps) => {
    return (
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M34.2 18.5404C34.2 9.57559 26.9424 2.2998 18 2.2998C9.05765 2.2998 1.80005 9.57559 1.80005 18.5404C1.80005 26.4009 7.37285 32.9458 14.76 34.4562V23.4126H11.52V18.5404H14.76V14.4803C14.76 11.3458 17.3034 8.79604 20.43 8.79604H24.48V13.6682H21.24C20.349 13.6682 19.62 14.399 19.62 15.2923V18.5404H24.48V23.4126H19.62V34.6998C27.801 33.8878 34.2 26.9693 34.2 18.5404Z" fill="#333333" />
        </svg>
    )
}

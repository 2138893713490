import { SpringValue, animated } from "@react-spring/web";
import { APP_LANGS } from "const";
import { useClickOutside } from "hooks";
import i18next from "i18next";
import { Check } from "img/Check";
import { Language } from "img/Language";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAppLang } from "types";
import styles from "./LanguageSelector.module.scss";

interface LanguageSelectorProps {
  animatedStyle: {
    color: SpringValue<string>;
    borderColor: SpringValue<string>;
  };
}

export const LanguageSelector = ({ animatedStyle }: LanguageSelectorProps) => {
  const { t, i18n } = useTranslation();

  const fieldRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  useClickOutside(fieldRef, () => {
    setShowOptions(false);
  });

  const handleSelectLang = (lang: IAppLang) => {
    setShowOptions(false);
    i18n.changeLanguage(lang.code as unknown as string);
  };

  const selectedLanguage = i18next.resolvedLanguage;

  return (
    <div className={styles.Wrapper} ref={fieldRef}>
      <animated.div
        className={styles.SelectedLang}
        style={animatedStyle}
        onClick={() => setShowOptions(!showOptions)}>
        <Language />
        <span className={styles.Label}>{selectedLanguage?.toUpperCase()}</span>
      </animated.div>

      {showOptions && (
        <div className={styles.Options}>
          {APP_LANGS?.map((lang) => (
            <div
              key={lang.code}
              className={styles.Option}
              onClick={() => handleSelectLang(lang)}>
              <div className={styles.Row}>
                <div className={styles.Row} style={{ marginRight: 8 }}>
                  {lang.flag}
                </div>
                {t(lang?.label)} ({lang?.code?.toUpperCase()})
              </div>
              <div>{lang?.code === selectedLanguage && <Check />}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface IProps {
  className?: string;
}

export const School = ({ className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    className={className}>
    <path
      d="M40 66.1537L18.3334 54.3845V36.3077L6.79507 30.0001L40 11.9233L73.2048 30.0001V53.9743H68.205V32.7693L61.6666 36.3077V54.3845L40 66.1537ZM40 42.3334L62.8012 30.0001L40 17.6668L17.1987 30.0001L40 42.3334ZM40 60.4615L56.6667 51.4615V38.9743L40 48.0709L23.3333 38.9743V51.4615L40 60.4615Z"
      fill="#33B750"
    />
  </svg>
);

import { Box, Grid } from "@mui/material";
import { useInView } from "@react-spring/web";
import classnames from "classnames";
import { ContentWrapper, DownloadAppBtn } from "components";
import { EVENT, TRACKING_LABEL } from "const";
import homeSectionPhone from "images/HomeSectionPhone.png";
import { HOME_PATH } from "pages/routes";
import { MutableRefObject, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./HomeSection.module.scss";

interface HomeSectionProps {
  elementRef: MutableRefObject<HTMLDivElement | null>;
}

export const HomeSection = ({ elementRef }: HomeSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ref, inView] = useInView({ amount: "all" });

  useEffect(() => {
    if (inView || window.scrollY === 0) {
      navigate(HOME_PATH);
    }
  }, [inView, navigate]);

  return (
    <ContentWrapper
      className={styles.SectionWrapper}
      elementRef={elementRef}
      withoutAnimation>
      <div className={classnames(styles.Circle, styles.CircleLeft)} />
      <div className={classnames(styles.Circle, styles.CircleTop)} />
      <div className={classnames(styles.Circle, styles.CircleRight)} />
      <Grid container className={styles.HomeSection} ref={ref}>
        <Grid item md={6} className={styles.TextContent}>
          <div className={styles.TitleSlim}>{t("homeSection.titleSlim")}</div>
          <div className={styles.TitleBold}>{t("homeSection.titleBold")}</div>
          <div className={styles.Explanation}>
            {t("homeSection.explanation")}
          </div>
          <Box
            className={styles.ImageContent}
            sx={{ display: { sm: "flex", md: "none" } }}>
            <img
              src={homeSectionPhone}
              alt="home-section"
              className={styles.Image}
            />
          </Box>
          <div className={styles.Buttons}>
            <DownloadAppBtn
              type="apple"
              label={TRACKING_LABEL.HOME}
              event={EVENT.HOME_APP_STORE}
            />
            <DownloadAppBtn
              type="google"
              label={TRACKING_LABEL.HOME}
              event={EVENT.HOME_GOOGLE_PLAY}
            />
          </div>
        </Grid>
        <Grid
          item
          md={6}
          className={styles.ImageWrapper}
          sx={{ display: { sm: "none", md: "flex" } }}>
          <img
            src={homeSectionPhone}
            alt="home-section"
            className={styles.Image}
          />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

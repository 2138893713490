interface IProps {
    className?: string;
}

export const Linkedin = ({ className }: IProps) => {
    return (
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M30.6 2.2998C31.5548 2.2998 32.4705 2.67909 33.1456 3.35422C33.8208 4.02935 34.2 4.94502 34.2 5.8998V31.0998C34.2 32.0546 33.8208 32.9703 33.1456 33.6454C32.4705 34.3205 31.5548 34.6998 30.6 34.6998H5.40005C4.44527 34.6998 3.52959 34.3205 2.85446 33.6454C2.17933 32.9703 1.80005 32.0546 1.80005 31.0998V5.8998C1.80005 4.94502 2.17933 4.02935 2.85446 3.35422C3.52959 2.67909 4.44527 2.2998 5.40005 2.2998H30.6ZM29.7 30.1998V20.6598C29.7 19.1035 29.0818 17.611 27.9813 16.5105C26.8809 15.41 25.3883 14.7918 23.832 14.7918C22.302 14.7918 20.52 15.7278 19.656 17.1318V15.1338H14.634V30.1998H19.656V21.3258C19.656 19.9398 20.772 18.8058 22.158 18.8058C22.8264 18.8058 23.4674 19.0713 23.94 19.5439C24.4125 20.0165 24.678 20.6575 24.678 21.3258V30.1998H29.7ZM8.78405 12.3078C9.58606 12.3078 10.3552 11.9892 10.9223 11.4221C11.4894 10.855 11.808 10.0858 11.808 9.2838C11.808 7.6098 10.458 6.2418 8.78405 6.2418C7.97726 6.2418 7.20351 6.5623 6.63303 7.13278C6.06254 7.70327 5.74205 8.47702 5.74205 9.2838C5.74205 10.9578 7.11005 12.3078 8.78405 12.3078ZM11.286 30.1998V15.1338H6.30005V30.1998H11.286Z" fill="#333333" />
        </svg>

    )
}

import type { Resource } from "i18next";

export enum LangEnum {
  EN = "en",
  DE = "de",
  ES = "es",
  FR = "fr",
  CN = "cn",
}

export type LangResources = Record<LangEnum, { translation: Resource }>;

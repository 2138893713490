import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./langs/en.json";
import deTranslation from "./langs/de.json";
import esTranslation from "./langs/es.json";
import frTranslation from "./langs/fr.json";
import cnTranslation from "./langs/cn.json";
import { LangEnum, LangResources } from "./types";

export const resources: LangResources = {
  [LangEnum.EN]: {
    translation: enTranslation,
  },
  [LangEnum.DE]: {
    translation: deTranslation,
  },
  [LangEnum.ES]: {
    translation: esTranslation,
  },
  [LangEnum.FR]: {
    translation: frTranslation,
  },
  [LangEnum.CN]: {
    translation: cnTranslation,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: LangEnum.EN,
});

export { i18n };

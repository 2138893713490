import styles from './Link.module.scss';
import classnames from 'classnames';
import { ACTION, CATEGORY, EVENT } from 'const';
import mixpanel from 'mixpanel-browser';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import ReactGA from 'react-ga';


interface IProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
}


export const Link = ({ children, className, href, title, ...rest }: IProps) => {
  return (
    <a
      className={classnames(styles.Link, className)}
      href={href}
      title={title}
      rel="noreferrer"
      id="link"
      onClick={() => {
        mixpanel.track(EVENT.LINK_ITEM_CLICKED, { label: title });
        ReactGA.event({
          category: CATEGORY.LINK,
          action: ACTION.CLICK,
          label: title
        })
      }}
      {...rest}
    >
      {children}
    </a>
  )
}

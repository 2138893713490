import { useTranslation } from "react-i18next";

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("legalPage.terms")}</h1>
      <br />
      <br />
      <section className="textDefault">
        <h1>INTRODUCTION</h1>
        <p>
          Dear User, in order to use the Savelit Mobile App you must read
          carefully and agree to these Terms and Conditions of Use. Savelit is
          an application for budgeting, developed and offered to you by
          Berrystack Services S.R.L. (hereinafter, “Berrystack”). It enables its
          users to track and manage personal finances and analyse their income
          and expenses. Your use of the Savelit Application and all the related
          services provided through it by Savelit (collectively referred to as
          "Savelit") is subject to the following clauses (“Terms”), which upon
          your acceptance form a legally binding agreement between you and the
          Savelit (“Agreement”). To eliminate any ambiguity, this agreement is
          exclusively between you and Savelit, with no additional parties
          involved. Savelit is exclusively accessible through a paid
          subscription, however, it does offer a 7-day trial mode for users to
          experience its features before committing to a subscription.
        </p>
      </section>

      <section className="textDefault">
        <h1>1. OVERVIEW</h1>
        <p>
          These Terms represent the contractual basis between Berrystack
          Services S.R.L (hereinafter, “Berrystack” or “Savelit”) and the
          customer for the use of the Application (as described in this clauses)
          as well as for any associated services. The contract between the
          customer and Berrystack is concluded under these Terms only. If you do
          not agree or accept, without limitation or qualification, the Terms of
          the Application, please do not use the Application.
          <br />
          <br />
          Berrystack Services S.R.L., headquartered in Iuliu Maniu Boulevard
          15H, Bl. 2, Sc. 2, Et. 11, Ap. 188, Bucharest, Romania registered at
          the Trade Register Office attached to the Bucharest Tribunal under no.
          J40/9964/2021, having a unique registration code 44403102 is the owner
          of the Application Savelit.
          <br />
          <br />
          These Terms are applicable to natural persons over 14 years of age or
          legal persons who access the Application and use our services. The
          service is only offered for your private, non- commercial use.
          <br />
          <br />
          By using the App and its features, users can track and manage personal
          finances and analyse their income and expenses. The app is presently
          accessible on both the Google Play Store and the App Store, giving you
          the option to use it with a complimentary 7-day trial period. Once the
          trial period concludes, you will be charged for the subscription of
          your choice.
          <br />
          <br />
          When there are changes in laws or regulations, technology, or
          organizational processes, or when changes are needed due to compliance
          with the statutory obligations or to improve the quality of services
          provided through Savelit App, Berrystack may amend the Terms and
          Conditions of Use at its sole discretion. Such an amendment may cover
          areas such as amending and terminating the Agreement, registering a
          user account, or paying features and prices.
          <br />
          <br />
          In this case, you can only continue to use the application if you
          agree to the changes. Since this is a standardized offer, any
          modifications you suggest regarding the Terms and Conditions of Use
          cannot be considered. Should you propose alterations to these Terms,
          they will only become effective with our prior written approval and
          confirmation from Berrystack.
        </p>
      </section>

      <section className="textDefault">
        <h1>2. USER ACCOUNT AND ACCEPTANCE OF TERMS AND CONDITIONS</h1>
        <p>
          These Terms regulate the legal relationship between you and Berrystack
          and represent a framework contract based on which Berrystack is
          providing the service to you. The Terms are always available to you in
          the Savelit App, and you have the right to request a digital copy.
          <br />
          <br />
          You are only permitted to use Savelit if you explicitly confirm your
          acceptance of the Terms during the sign-up process by selecting
          "Accept" in a confirmation dialog box. Furthermore, your further use
          of Savelit also signifies your acceptance and willingness to be
          legally bound by these Terms.
          <br />
          <br />
          You may not accept these Terms unless you are at least 14 years of
          age, and you have sufficient legal capacity to enter into a contract.
          If you are less than 18 years of age, you must have your parent or
          legal guardian's permission to accept the Terms and use Savelit. If
          your legal representative does not consent to the Terms and Conditions
          of the Application, it is incumbent upon you to cease using it and to
          request the deletion of the account you established by contacting us.
          You have the option to request the deletion of your account at any
          time by sending an email to the provided address:{" "}
          <a href="mailto:contact@savelit.com">contact@savelit.com</a>.
          <br />
          <br />
          Upon accepting these Terms, you are affirming that you do not qualify
          as a politically exposed person as defined by anti-money laundering
          legislation. If you do not adhere to or no longer meet this
          requirement, or if you have any uncertainties about your status in
          this regard, it is imperative that you get in touch with Berrystack
          via email.
          <br />
          <br />
          User accounts are available to clearly identified natural persons. To
          be able to use Savelit you must sign up. You can sign up with your
          Apple ID or with your personal Facebook or Google account. You agree
          to provide us only accurate, truthful, and current information and
          keep it up to date. Upon creating your account, you are responsible
          for ensuring that the information you provide to Savelit is accurate,
          comprehensive, and up-to-date. It's important to note that Savelit
          does not have control over this data; therefore, the accuracy of the
          information is your sole responsibility. You must truthfully provide
          your own name and a billing address for billing purposes.
          <br />
          <br />
          Savelit stores your data in your user account. The user account
          combines information about you so that you and we can better
          understand and manage your rights and obligations as a user of
          Savelit. A user account is not transferable.
          <br />
          <br />
          You must keep your Savelit account login information confidential and
          secure, and you may not share it with anyone. You are solely
          responsible and liable for all activities that occur under your
          account. Savelit reserves the right to refuse your registration and is
          entitled to suspend your Savelit account at any time.
        </p>
      </section>

      <section className="textDefault">
        <h1>3. YOUR USE OF SAVELIT</h1>
        <p>
          You are obligated to utilize Savelit in strict accordance with the
          Terms and Conditions, for its intended purpose, and to adhere to all
          relevant laws as well as the Terms and Conditions set forth by third
          parties or service providers.
          <br />
          <br />
          You are strictly prohibited from utilizing Savelit to engage in
          activities that disrupt, interfere with, damage, or unlawfully access
          the servers, network connectivity, or any other components or services
          of third parties.
        </p>
      </section>

      <section className="textDefault">
        <h1>4. PAYMENT ACCOUNT SYNCHRONIZATION</h1>
        <p>
          Savelit enables you to link to bank accounts or e-wallets, which are
          referred to as "payment accounts," held by supported financial
          institutions worldwide. It enables the automatic synchronization of
          your payment account data with Savelit. To facilitate the automatic
          synchronization in relation to payment accounts held by European
          financial institutions, Savelit utilizes third party financial service
          technology provider, Salt Edge, which acts as a payment account data
          Aggregator. The complete list of European financial institutions whose
          payment accounts can be synchronized in the app is available at:
          https://www.saltedge.com/ products/account_information/coverage.
          <br />
          <br />
          Salt Edge Limited has its registered headquarters at 2nd Floor Amba
          House, 15 College Road, Harrow, England, HA1 1BA, United Kingdom
          (“Salt Edge”).
          <br />
          <br />
          You commit to linking exclusively to payment accounts that you either
          own or possess the legitimate right to use. When using the payment
          account synchronization feature of Savelit, which is facilitated by a
          solution provided by the Aggregator, you explicitly grant the
          Aggregator the consent to share the retrieved payment account data
          with Savelit. The payment account data is retrieved by means of
          account information services provided to you in accordance with the
          applicable payment services legal framework. You affirm and guarantee
          to Savelit and the Aggregator that you hold all requisite rights to
          undertake these actions.
          <br />
          <br />
          Users of Savelit who link to European financial institutions through
          Salt Edge have the option to register for the Salt Edge Dashboard,
          which allows for the centralized management of connections. When you
          access the Salt Edge Dashboard and utilize its services, you indicate
          that you have read and agree to abide by the Salt Edge Dashboard Terms
          of Service (https:// www.saltedge.com/dashboard/terms_of_service) as
          well as the Privacy Policy (
          <a
            href="https://
www.saltedge.com/dashboard/privacy_policy"
            rel="noreferrer"
            target="_blank">
            https:// www.saltedge.com/dashboard/privacy_policy
          </a>
          ).
        </p>
      </section>

      <section className="textDefault">
        <h1>5. USER CONTENT</h1>
        <p>
          You have the capability to upload or synchronize texts, numerical
          data, photos, or other content to Savelit. You retain the copyright
          and all other rights to your content that are legally protected. For
          such content, you grant Savelit a worldwide, nonexclusive, no-charge,
          and royalty-free license to use it in connection with the provision of
          the services. This license encompasses the rights to copy, reproduce,
          modify, create derivative works, publish, display, upload, transmit,
          distribute, market, and sublicense the content. You confirm that you
          possess all the necessary rights and consents to grant this license.
          The license is valid for the entire duration of the copyright or until
          the termination of the Terms.
          <br />
          <br />
          You bear sole responsibility for any content you provide to Savelit
          and the consequences thereof. It is prohibited to input, upload, or
          synchronize with Savelit any unlawful content or content that
          infringes upon copyright or any other third-party rights.
          <br />
          <br />
          Savelit does not perform a review of user-generated content but
          retains the right to remove or disable access to any user content for
          any reason. Savelit holds no responsibility for the accuracy of the
          content you provide to Savelit or synchronize with it or for content
          created by Savelit based on your input. It is your sole responsibility
          to back up the content you input, upload, or synchronize with Savelit.
          <br />
          <br />
          <strong>
            Additionally, you are making the following commitments:
          </strong>
          <br />
          <br />
          - You will solely utilize the App for personal, non-commercial
          purposes and will refrain from sharing it with third parties.
          <br />
          <br />
          - You will not transfer, assign, or otherwise make your user account
          available to another individual.
          <br />
          <br />
          - You will limit your usage to lawful purposes.
          <br />
          <br />
          - You will refrain from using the App in a manner that causes
          disruption or inconvenience to the company or third parties.
          <br />
          <br />
          - You will not interfere with the proper functioning of the network.
          <br />
          <br />
          - You will abstain from attempting to damage, impair, or compromise
          the App in any manner.
          <br />
          <br />
          - You will maintain the security and confidentiality of your user
          account password or any provided identification method that grants
          access to the App.
          <br />
          <br />
          - In the event of identity doubts, you will provide the requested
          proof of your identity.
          <br />
          <br />- You will not employ the App with a device that is incompatible
          or not approved for use.
        </p>
      </section>

      <section className="textDefault">
        <h1>6. NO PROFESSIONAL ADVICE</h1>
        <p>
          Savelit may offer you personalized services related to managing your
          finances, which may include insights and analyses of various financial
          indicators, based on your synchronized payment account data.
          <br />
          <br />
          These financial analyses do not constitute professional financial,
          tax, accounting, investment, or any other professional advice or
          substitute for it. Savelit and its features are designed to provide
          general information and insights based on the data input by the user.
          These features aim to assist users in making informed decisions but
          are not a substitute for professional advice tailored to specific
          individual circumstances.
          <br />
          <br />
          Users are encouraged to seek advice from qualified professionals or
          experts in the relevant field before making significant decisions or
          taking actions that may have legal, financial, or other consequences.
          The App's features should not be used as a sole basis for any such
          decisions.
          <br />
          <br />
          The developers, operators, and owners of Savelit shall not be liable
          for any actions, decisions, or consequences arising from the use of
          the App's features. Savelit relies on the data provided by the user
          and, in some cases, external data sources and does not make any
          representations or warranties of any kind regarding these services.
          Under no circumstances shall Savelit be liable for any damages
          incurred by you or a third party because of your use of the financial
          advice provided in the App.
        </p>
      </section>

      <section className="textDefault">
        <h1>
          7. TERMINATION OF THE AGREEMENT CANCELLATION OF YOUR USER ACCOUNT
        </h1>
        <p>
          The Agreement will remain in full force and effect and your User
          Account will remain available while you use Savelit and until
          terminated by either you or us. Termination always includes the
          deletion of your user account.
          <br />
          <br />
          Each contracting party may, at its own discretion and without
          liability, terminate the contract described in these Terms of Use and
          all related services at any time without notice. In such instances,
          Savelit has the authority to specify the service or services in
          question and may consequently limit, or suspend, either wholly or
          partially, the services. This includes the availability of any data,
          databases, or content. Additionally, Savelit retains the right to deny
          some or all the services, as well as block or remove any portion of
          associated data, including your user account and related data. Savelit
          will announce this by notifying you on the Application. You may
          terminate the Agreement at any time and for any reason by sending a
          notice to Savelit (by e-mail) and deleting the Savelit
          application/account. A refund of your purchases is in such case not
          possible.
          <br />
          <br />
          We can terminate the Agreement upon notice at any time and for any
          reason. The notice period is 1 month unless immediate termination is
          agreed between you and Savelit. In exceptional situations, as outlined
          by relevant EU or UK laws, which could include matters such as money
          laundering or terrorist financing, any action aimed at freezing
          assets, or specific measures associated with the prevention and
          investigation of crimes, Savelit may terminate the Agreement without
          notice at any time. In case of termination due to a breach of the
          Terms and Conditions, you are not entitled to refunds.
        </p>
      </section>

      <section className="textDefault">
        <h1>8. LICENSE AND RESTRICTIONS</h1>
        <p>
          Savelit application and all rights therein, including intellectual
          property rights, exclusively belong to Berrystack or the licensors of
          Savelit. Nothing in the Terms shall be construed to grant you any
          rights, except for the limited license granted below.
          <br />
          <br />
          In accordance with the Terms, Savelit extends to you a limited,
          non-exclusive, non- transferable, and non-sublicensable license. This
          license allows you to access and utilize the App on any same-platform
          device (e.g., iOS device) that you own and have control over. This
          license is exclusively for your personal, non-commercial use.
          <br />
          <br />
          <strong>You are prohibited from:</strong>
          <br />
          <br />
          - Removing references to copyrights, trademarks, or other property
          rights from the application.
          <br />
          <br />
          - Reproducing, modifying, creating, distributing, licensing, renting,
          selling, reselling, transferring, transmitting, making public, or
          using the applications in any way without the express consent of
          Berrystack to stream, broadcast, or exploit them.
          <br />
          <br />
          - Decompiling, reverse engineering, or disassembling the application,
          unless and to the extent permitted by law.
          <br />
          <br />
          - Linking, framing, or mirroring any part of the application.
          <br />
          <br />
          - Developing or installing programs or scripts with the intention of
          scraping, indexing, surveillance, or engaging in other data mining
          activities or unduly obstructing or disrupting the operation or
          functionality of any aspect of the App.
          <br />
          <br />
          - Attempting to gain unauthorized access to any part of the
          applications or the systems or networks connected to them.
          <br />
          <br />
          <strong>
            These Terms and Conditions of Use do not grant you any rights:
          </strong>
          <br />
          <br />
          - To or in relation to the App, except for the limited license granted
          as mentioned earlier.
          <br />
          <br />
          - To use or refer to Berrystack company names, logos, brands, other
          trademarks, or those of the licensors.
          <br />
          <br />
          Information regarding the current hardware and system requirements for
          Savelit, as well as technical restrictions and other limitations, can
          always be found on the Digital Store. You bear the responsibility for
          the correct installation and operation of the App on your devices, as
          well as ensuring access to the data network that is necessary for
          using the App. Additionally, you are responsible for any costs that
          may arise from using the App. When accessing and/or using the App, you
          may incur data and connection fees and charges, such as those from
          your mobile network provider, and it is your sole responsibility to
          cover these expenses.
        </p>
      </section>

      <section className="textDefault">
        <h1>9. MAINTENANCE AND SUPPORT</h1>
        <p>
          Savelit is continually improving, and Berrystack reserves the right,
          at its sole discretion, to update the App, alter the nature of
          Savelit, or modify or discontinue certain features without prior
          notice to you. Berrystack is not required to maintain or update the
          App.
          <br />
          <br />
          Berrystack cannot guarantee uninterrupted provision of the services.
          There may be temporary interruptions in the availability of the App or
          integrated third-party services due to maintenance, technical
          difficulties, or other events that are beyond Berrystack's control.
        </p>
      </section>

      <section className="textDefault">
        <h1>10. PRICES AND SUBSCRIPTIONS</h1>
        <p>
          The App is available upon a paid subscription. A free trial period for
          subscription content can be offered, at Berrystack’s discretion.
          Currently, we offer a 7-day trial mode for users to experience its
          features. However, you are required to provide your payment details to
          start such a subscription. The following conditions apply once the
          trial mode concludes.
          <br />
          <br />
          You can find up-to-date and detailed information about the
          subscription offers within the App. Subscriptions are established for
          the duration specified in your order, with options typically including
          1-month and 12-month terms. When you click the BUY button, payment is
          due and payable immediately upon completion of the purchasing process
          and you are entering into an additional agreement in accordance with
          the Terms. Therefore, it's advisable to carefully review the Terms and
          any associated information before proceeding with the purchase.
          <br />
          <br />
          Regarding the right of withdrawal provided by European Legislation,
          you acknowledge that the contractual performance is to commence
          immediately and for this reason, you do not have the right of
          withdrawal. Consequently, once you make the purchase, you won't have
          the option to cancel the contract or request a refund.
          <br />
          <br />
          The prices are presented in your local currency, provided it is
          supported, and they incorporate the relevant VAT (Value Added Tax).
          It's important to note that Berrystack does not assure that Savelit or
          any of its features will remain free of charge, and Berrystack
          reserves the right, at its sole discretion, to modify the pricing as
          deemed necessary.
          <br />
          <br />
          Subscriptions automatically renew themselves for another term of the
          same length, on the first day of the new term, unless you cancel
          before the current term runs out. Cancelation is available anytime,
          without affecting the current term of the subscription.
          <br />
          <br />
          For the time being, payment methods are limited to credit and debit
          cards, or PayPal but may vary according to the distribution platform,
          i.e., Digital Store. Savelit itself doesn't store any payment
          information or process payments and is not responsible for the payment
          processing. Refunds for in-app purchases are generally not provided.
        </p>
      </section>

      <section className="textDefault">
        <h1>11. WARRANTIES AND LIMITATION OF LIABILITY</h1>
        <p>
          Under the provided terms, Berrystack is only responsible for damages
          resulting from deliberate intent or gross negligence.
          <br />
          <br />
          Berrystack, specifically, is not liable for any damages arising from
          the use (or inability to use) of the App or its features. This
          includes damages caused by malware, viruses, or any errors or
          omissions in the information or the application. Berrystack is not
          responsible for damages or claims for damages by third parties. These
          disclaimers and limitations also apply to Berrystack's assistants,
          representatives, and deputies.
          <br />
          <br />
          To the extent permitted by the law, Savelit App and integrated
          third-party services are provided "AS IS" and “AS AVAILABLE” without
          any express or implied representations or warranties of any kind
          except as set forth in the present Terms. Consequently, we cannot
          guarantee that the App is free of errors, defects, malware, and
          viruses or that the App is correct, updated, and error-free.
          <br />
          <br />
          In some jurisdictions, limitations on implied warranties may not be
          fully enforceable, so this disclaimer might not apply to you in its
          entirety. If payment services law is applicable, the provisions of the
          "warranties and limitation of liability" section apply to the extent
          they do not contradict this law.
          <br />
          <br />
          You may have the right to seek damages to the extent permitted by the
          law, the terms of the insurance contract, and the conditions of the
          relevant insurance policy. This means that certain legal rights and
          protections may still apply despite the disclaimers and limitations
          mentioned earlier.
        </p>
      </section>

      <section className="textDefault">
        <h1>12. INDEMNIFICATION</h1>
        <p>
          You agree to indemnify and absolve Berrystack, its licensors, as well
          as its representatives, employees, or contractors from any claims,
          expenses, damages, losses, or liability claims (including judicial
          fees or attorney costs) that may arise due to or in connection with
          your breach of any provision in these Terms and Conditions of Use, any
          relevant legal provisions, your infringement of third-party rights, or
          any other matters related to your use of the App.
        </p>
      </section>

      <section className="textDefault">
        <h1>13. THIRD-PARTY SERVICES</h1>
        <p>
          You must agree and comply with any applicable third-party terms when
          using Savelit, as the App may integrate or otherwise include
          third-party services or content (such as appropriate Digital Store,
          Facebook’s Terms, or Salt Edge). The inclusion of third-party services
          or content in Savelit does not imply an endorsement or affiliation
          between Savelit and the respective third parties.
        </p>
      </section>

      <section className="textDefault">
        <h1>14. IOS PLATFORM USERS</h1>
        <p>
          You recognize that this agreement is concluded between you and
          Berrystack, not you and Apple. Savelit is solely responsible for the
          App and its content, excluding user-generated content. Your usage of
          Savelit must adhere to the rules outlined in the App Store Terms of
          Service, except for the allowance of Family Sharing.
          <br />
          <br />
          Savelit, not Apple, is responsible for providing maintenance and
          support services for Savelit. Apple is not obligated to provide any
          such services. If Savelit does not meet applicable warranties, you can
          notify Apple for a refund. However, Apple's warranty responsibilities
          are limited, and any other claims or issues are the sole
          responsibility of Savelit.
          <br />
          <br />
          In cases of product liability, regulatory compliance, and intellectual
          property infringement claims, Berrystack is solely responsible for
          investigating, defending, settling, and resolving such claims.
          <br />
          <br />
          You acknowledge that Apple and its subsidiaries are considered
          third-party beneficiaries of this Agreement. Apple has the right to
          enforce these terms against you as a third-party beneficiary.
          <br />
          <br />
          Subscriptions are auto-renewable, which means they renew automatically
          unless you turn off auto-renew at least 24 hours before the current
          subscription period ends. Your account will be charged for renewal
          within 24 hours before the end of the current period. You can manage
          your subscriptions and disable auto-renewal in your Account Settings
          after purchase.
        </p>
      </section>

      <section className="textDefault">
        <h1>15. CONSUMER PROTECTION</h1>
        <p>
          You have all the rights provided by your country of residence
          regarding consumer protection. We make all reasonable efforts to
          comply with the rights granted by applicable consumer protection
          legislation.
        </p>
      </section>

      <section className="textDefault">
        <h1>16. APPLICABLE LAW AND OTHER PROVISIONS</h1>
        <p>
          Unless a mandatory law in any jurisdiction dictates otherwise, the
          Terms and any disputes or claims arising from them, or their subject
          matter or formation shall be governed by the laws of Romania. Unless
          resolved amicably, all legal disputes shall be brought before the
          competent courts in the jurisdiction of the headquarters address of
          Berrystack.
          <br />
          <br />
          If any provisions of these terms of use or the contract become invalid
          or ineffective, the other provisions and the terms of use of the
          contract will be otherwise not affected. To the extent permitted by
          law, the void or ineffective provisions will be replaced by provisions
          that serve a similar economic purpose as closely as possible.
          <br />
          <br />
          All information about Savelit is available in the English language.
          English shall be also the binding and controlling language for the
          additional agreements concluded pursuant to the Terms.
        </p>
      </section>

      <section className="textDefault">
        <h1>17. CONTACT INFORMATION</h1>
        <p>
          If you have questions, encounter problems, or wish to provide
          suggestions, you can contact Savelit using the provided contact
          information. Your questions, complaints or claims with respect to
          Savelit should be directed to:
        </p>
        <p>
          <strong>E-mail</strong>
          <br /> <a href="mailto:contact@savelit.com">contact@savelit.com</a>
        </p>
        <p>
          <strong>Address</strong>
          <br /> Iuliu Maniu Boulevard 15H, Bl. 2, Sc. 2, Et. 11, Ap. 188,
          Bucharest, Romania, postal code 061075.
        </p>
      </section>
    </div>
  );
};

import classnames from 'classnames';
import styles from './Loading.module.scss';

interface IProps {
  className?: string;
}

export const Loading = ({ className }: IProps) => {
  return (
    <div className={classnames(styles.Loading, className)}>
      <div className={styles.Circle} />
      <div className={styles.Circle} />
      <div className={styles.Circle} />
    </div>
  );
};

import { Link } from "components";
import { CONTACT_SUPPORT, EMAIL_CONTACT } from "const";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SupportTypes } from "./GuidelinesSection";
import styles from "./GuidelinesSection.module.scss";

interface GuidelinesContentProps {
  type: SupportTypes;
}

export const GuidelinesContent = ({ type }: GuidelinesContentProps) => {
  const { t } = useTranslation();

  const content = useMemo(
    () => ({
      onboarding: {
        title: t("supportPage.guidelines.content.onboarding.title"),
        items: [1, 2, 3, 4, 5, 6].map((no: number) => ({
          title: t(`supportPage.guidelines.content.onboarding.item${no}`),
          text: t(`supportPage.guidelines.content.onboarding.item${no}Text`),
        })),
      },
      home: {
        title: t("supportPage.guidelines.content.home.title"),
        items: [1, 2, 3, 4, 5, 6, 7, 8].map((no: number) => ({
          title: t(`supportPage.guidelines.content.home.item${no}`),
          text: t(`supportPage.guidelines.content.home.item${no}Text`),
        })),
      },
      transaction: {
        title: t("supportPage.guidelines.content.transaction.title"),
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((no: number) => ({
          title: t(`supportPage.guidelines.content.transaction.item${no}`),
          text: t(`supportPage.guidelines.content.transaction.item${no}Text`),
        })),
      },
      budget: {
        title: t("supportPage.guidelines.content.budget.title"),
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((no: number) => ({
          title: t(`supportPage.guidelines.content.budget.item${no}`),
          text: t(`supportPage.guidelines.content.budget.item${no}Text`),
        })),
      },
      analitics: {
        title: t("supportPage.guidelines.content.analitics.title"),
        items: [1, 2, 3, 4, 5, 6, 7].map((no: number) => ({
          title: t(`supportPage.guidelines.content.analitics.item${no}`),
          text: t(`supportPage.guidelines.content.analitics.item${no}Text`),
        })),
      },
      settings: {
        title: t("supportPage.guidelines.content.settings.title"),
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((no: number) => ({
          title: t(`supportPage.guidelines.content.settings.item${no}`),
          text: t(`supportPage.guidelines.content.settings.item${no}Text`),
        })),
      },
    }),
    [t],
  );

  if (type === "deletion") {
    return (
      <>
        <div className={styles.contentTitle}>
          {t("supportPage.guidelines.content.deletion.title")}
        </div>
        <div className={styles.contentText}>
          {t("supportPage.guidelines.content.deletion.text1")}
        </div>
        <div className={styles.contentText}>
          {t("supportPage.guidelines.content.deletion.text2")}
        </div>
      </>
    );
  }

  if (type === "video") {
    return (
      <>
        <div className={styles.contentTitle}>
          {t("supportPage.guidelines.content.video.title")}
        </div>
        <div className={styles.contentText}>
          {t("supportPage.guidelines.content.video.text")}
        </div>
        <div className={styles.contentItem}>
          {t("supportPage.guidelines.content.video.subtitle")}
        </div>
        <div className={styles.contentStepTitle}>
          {t("supportPage.guidelines.content.video.ios.title")}
        </div>
        {[1, 2, 3, 4, 5, 6, 7].map((no: number) => (
          <div className={styles.contentStep} key={no}>
            {t(`supportPage.guidelines.content.video.ios.step${no}`)}
          </div>
        ))}
        <div className={styles.contentStepTitle}>
          {t("supportPage.guidelines.content.video.android.title")}
        </div>
        {[1, 2, 3, 4, 5, 6].map((no: number) => (
          <div className={styles.contentStep} key={no}>
            {t(`supportPage.guidelines.content.video.android.step${no}`)}
          </div>
        ))}
      </>
    );
  }

  if (type === "support") {
    return (
      <>
        <div className={styles.contentTitle}>
          {t("supportPage.guidelines.content.contact.title")}
        </div>
        <div className={styles.contentText}>
          {t("supportPage.guidelines.content.contact.text")}
        </div>
        <div className={styles.contentStepTitle}>
          {t("supportPage.guidelines.content.contact.web.title")}
        </div>
        <div className={styles.contentStep}>
          {t(`supportPage.guidelines.content.contact.web.step`)}
          <Link
            href={CONTACT_SUPPORT}
            title={t("links.contact")}
            target="_self">
            {EMAIL_CONTACT}
          </Link>
        </div>
        <div className={styles.contentStepTitle}>
          {t("supportPage.guidelines.content.contact.app.title")}
        </div>
        {[1, 2, 3].map((no: number) => (
          <div className={styles.contentStep} key={no}>
            {t(`supportPage.guidelines.content.contact.app.step${no}`)}
          </div>
        ))}
        <div className={styles.contentText}>
          {t("supportPage.guidelines.content.contact.app.text")}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.contentTitle}>{content[type].title}</div>
      {content[type].items.map(
        (item: { title: string; text: string }, index: number) => (
          <div key={index}>
            <div className={styles.contentItem}>{item.title}</div>
            <div className={styles.contentText}>{item.text}</div>
          </div>
        ),
      )}
    </>
  );
};

export const GooglePlay = () => {
    return (
        <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2682 15.4581L0.944824 29.5831C1.35766 31.1206 2.75881 32.2456 4.42267 32.2456C5.08572 32.2456 5.71123 32.0706 6.24917 31.7456L6.2867 31.7206L21.274 23.0831L14.2557 15.4456L14.2682 15.4581Z" fill="#EA4335" />
            <path d="M27.7417 13.0581H27.7292L21.2614 9.29559L13.968 15.7831L21.2865 23.0956L27.7292 19.3831C28.8551 18.7706 29.6183 17.5831 29.6183 16.2206C29.6183 14.8581 28.8677 13.6706 27.7417 13.0706V13.0581Z" fill="#FBBC04" />
            <path d="M0.944804 2.78308C0.869742 3.08308 0.819702 3.38308 0.819702 3.70808V28.6706C0.819702 28.9956 0.857232 29.2956 0.944804 29.5956L14.7186 15.8331L0.944804 2.78308Z" fill="#4285F4" />
            <path d="M14.3683 16.1831L21.2615 9.29559L6.2867 0.620594C5.74876 0.295594 5.11074 0.108093 4.42267 0.108093C2.75881 0.108093 1.35766 1.24559 0.944824 2.77059L14.3683 16.1706V16.1831Z" fill="#34A853" />
        </svg>

    )
}
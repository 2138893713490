export const EVENT = {
  VISITED: "website-visited",
  NAVIGATE: "menu-navigate",
  HOME_APP_STORE: "homeSection_download-from-app-store",
  HOME_GOOGLE_PLAY: "homeSection_download-from-google-play",
  MADE_SIMPLE_APP_STORE: "madeSimpleSection_download-from-app-store",
  MADE_SIMPLE_GOOGLE_PLAY: "madeSimpleSection_download-from-google-play",
  UNLOCK_INSIGHTS_APP_STORE: "unlockInsights_download-from-app-store",
  UNLOCK_INSIGHTS_GOOGLE_PLAY: "unlockInsightsn_download-from-google-play",
  BANKS_SHOW_MORE_ITEMS: "banksSection_show-more-banks",
  BANKS_SHOW_LESS_ITEMS: "banksSection_show-less-banks",
  LINK_ITEM_CLICKED: "link-item-clicked",
  DOWNLOAD_FOOTER_APP_STORE: "downloadFooter_download-from-app-store",
  DOWNLOAD_FOOTER_GOOGLE_PLAY: '"downloadFooter_download-from-google-play',
};

export const CATEGORY = {
  MENU_LINK: "menu-link",
  LINK: "link",
  BUTTON: "button",
};

export const ACTION = {
  CLICK: "click",
  NAVIGATE: "navigate",
  DOWNLOAD_APP_APPLE: "download-app-apple",
  DOWNLOAD_APP_GOOGLE: "download-app-google",
  SHOW_MORE: "show-more",
  SHOW_LESS: "show-less",
};

export const TRACKING_LABEL = {
  HOME: "HomeSection",
  MADE_SIMPLE: "MadeSimple",
  UNLOCK_INSIGHTS: "UnlockInsights",
  DOWNLOAD: "DownloadSection",
  DOWLOAD_FOOTER: "DownloadFooter",
};

export const INITIAL_HEADER = {
  backgroundColor: "transparent",
  boxShadow: "0px 0px 0px 0px rgb(0, 0, 0)",
};
export const SCROLLED_HEADER = {
  backgroundColor: "#FFF",
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",
};
export const INITIAL_LOGO = {
  color: "#FFFFFF",
};
export const SCROLLED_LOGO = {
  color: "#333333",
};

export const INITIAL_ITEM = {
  color: "rgba(255, 255, 255, 0.80)",
  borderColor: "transparent",
};
export const SCROLLED_ITEM = {
  color: "rgba(51, 51, 51, 0.80)",
  borderColor: "transparent",
};
export const HOVER_INITIAL_ITEM = {
  color: "rgba(255, 255, 255, 0.80)",
  borderColor: "rgba(255, 255, 255, 0.20)",
};
export const HOVER_SCROLLED_ITEM = {
  color: "rgba(51, 51, 51, 0.80)",
  borderColor: "rgba(120, 124, 169, 0.30)",
};

export const Logo = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="6" fill="url(#paint0_linear_3_71)" />
      <path
        d="M10.7279 22.1384L8.71156 25.4994H15.0252C15.0717 25.4994 15.1111 25.5156 15.1434 25.548C15.1758 25.585 15.1921 25.6243 15.1921 25.6657C15.1921 26.1093 15.1944 26.562 15.1991 27.0241L18.5714 29.0337V25.6657C18.5714 25.6241 18.5899 25.5848 18.6271 25.548C18.6596 25.5156 18.6967 25.4994 18.7382 25.4994H22.3748C23.8257 25.4162 24.7876 24.9219 25.2605 24.0164C25.497 23.545 25.6081 22.9721 25.5942 22.2977V16.3515C25.6081 15.9634 25.513 15.6884 25.309 15.5267L22.2704 14.2655C22.2843 16.451 22.2843 18.2388 22.2704 19.6296V20.3364C22.2704 20.3641 22.2681 20.3919 22.2634 20.4197C22.1799 21.4868 21.5705 22.0597 20.4348 22.1384H10.7279ZM14.0657 16.8576C14.0657 17.6523 14.3509 18.3313 14.9211 18.895C15.4866 19.4633 16.1679 19.7475 16.9653 19.7475C17.7626 19.7475 18.4509 19.4633 19.0165 18.895C19.5866 18.3313 19.8719 17.6523 19.8719 16.8576C19.8719 16.0629 19.5866 15.3769 19.0165 14.8132C18.4509 14.2496 17.7673 13.9676 16.9653 13.9676C16.1679 13.9676 15.4866 14.2496 14.9211 14.8132C14.3509 15.3769 14.0657 16.0583 14.0657 16.8576ZM18.5714 7.5989C18.5714 7.26631 18.569 6.93347 18.5644 6.60088L15.1921 4.58429V7.97312C15.1921 8.01938 15.1758 8.05871 15.1434 8.09084C15.1109 8.12322 15.0714 8.13941 15.0252 8.13941H11.6251C9.43698 8.2687 8.36394 9.33842 8.40572 11.3481V17.2943C8.39643 17.687 8.49855 17.9641 8.71156 18.126L8.71852 18.1329L11.7293 19.3736C11.7154 17.1881 11.7154 15.4003 11.7293 14.0095V13.3027C11.7293 13.275 11.7316 13.2495 11.7362 13.2264C11.8198 12.1546 12.4338 11.5794 13.5788 11.5007H23.2926L25.309 8.13967H18.738C18.6962 8.13967 18.659 8.12348 18.6268 8.09109C18.5897 8.05871 18.5711 8.01938 18.5711 7.97338V7.59915L18.5714 7.5989Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_71"
          x1="17"
          y1="0"
          x2="17"
          y2="34"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#6052FF" />
          <stop offset="1" stopColor="#4239A0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const BurgerMenu = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_397_85" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                <rect width="36" height="36" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_397_85)">
                <path d="M6.375 26.4519C6.05625 26.4519 5.78906 26.344 5.57344 26.1283C5.35781 25.9126 5.25 25.6453 5.25 25.3264C5.25 25.0075 5.35781 24.7404 5.57344 24.525C5.78906 24.3096 6.05625 24.2019 6.375 24.2019H29.6249C29.9437 24.2019 30.2109 24.3098 30.4265 24.5255C30.6421 24.7412 30.7499 25.0085 30.7499 25.3274C30.7499 25.6463 30.6421 25.9134 30.4265 26.1288C30.2109 26.3442 29.9437 26.4519 29.6249 26.4519H6.375ZM6.375 19.125C6.05625 19.125 5.78906 19.0171 5.57344 18.8014C5.35781 18.5857 5.25 18.3184 5.25 17.9995C5.25 17.6806 5.35781 17.4135 5.57344 17.1981C5.78906 16.9827 6.05625 16.875 6.375 16.875H29.6249C29.9437 16.875 30.2109 16.9829 30.4265 17.1986C30.6421 17.4143 30.7499 17.6816 30.7499 18.0005C30.7499 18.3194 30.6421 18.5865 30.4265 18.8019C30.2109 19.0173 29.9437 19.125 29.6249 19.125H6.375ZM6.375 11.7981C6.05625 11.7981 5.78906 11.6902 5.57344 11.4745C5.35781 11.2588 5.25 10.9915 5.25 10.6726C5.25 10.3537 5.35781 10.0866 5.57344 9.87119C5.78906 9.65579 6.05625 9.5481 6.375 9.5481H29.6249C29.9437 9.5481 30.2109 9.65596 30.4265 9.87168C30.6421 10.0874 30.7499 10.3547 30.7499 10.6736C30.7499 10.9925 30.6421 11.2596 30.4265 11.475C30.2109 11.6904 29.9437 11.7981 29.6249 11.7981H6.375Z" fill="#333333"/>
            </g>
        </svg>
    )
}
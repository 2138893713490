import { LangEnum } from "i18n/types";
import { Cn, De, Es, Fr, Us } from "react-flags-select";
import { IAppLang } from "types";


export const APP_LANGS: IAppLang[] = [{
    code: LangEnum.EN,
    flag: <Us />,
    label: `appLang.${LangEnum.EN}`
}, {
    code: LangEnum.DE,
    flag: <De />,
    label: `appLang.${LangEnum.DE}`
}, {
    code: LangEnum.ES,
    flag: <Es />,
    label: `appLang.${LangEnum.ES}`
}, {
    code: LangEnum.FR,
    flag: <Fr />,
    label: `appLang.${LangEnum.FR}`
}, {
    code: LangEnum.CN,
    flag: <Cn />,
    label: `appLang.${LangEnum.CN}`
}]
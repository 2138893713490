interface IProps {
  className?: string;
}

export const TikTok = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7899 3.34784H18.7945C19.0841 4.87598 20.0122 6.18724 21.298 7.00648C21.2984 7.007 21.2989 7.00753 21.2993 7.00805C22.1944 7.57814 23.2616 7.91074 24.4073 7.91074V8.80359L24.4074 11.921C22.2793 11.921 20.3073 11.2557 18.6977 10.1265V18.2775C18.6977 22.3482 15.3091 25.66 11.1437 25.66C9.53421 25.66 8.04163 25.1639 6.81474 24.3219C6.81409 24.3213 6.81343 24.3205 6.81264 24.3198C4.86583 22.9831 3.59 20.7735 3.59 18.2767C3.59 14.2061 6.97861 10.8942 11.144 10.8942C11.4896 10.8942 11.8283 10.922 12.1618 10.9662V11.9164L12.1617 15.0616C11.8395 14.9631 11.4989 14.9058 11.1437 14.9058C9.2415 14.9058 7.69394 16.4184 7.69394 18.2775C7.69394 19.5721 8.44548 20.6969 9.54353 21.2616C10.0223 21.5079 10.5661 21.649 11.1436 21.649C13.002 21.649 14.5179 20.2043 14.5869 18.4043L14.5935 2.32996H18.6975C18.6975 2.67765 18.7319 3.01747 18.7945 3.34784H15.7899Z"
        fill="#333333"
        fillOpacity="0.8"
      />
    </svg>
  );
};

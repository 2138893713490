import classnames from "classnames";
import { ACTION, APP_STORE_URL, CATEGORY, GOOGLE_PLAY_URL } from "const";
import { AppleStore, GooglePlay } from "img";
import mixpanel from "mixpanel-browser";
import { useMemo } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import styles from "./DownloadAppBtn.module.scss";

interface IProps {
  type: "apple" | "google";
  label: string;
  event: string;
}

export const DownloadAppBtn = ({ type, label, event }: IProps) => {
  const { t } = useTranslation();

  const links = useMemo(
    () => ({
      apple: APP_STORE_URL,
      google: GOOGLE_PLAY_URL,
    }),
    [],
  );

  const actions = useMemo(
    () => ({
      apple: ACTION.DOWNLOAD_APP_APPLE,
      google: ACTION.DOWNLOAD_APP_GOOGLE,
    }),
    [],
  );

  const handleClick = () => {
    mixpanel.track(event);
    ReactGA.event({
      category: CATEGORY.BUTTON,
      action: actions[type],
      label,
    });
  };

  return (
    <div className={classnames(styles.DownloadAppBtn)} onClick={handleClick}>
      <a
        href={links[type]}
        target="_blank"
        rel="noreferrer"
        className={classnames(styles.Anchor)}>
        <div className={styles.Logo}>
          {type === "apple" && <AppleStore />}
          {type === "google" && <GooglePlay />}
        </div>

        <div className={styles.Text}>
          <div className={styles.Download}>
            {t(`downloadAppBtn.${type}.download`)}
          </div>
          <div className={styles.StoreName}>
            {t(`downloadAppBtn.${type}.storeName`)}
          </div>
        </div>
      </a>
    </div>
  );
};

"use client";
import { ContentWrapper } from "components";
import { BANKS } from "const";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSpringCarousel } from "react-spring-carousel";
import styles from "./MostPopularBanksSection.module.scss";

export const MostPopularBanksSection = () => {
  const { t } = useTranslation();
  const { countryCode } = useParams();
  const interval = useRef<NodeJS.Timeout>();

  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("GB");

  const onMouseEnterHandler = () => {
    clearInterval(interval.current);
  };

  const onMouseLeaveHandler = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    interval.current = setInterval(slideToNextItem, 3000);
  };

  const { carouselFragment, slideToNextItem } = useSpringCarousel({
    itemsPerSlide: 10,
    withLoop: true,
    gutter: 24,
    items: BANKS[selectedCountryCode]?.map((bank, index) => ({
      id: index + "",
      renderItem: (
        <div
          key={`${selectedCountryCode}-${bank.name}`}
          className={styles.Item}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}>
          <img
            src={bank.logoUrl}
            alt={bank.name}
            onDragStart={(event) => {
              event.preventDefault();
              return false;
            }}
          />
        </div>
      ),
    })),
  });

  useEffect(() => {
    interval.current = setInterval(slideToNextItem, 3000);
    return () => {
      clearInterval(interval.current);
    };
  }, [slideToNextItem]);

  useEffect(() => {
    if (countryCode) {
      const isCodeInList = Object.keys(BANKS)?.includes(
        countryCode?.toUpperCase(),
      );
      setSelectedCountryCode(isCodeInList ? countryCode.toUpperCase() : "GB");
    }
  }, [countryCode]);

  return (
    <ContentWrapper className={styles.MostPopularBanksSection} withoutAnimation>
      <div className={styles.Carousel}>{carouselFragment}</div>
      <div className={styles.Description}>{t("popularBanks.description")}</div>
    </ContentWrapper>
  );
};

import { Grid } from "@mui/material";
import { ContentWrapper } from "components";
import {
  FlexibleTimeFrameAnalysis,
  FocusedCategoryAnalysis,
  HistoricalComparison,
  SpotKeyTrends,
} from "img";
import { useTranslation } from "react-i18next";
import styles from "./UnlockInsightsTilesSection.module.scss";

export const UnlockInsightsTilesSection = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper className={styles.ContentWrapper}>
      <Grid container rowSpacing={5} columnSpacing={7.5}>
        <Grid item md={6} xs={12} display="flex">
          <div className={styles.Icon}>
            <FlexibleTimeFrameAnalysis />
          </div>
          <div className={styles.Content}>
            <div className={styles.TitleTile}>
              {t("unlockInsightsTilesSection.section1.title")}
            </div>
            <div className={styles.SubtitleTitle}>
              {t("unlockInsightsTilesSection.section1.subtitle")}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} display="flex">
          <div className={styles.Icon}>
            <SpotKeyTrends />
          </div>
          <div className={styles.Content}>
            <div className={styles.TitleTile}>
              {t("unlockInsightsTilesSection.section2.title")}
            </div>
            <div className={styles.SubtitleTitle}>
              {t("unlockInsightsTilesSection.section2.subtitle")}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} display="flex">
          <div className={styles.Icon}>
            <FocusedCategoryAnalysis />
          </div>
          <div className={styles.Content}>
            <div className={styles.TitleTile}>
              {t("unlockInsightsTilesSection.section3.title")}
            </div>
            <div className={styles.SubtitleTitle}>
              {t("unlockInsightsTilesSection.section3.subtitle")}
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} display="flex">
          <div className={styles.Icon}>
            <HistoricalComparison />
          </div>
          <div className={styles.Content}>
            <div className={styles.TitleTile}>
              {t("unlockInsightsTilesSection.section4.title")}
            </div>
            <div className={styles.SubtitleTitle}>
              {t("unlockInsightsTilesSection.section4.subtitle")}
            </div>
          </div>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

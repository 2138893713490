import { Box, Grid } from "@mui/material";
import { ContentWrapper, DownloadAppBtn } from "components";
import { EVENT, TRACKING_LABEL } from "const";
import madeSimpleSectionPhone from "images/MadeSimpleSectionPhone.png";
import { useTranslation } from "react-i18next";
import styles from "./MadeSimpleSection.module.scss";

export const MadeSimpleSection = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper className={styles.ContentWrapper} showBottomArrow={true}>
      <Grid container columnSpacing={6}>
        <Grid
          item
          md={5}
          xs={12}
          className={styles.ImageContent}
          sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          <img
            src={madeSimpleSectionPhone}
            alt="smart-budgeting-made-section"
            className={styles.Image}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          <div className={styles.Title}>{t("madeSimpleSection.title")}</div>
          <div className={styles.Explanation}>
            {t("madeSimpleSection.explanation")}
          </div>
          <Box
            className={styles.ImageContent}
            sx={{ display: { sm: "flex", md: "none" } }}>
            <img
              src={madeSimpleSectionPhone}
              alt="smart-budgeting-made-section"
              className={styles.Image}
            />
          </Box>
          <div className={styles.Buttons}>
            <DownloadAppBtn
              type="apple"
              label={TRACKING_LABEL.MADE_SIMPLE}
              event={EVENT.MADE_SIMPLE_APP_STORE}
            />
            <DownloadAppBtn
              type="google"
              label={TRACKING_LABEL.MADE_SIMPLE}
              event={EVENT.MADE_SIMPLE_GOOGLE_PLAY}
            />
          </div>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

import { FooterSection, MenuSection, StayUpToDateSection } from "components";
import { useScrollToElement } from "hooks";
import { CONTACT_PATH, SUPPORT_PATH } from "pages/routes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { IMenuItem } from "types";
import {
  AboutSection,
  BanksSection,
  HomeSection,
  MadeSimpleSection,
  MadeSimpleTilesSection,
  MostPopularBanksSection,
  OurPartnersSection,
  UnlockInsightsSection,
  UnlockInsightsTilesSection,
} from "./sections";

function Home() {
  const [activeKey, setActiveKey] = useState<string>("home");

  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const { scrollTo } = useScrollToElement();

  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const partnersRef = useRef(null);

  const menuItems: IMenuItem[] = useMemo(
    () => [
      { index: 0, label: t("menu.home"), key: "home", gotoRef: homeRef },
      { index: 1, label: t("menu.about"), key: "about", gotoRef: aboutRef },
      {
        index: 2,
        label: t("menu.partners"),
        key: "partners",
        gotoRef: partnersRef,
      },
      {
        index: 3,
        label: t("menu.support"),
        key: "support",
        link: SUPPORT_PATH,
      },
      {
        index: 4,
        label: t("menu.contact"),
        key: "contact",
        link: CONTACT_PATH,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (state?.item?.key) {
      const item = menuItems.find((elem) => elem.key === state.item.key);
      if (item?.gotoRef) {
        scrollTo(item.gotoRef);
        setActiveKey(state.item.key);
      }
    }
  }, [state, menuItems, scrollTo]);

  useEffect(() => {
    if (pathname) {
      const key = pathname?.substring(1);
      const item = menuItems.find((elem) => elem.key === key);
      if (item?.gotoRef) {
        scrollTo(item.gotoRef);
        setActiveKey(key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  return (
    <div style={{ overflow: "hidden" }}>
      <MenuSection menuItems={menuItems} defaultKey={activeKey} />
      <HomeSection elementRef={homeRef} />
      <MostPopularBanksSection />
      <AboutSection elementRef={aboutRef} />
      <MadeSimpleSection />
      <MadeSimpleTilesSection />
      <UnlockInsightsSection />
      <UnlockInsightsTilesSection />
      <BanksSection />
      <OurPartnersSection elementRef={partnersRef} />
      <StayUpToDateSection />
      <FooterSection
        homeRefs={{ home: homeRef, about: aboutRef, partners: partnersRef }}
      />
    </div>
  );
}

export default Home;

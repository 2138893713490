import { Grid } from "@mui/material";
import { ContentWrapper } from "components";
import { ACTION, BANKS, CATEGORY, EVENT } from "const";
import { Breakpoints, useScrollToElement, useWindowDimensions } from "hooks";
import mixpanel from "mixpanel-browser";
import { useEffect, useMemo, useRef, useState } from "react";
import CountUp from "react-countup";
import ReactFlagsSelect from "react-flags-select";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./BanksSection.module.scss";

export const BanksSection = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("GB");
  const [countryCodes, setCountryCodes] = useState<string[]>([]);
  const [showMore, setShowMore] = useState(false);
  const goToRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { countryCode } = useParams();
  const { breakpoint } = useWindowDimensions();
  const { scrollTo } = useScrollToElement();

  useEffect(() => {
    setCountryCodes(Object.keys(BANKS)?.sort((a, b) => a.localeCompare(b)));
  }, []);

  useEffect(() => {
    if (countryCode) {
      const isCodeInList = Object.keys(BANKS)?.includes(
        countryCode?.toUpperCase(),
      );
      setSelectedCountryCode(isCodeInList ? countryCode.toUpperCase() : "GB");
    }
  }, [countryCode]);

  const onSelectChange = (code: string) => {
    setSelectedCountryCode(code);
    setShowMore(false);
  };

  const onClickHandler = () => {
    mixpanel.track(
      showMore ? EVENT.BANKS_SHOW_LESS_ITEMS : EVENT.BANKS_SHOW_MORE_ITEMS,
    );
    ReactGA.event({
      category: CATEGORY.BUTTON,
      action: showMore ? ACTION.SHOW_LESS : ACTION.SHOW_MORE,
      label: "BanksSection",
    });
    if (showMore) {
      scrollTo(goToRef);
    }
    setShowMore(!showMore);
  };

  const shortListLimit = useMemo(
    () =>
      breakpoint === Breakpoints.XL
        ? 14
        : breakpoint === Breakpoints.LG
          ? 10
          : breakpoint === Breakpoints.MD
            ? 12
            : breakpoint === Breakpoints.SM
              ? 9
              : 6,
    [breakpoint],
  );

  const fullList = useMemo(
    () => BANKS[selectedCountryCode] ?? [],
    [selectedCountryCode],
  );

  const list = useMemo(
    () => fullList?.slice(0, showMore ? fullList.length : shortListLimit),
    [fullList, showMore, shortListLimit],
  );

  return (
    <ContentWrapper className={styles.BanksSection} elementRef={goToRef}>
      <div className={styles.Title}>{t("banksSection.title")}</div>
      <Grid container className={styles.Stats}>
        <Grid
          item
          lg={3}
          md={1.5}
          sx={{ display: { sm: "none", md: "flex" } }}
        />
        <Grid item lg={2} md={3} sm={4} xs={4}>
          <CountUp
            start={4500}
            end={5049}
            duration={4}
            enableScrollSpy={true}
            scrollSpyOnce={true}
            className={styles.Value}
          />
          <div className={styles.Label}>{t("banksSection.banks")}</div>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={4}>
          <CountUp
            end={47}
            duration={3}
            enableScrollSpy={true}
            scrollSpyOnce={true}
            className={styles.Value}
          />
          <div className={styles.Label}>{t("banksSection.countries")}</div>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={4}>
          <CountUp
            end={24}
            suffix="/24"
            duration={2}
            enableScrollSpy={true}
            scrollSpyOnce={true}
            className={styles.Value}
          />
          <div className={styles.Label}>{t("banksSection.availability")}</div>
        </Grid>
        <Grid
          item
          lg={3}
          md={1.5}
          sx={{ display: { sm: "none", md: "flex" } }}
        />
      </Grid>
      <ReactFlagsSelect
        countries={countryCodes}
        selected={selectedCountryCode}
        onSelect={onSelectChange}
        searchable={true}
        className={styles.SelectBanks}
        selectButtonClassName={styles.SelectBanksButton}
      />
      <Grid container className={styles.BankLogos} gap={4} spacing={1}>
        {list.map((bank, index) => (
          <Grid
            item
            xl={1.5}
            lg={2}
            md={2.5}
            sm={3}
            xs={4}
            key={index}
            className={styles.Item}>
            <div className={styles.Container}>
              <img src={bank.logoUrl} alt={bank.name} className={styles.Logo} />
              <div className={styles.Name}>{bank.name}</div>
            </div>
          </Grid>
        ))}
      </Grid>
      {fullList.length > shortListLimit && (
        <div className={styles.Button} onClick={onClickHandler}>
          {!showMore ? t("banksSection.showMore") : t("banksSection.showLess")}
        </div>
      )}
    </ContentWrapper>
  );
};

export const SetAndMonitorLimits = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6 45C9.6 45 8.75 44.65 8.05 43.95C7.35 43.25 7 42.4 7 41.4V6.6C7 5.6 7.35 4.75 8.05 4.05C8.75 3.35 9.6 3 10.6 3H37.4C38.4 3 39.25 3.35 39.95 4.05C40.65 4.75 41 5.6 41 6.6V41.4C41 42.4 40.65 43.25 39.95 43.95C39.25 44.65 38.4 45 37.4 45H10.6ZM10.6 42H37.4C37.5333 42 37.6667 41.9333 37.8 41.8C37.9333 41.6667 38 41.5333 38 41.4V6.6C38 6.46667 37.9333 6.33333 37.8 6.2C37.6667 6.06667 37.5333 6 37.4 6H10.6C10.4667 6 10.3333 6.06667 10.2 6.2C10.0667 6.33333 10 6.46667 10 6.6V41.4C10 41.5333 10.0667 41.6667 10.2 41.8C10.3333 41.9333 10.4667 42 10.6 42Z" fill="#6052FF" />
            <rect x="14" y="10" width="20" height="4" rx="2" fill="#6052FF" />
            <rect x="14" y="18" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="14" y="26" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="14" y="34" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="22" y="18" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="22" y="26" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="30" y="26" width="4" height="12" rx="2" fill="#6052FF" />
            <rect x="22" y="34" width="4" height="4" rx="2" fill="#6052FF" />
            <rect x="30" y="18" width="4" height="4" rx="2" fill="#6052FF" />
        </svg>

    )
}
import { Grid } from "@mui/material";
import {
  ContentWrapper,
  FooterSection,
  HeaderSection,
  MenuSection,
  ScrollToTopButton,
  StayUpToDateSection,
} from "components";
import VerticalMenu from "components/atoms/VerticalMenu/VerticalMenu";
import { PRIVACY_PATH, TERMS_PATH } from "pages/routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Legal.module.scss";
import { PrivacyPolicy, TermsAndConditions } from "./sections";

enum LegalTypes {
  Terms = "terms-and-conditions",
  Privacy = "privacy-policy",
}

const Legal = () => {
  const [selected, setSelected] = useState<LegalTypes>(LegalTypes.Terms);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === TERMS_PATH) {
      setSelected(LegalTypes.Terms);
    } else if (location.pathname === PRIVACY_PATH) {
      setSelected(LegalTypes.Privacy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (key: string) => {
    setSelected(key as LegalTypes);
    navigate(`/${key}`);
  };

  const items: { label: string; key: LegalTypes }[] = [
    {
      label: t("legalPage.terms"),
      key: LegalTypes.Terms,
    },
    { label: t("legalPage.privacy"), key: LegalTypes.Privacy },
  ];

  return (
    <div className={styles.App}>
      <MenuSection />
      <HeaderSection title={t("legalPage.title")} />
      <ContentWrapper className={styles.Wrapper} withoutAnimation>
        <Grid container>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <VerticalMenu
              title={t("legalPage.title")}
              items={items}
              selectedKey={selected}
              handleClick={handleClick}
            />
          </Grid>
          <Grid item lg={1} md={0.5} />
          <Grid item lg={8} md={7.5} sm={12} xs={12}>
            <div className={styles.Content}>
              {selected === LegalTypes.Terms ? (
                <TermsAndConditions />
              ) : (
                <PrivacyPolicy />
              )}
            </div>
          </Grid>
        </Grid>
        <ScrollToTopButton />
      </ContentWrapper>
      <StayUpToDateSection withoutAnimation />
      <FooterSection />
    </div>
  );
};

export default Legal;

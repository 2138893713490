import styles from './Button.module.scss';
import classnames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { Loading } from '../Loading';

export enum BUTTON_LOOK {
  PRIMARY = "primary",
  SECONDARY = "secondary",
};

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  look?: BUTTON_LOOK,
  loading?: boolean
}


export const Button = ({ children, className, look, loading, disabled, ...rest }: IProps) => {
  return (
    <button
      className={classnames(styles.Button, styles[`Button--${look}`], className)}
      disabled={disabled || loading}
      {...rest}>
      {!loading ? children : <Loading />}
    </button>
  )
}
export const APP_STORE_URL =
  "https://apps.apple.com/ro/app/savelit/id6461867348";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=io.berrystack.savelit";
export const CONTACT_SUPPORT = "mailto:support@savelit.com?subject=&body=";
export const CONTACT_OFFICE = "mailto:office@savelit.com?subject=&body=";
export const LINKEDIN = "https://www.linkedin.com/company/savelit/about/";
export const FACEBOOK = "https://www.facebook.com/savelitapp";
export const INSTAGRAM = "https://www.instagram.com/savelitapp/";
export const TWITTER = "https://twitter.com/savelitapp";
export const TIKTOK = "https://www.tiktok.com/@savelitapp";
export const YOUTUBE =
  "https://www.youtube.com/channel/UCbeWaaaTnLvbX5oiS7P8Ohw";
export const SENDGRID_ADD_EMAIL_ENDPOINT =
  "https://api.sendgrid.com/v3/marketing/contacts";
export const SENDGRID_SEND_FORM_ENDPOINT =
  "https://api.sendgrid.com/v3/mail/send";
export const EMAIL_CONTACT = "support@savelit.com";
export const EMAIL_OFFICE = "office@savelit.com";

interface IProps {
  className?: string;
}

export const Video = ({ className }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    className={className}>
    <path
      d="M32.5001 50.1281L53.4614 36.6667L32.5001 23.2052V50.1281ZM28.3334 68.3332V61.6666H14.3591C12.6753 61.6666 11.2501 61.0832 10.0834 59.9166C8.91675 58.7499 8.33341 57.3247 8.33341 55.6409V17.6924C8.33341 16.0086 8.91675 14.5834 10.0834 13.4167C11.2501 12.2501 12.6753 11.6667 14.3591 11.6667H65.6409C67.3247 11.6667 68.7499 12.2501 69.9166 13.4167C71.0832 14.5834 71.6666 16.0086 71.6666 17.6924V55.6409C71.6666 57.3247 71.0832 58.7499 69.9166 59.9166C68.7499 61.0832 67.3247 61.6666 65.6409 61.6666H51.6666V68.3332H28.3334ZM14.3591 56.6667H65.6409C65.8974 56.6667 66.1324 56.5598 66.3462 56.3462C66.5598 56.1324 66.6667 55.8974 66.6667 55.6409V17.6924C66.6667 17.436 66.5598 17.2009 66.3462 16.9872C66.1324 16.7735 65.8974 16.6667 65.6409 16.6667H14.3591C14.1026 16.6667 13.8676 16.7735 13.6538 16.9872C13.4402 17.2009 13.3333 17.436 13.3333 17.6924V55.6409C13.3333 55.8974 13.4402 56.1324 13.6538 56.3462C13.8676 56.5598 14.1026 56.6667 14.3591 56.6667Z"
      fill="#FF0000"
    />
  </svg>
);

import { useEffect, useState } from "react";

export enum Breakpoints {
  XS,
  SM,
  MD,
  LG,
  XL,
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  let breakpoint;
  if (width < 600) {
    breakpoint = Breakpoints.XS;
  } else if (width < 900) {
    breakpoint = Breakpoints.SM;
  } else if (width < 1200) {
    breakpoint = Breakpoints.MD;
  } else if (width < 1536) {
    breakpoint = Breakpoints.LG;
  } else {
    breakpoint = Breakpoints.XL;
  }
  return { width, height, breakpoint };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

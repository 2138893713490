export const Close = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_397_97" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                <rect width="36" height="36" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_397_97)">
                <path d="M18 19.5807L10.3903 27.1903C10.1827 27.398 9.9216 27.5043 9.60718 27.5091C9.29278 27.5139 9.02693 27.4077 8.80963 27.1903C8.5923 26.973 8.48364 26.7096 8.48364 26.4C8.48364 26.0904 8.5923 25.8269 8.80963 25.6096L16.4192 18L8.80963 10.3903C8.60193 10.1827 8.49568 9.92161 8.49088 9.60718C8.48605 9.29278 8.5923 9.02693 8.80963 8.80963C9.02693 8.5923 9.29038 8.48364 9.59998 8.48364C9.90958 8.48364 10.173 8.5923 10.3903 8.80963L18 16.4192L25.6096 8.80963C25.8173 8.60193 26.0784 8.49568 26.3928 8.49088C26.7072 8.48605 26.973 8.5923 27.1903 8.80963C27.4077 9.02693 27.5163 9.29038 27.5163 9.59998C27.5163 9.90958 27.4077 10.173 27.1903 10.3903L19.5807 18L27.1903 25.6096C27.398 25.8173 27.5043 26.0784 27.5091 26.3928C27.5139 26.7072 27.4077 26.973 27.1903 27.1903C26.973 27.4077 26.7096 27.5163 26.4 27.5163C26.0904 27.5163 25.8269 27.4077 25.6096 27.1903L18 19.5807Z" fill="#333333"/>
            </g>
        </svg>
    )
}
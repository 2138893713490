interface IProps {
  className?: string;
}

export const Youtube = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}>
      <path
        d="M26.2976 7.81516C26.1515 7.271 25.8651 6.77476 25.4669 6.3761C25.0688 5.97744 24.5729 5.69034 24.0289 5.54355C22.0266 5.005 14 5.005 14 5.005C14 5.005 5.97343 5.005 3.97108 5.54068C3.42687 5.687 2.93074 5.97394 2.53251 6.37267C2.13428 6.7714 1.84797 7.2679 1.70233 7.8123C1.16666 9.8175 1.16666 13.9998 1.16666 13.9998C1.16666 13.9998 1.16666 18.1821 1.70233 20.1844C1.99739 21.2902 2.86822 22.161 3.97108 22.456C5.97343 22.9946 14 22.9946 14 22.9946C14 22.9946 22.0266 22.9946 24.0289 22.456C25.1346 22.161 26.0026 21.2902 26.2976 20.1844C26.8333 18.1821 26.8333 13.9998 26.8333 13.9998C26.8333 13.9998 26.8333 9.81751 26.2976 7.81516ZM11.4505 17.8383V10.1613L18.0963 13.9712L11.4505 17.8383Z"
        fill="#333333"
        fillOpacity="0.8"
      />
    </svg>
  );
};

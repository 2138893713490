import { Grid } from "@mui/material";
import { ContentWrapper, Link } from "components";
import VerticalMenu from "components/atoms/VerticalMenu/VerticalMenu";
import { CONTACT_SUPPORT, EMAIL_CONTACT } from "const";
import { Breakpoints, useWindowDimensions } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { GuidelinesContent } from "./GuidelinesContent";
import styles from "./GuidelinesSection.module.scss";

export type SupportTypes =
  | "onboarding"
  | "home"
  | "transaction"
  | "budget"
  | "analitics"
  | "settings"
  | "deletion"
  | "video"
  | "support";

export const GuidelinesSection = () => {
  const [selected, setSelected] = useState<SupportTypes>("onboarding");
  const contentRef = useRef<HTMLDivElement>(null);

  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();
  const { breakpoint } = useWindowDimensions();

  useEffect(() => {
    if (!!location.search) {
      const value = location.search.split("=");
      setSelected(value[1] as SupportTypes);
    } else {
      setTimeout(() => setSearchParams({ menu: "onboarding" }), 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (key: string) => {
    setSelected(key as SupportTypes);
    setSearchParams({ menu: key });
    if (breakpoint === Breakpoints.SM || breakpoint === Breakpoints.XS) {
      contentRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const guidelinesItems: { label: string; key: SupportTypes }[] = [
    { label: t("supportPage.guidelines.menu.onboarding"), key: "onboarding" },
    { label: t("supportPage.guidelines.menu.home"), key: "home" },
    { label: t("supportPage.guidelines.menu.transaction"), key: "transaction" },
    { label: t("supportPage.guidelines.menu.budget"), key: "budget" },
    { label: t("supportPage.guidelines.menu.analitics"), key: "analitics" },
    { label: t("supportPage.guidelines.menu.settings"), key: "settings" },
    { label: t("supportPage.guidelines.menu.deletion"), key: "deletion" },
    { label: t("supportPage.guidelines.menu.video"), key: "video" },
    { label: t("supportPage.guidelines.menu.support"), key: "support" },
  ];

  return (
    <ContentWrapper className={styles.Wrapper}>
      <Grid container>
        <Grid item lg={3} md={4} sm={12}>
          <VerticalMenu
            title={t("supportPage.guidelines.menu.title")}
            items={guidelinesItems}
            selectedKey={selected}
            handleClick={handleClick}
          />
          <div className={styles.leftInfo}>
            {t("supportPage.info")}
            <Link
              href={CONTACT_SUPPORT}
              title={t("links.contact")}
              target="_self">
              {EMAIL_CONTACT}
            </Link>
          </div>
        </Grid>
        <Grid item lg={1} md={0.5} />
        <Grid
          item
          lg={8}
          md={7.5}
          sm={12}
          ref={contentRef}
          className={styles.Content}>
          <GuidelinesContent type={selected} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

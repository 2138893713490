import {
  FooterSection,
  HeaderSection,
  MenuSection,
  StayUpToDateSection,
} from "components";
import { useTranslation } from "react-i18next";
import styles from "./Contact.module.scss";
import Form from "./sections/Form/Form";
import Info from "./sections/Info/Info";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.App}>
      <MenuSection defaultKey="contact" />
      <HeaderSection
        title={t("contactPage.title")}
        description={t("contactPage.description")}
      />
      <Info />
      <Form />
      <StayUpToDateSection withoutAnimation />
      <FooterSection />
    </div>
  );
};

export default Contact;
